export const GOOGLE_ADD_NUMBER="GOOGLE_ADD_NUMBER";
export const GOOGLE_LOGIN_REQUEST_SUCCESS="GOOGLE_LOGIN_REQUEST_SUCCESS";

export const googleAddNumber=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:GOOGLE_ADD_NUMBER,
        data:myurldata
    }

    
   
}


export const googleAddNumberSuccess=(GLoginRes,number)=>{
    console.log("googleAddNumberSuccess")
    return{
        type:GOOGLE_LOGIN_REQUEST_SUCCESS,
        GLoginRes:GLoginRes,
        mobileNumber:number
    }
    }