import BpHistoryHeader from "../Header/BpHistoryHeader";
import { Link } from "react-router-dom";
import TagpayIcon from "../../assets/tagpay.png"
import PaidIcon from "../../assets/paidicon.png"
import Refundicon from "../../assets/refund.png"
import WaitingLoder from "../WaitingLoader/component";
import "../TagpayHistory/tagpay.css"
import TagIcon from "../../assets/tagpay.png"
import changeArrow from "../../assets/changeArrow.png";
import RechargeSuccess from '../../assets/rechargeSuccess.png'

import { useNavigate } from "react-router-dom";
const TagpayHistory=(props)=>{
  const navigate=useNavigate()

  function TruncatedOrderId({ text, limit }) {
    if (text&&text.length <= limit) {
      return <>{text}</>;
    }
    return <>{`${text.substring(0, limit)}...`}</>;
  }
   console.log("props rechargeModal",props.rechargeModal)
 


const handleAmount=(e)=>{

console.log("handleAmount",e.target.value)
props.setAmount(e.target.value)

}

const handleSetelectAmount=(e,amount)=>{
  props.setAmount(e.target.value)
  props.setAmount(amount)
}

const goToRechargePaymentOption=()=>{
  //localStorage.setItem("goToRechargePaymentOption",)
  navigate("/paymentOptionRecharge")

}

const CloseRechargeModal=()=>{
  props.setRechargeModal(false)
  localStorage.removeItem("ChangeRechageMethod")
}

    return(
    <>
       {true?
    <>
     
       {props.loader && <WaitingLoder />} 
      
       <div
        style={{
          background:
            "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
            //"paddingBottom":Boolean((props.ActiveOrderItems[0])&&(props.PreCardItems[0]))?"12.5rem":Boolean(props.ActiveOrderItems[0])?"9.5rem":props.PreCardItems[0]&&"8.5rem",
        }}
       className={props.login|| props.loginOtp?"h-screen overflow-y-auto ":"  overflow-y-auto h-screen"}
    
    
      >
      {(props&&props.rechargeModal)&&
      <div  className="modalDeactive"   >
        <div className="modal-content-tagRecharge" style={{padding:"5%"}}>
          
          <div onClick={(e)=>{CloseRechargeModal(e)}} style={{display:"flex","justify-content":"flex-end"}}> close</div>
       <div style={{paddingTop:"5%"}}>
        <h3 style={{ color: 'var(--neutral-01, #333F4E)',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal'}}>Select an amount to</h3>
      <p style={{  color: 'var(--neutral-03, #94A3B8)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%', // You can also use '21px' directly here if needed
    letterSpacing: '0.2px',}}>Recharge RFID Tag</p>
   
  
      
      </div>
      <input
      
    
    style={{ display: 'flex',marginTop:"5vw",
    width: '100%',
    padding: '11px 14px 11px 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'var(--neutral-01, #333F4E)',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    borderRadius: '8px',
    border: '1px solid rgba(0, 177, 119, 0.70)',
    background: 'rgba(0, 177, 119, 0.05)',
    marginLeft:"0px"}} placeholder="Enter Amount" value={props.amount} onChange={(e)=>{handleAmount(e)}} type="text"  maxLength={5}/>
      <div style={{display:"flex","justify-content": "space-between",marginTop:"5%"}}><div style={{    display: 'flex',
     
     padding: "1px",
     "justify-content": "center",
     "align-items": "center",
     gap: "18px",
    
     "border-radius": "10px",
border: "1px solid rgba(148, 163, 184, 0.54)",
"width": "73px",
"height": "38px",
  }} onClick={(e)=>{handleSetelectAmount(e,50)}}><h1>₹50</h1></div>
  <div style={{    display: 'flex',
     
     padding: "1px",
     "justify-content": "center",
     "align-items": "center",
     gap: "18px",
    
     "border-radius": "10px",
border: "1px solid rgba(148, 163, 184, 0.54)",
"width": "73px",
"height": "38px",
  }}  onClick={(e)=>{handleSetelectAmount(e,100)}}  ><h1>₹100</h1></div>

<div style={{    display: 'flex',
     
     padding: "1px",
     "justify-content": "center",
     "align-items": "center",
     gap: "18px",
    
     "border-radius": "10px",
border: "1px solid rgba(148, 163, 184, 0.54)",
"width": "73px",
"height": "38px",
  }}  onClick={(e)=>{handleSetelectAmount(e,200)}}><h1>₹200</h1></div>
  <div style={{    display: 'flex',
     
     padding: "1px",
     "justify-content": "center",
     "align-items": "center",
     gap: "18px",
     "width": "73px",
    "height": "38px",
     "border-radius": "10px",
border: "1px solid rgba(148, 163, 184, 0.54)"
  }} onClick={(e)=>{handleSetelectAmount(e,500)}}><h1>₹500</h1></div>
      </div>
      <div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        "justify-content":
                                                          "space-between",
                                                      
                                                        "margin-top": "21px",
                                                        "align-items":"center"

                                                      }}
                                                    >
                                                      <div  >
                                                        <h2
                                                          style={{
                                                            color:"#384F6F",

                                                            /* SM14 */
                                                            "font-family":"Roboto",
                                                            "font-size": "14px",
                                                            "font-style":"normal",
                                                            "font-weight": "600",
                                                            "line-height": "150%", /* 21px */
                                                            "letter-spacing ":"0.2px"
                                                          }}
                                                        >
                                                          Payment Method
                                                        </h2>
                                                        <p style={{color: 'var(--neutral-100, #718198)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%', // You can also use '21px' directly here if needed
    letterSpacing: '0.2px',}}>{localStorage.getItem("default_Recharge_PgName")=="RECHARGE_R_CARD"?"CARD":localStorage.getItem("default_Recharge_PgName")=="RECHARGE_R_WALLET"?"WALLET":"UPI"}</p>
                                                     
                                                      </div>
                                                      {
                                                        <div
                                                          style={{
                                                            "text-decoration":
                                                              "none",
                                                           
                                                          }}
                                                         onClick={goToRechargePaymentOption}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              "align-items":
                                                                "center",
                                                            }}
                                                          >
                                                            <h3
                                                              style={{
                                                                fontSize:
                                                                  "13px",
                                                                color:
                                                                  "#718198",
                                                              }}Change
                                                            >
                                                              Change
                                                            </h3>
                                                            <img
                                                              style={{
                                                                "margin-left":
                                                                  "5px",
                                                              }}
                                                              src={changeArrow}
                                                            />
                                                          </div>
                                                        </div>
                                                      }
                                                    </div>
                                              
                                                  </div>
          <div style={{display:"flex","justify-content":"space-around","margin-top": "8px"}}>
         
            <button onClick={(e)=>{props.AddIntoCart(e)}} className="rechargebtn" style={{    color: 'var(--neutral-white, #FFF)',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px', // You can also use '133.333%' directly here if needed
    textTransform: 'uppercase',}}>Proceed To Pay</button>
          </div>
          </div>
        </div>}


     {/*    <div  className="modalDeactive"   >
        <div className="modal-content-tagRecharge">
          
          <div onClick={(e)=>{CloseRechargeModal(e)}} style={{display:"flex","justify-content":"flex-end"}}> close</div>
       <div style={{paddingTop:"5%"}}>
        <h3 style={{ color: 'var(--neutral-01, #333F4E)',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal'}}>Select an amount to</h3>
      <p style={{  color: 'var(--neutral-03, #94A3B8)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%', // You can also use '21px' directly here if needed
    letterSpacing: '0.2px',}}>Recharge RFID Tag</p>
   
  
      
      </div>
      <input
      
    
    style={{ display: 'flex',marginTop:"5%",
    width: '100%',
    padding: '11px 14px 11px 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'var(--neutral-01, #333F4E)',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    borderRadius: '8px',
    border: '1px solid rgba(0, 177, 119, 0.70)',
    background: 'rgba(0, 177, 119, 0.05)',
    marginLeft:"0px"}} placeholder="Enter Amount" value={props.amount} onChange={(e)=>{handleAmount(e)}} type="text"  maxLength={5}/>
      <div style={{display:"flex","justify-content": "space-between",marginTop:"5%"}}><div style={{    display: 'flex',
     
     padding: "1px",
     "justify-content": "center",
     "align-items": "center",
     gap: "18px",
    
     "border-radius": "10px",
border: "1px solid rgba(148, 163, 184, 0.54)",
"width": "73px",
"height": "38px",
  }} onClick={(e)=>{handleSetelectAmount(e,50)}}><h1>₹50</h1></div>
  <div style={{    display: 'flex',
     
     padding: "1px",
     "justify-content": "center",
     "align-items": "center",
     gap: "18px",
    
     "border-radius": "10px",
border: "1px solid rgba(148, 163, 184, 0.54)",
"width": "73px",
"height": "38px",
  }}  onClick={(e)=>{handleSetelectAmount(e,100)}}  ><h1>₹100</h1></div>

<div style={{    display: 'flex',
     
     padding: "1px",
     "justify-content": "center",
     "align-items": "center",
     gap: "18px",
    
     "border-radius": "10px",
border: "1px solid rgba(148, 163, 184, 0.54)",
"width": "73px",
"height": "38px",
  }}  onClick={(e)=>{handleSetelectAmount(e,200)}}><h1>₹200</h1></div>
  <div style={{    display: 'flex',
     
     padding: "1px",
     "justify-content": "center",
     "align-items": "center",
     gap: "18px",
     "width": "73px",
    "height": "38px",
     "border-radius": "10px",
border: "1px solid rgba(148, 163, 184, 0.54)"
  }} onClick={(e)=>{handleSetelectAmount(e,500)}}><h1>₹500</h1></div>
      </div>
      <div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        "justify-content":
                                                          "space-between",
                                                      
                                                        "margin-top": "21px",
                                                        "align-items":"center"

                                                      }}
                                                    >
                                                      <div  >
                                                        <h2
                                                          style={{
                                                            color:"#384F6F",

                                                           
                                                            "font-family":"Roboto",
                                                            "font-size": "14px",
                                                            "font-style":"normal",
                                                            "font-weight": "600",
                                                            "line-height": "150%", 
                                                            "letter-spacing ":"0.2px"
                                                          }}
                                                        >
                                                          Payment Method
                                                        </h2>
                                                        <p style={{color: 'var(--neutral-100, #718198)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%', // You can also use '21px' directly here if needed
    letterSpacing: '0.2px',}}>{localStorage.getItem("default_Recharge_PgName")=="RECHARGE_R_CARD"?"CARD":localStorage.getItem("default_Recharge_PgName")=="RECHARGE_R_WALLET"?"WALLET":"UPI"}</p>
                                                     
                                                      </div>
                                                      {
                                                        <div
                                                          style={{
                                                            "text-decoration":
                                                              "none",
                                                           
                                                          }}
                                                         onClick={goToRechargePaymentOption}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              "align-items":
                                                                "center",
                                                            }}
                                                          >
                                                            <h3
                                                              style={{
                                                                fontSize:
                                                                  "13px",
                                                                color:
                                                                  "#718198",
                                                              }}Change
                                                            >
                                                              Change
                                                            </h3>
                                                            <img
                                                              style={{
                                                                "margin-left":
                                                                  "5px",
                                                              }}
                                                              src={changeArrow}
                                                            />
                                                          </div>
                                                        </div>
                                                      }
                                                    </div>
                                              
                                                  </div>
          <div style={{display:"flex","justify-content":"space-around","margin-top": "8px"}}>
         
            <button onClick={(e)=>{props.AddIntoCart(e)}} className="rechargebtn" style={{    color: 'var(--neutral-white, #FFF)',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px', // You can also use '133.333%' directly here if needed
    textTransform: 'uppercase',}}>Proceed To Pay</button>
          </div>
          </div>
        </div> */}
      {props.deactiveModal&&<div  className="modalDeactive"  /* style={{width:"90%",top:"20%", height:"392px",left:"5%"}} */ >
        <div className="modal-content-tagHistory">
          <img src={TagIcon} style={{"margin-left":"37%"}} />
          <h3 style={{"text-align":"center"}}>De-activate Tag?</h3>
          <p style={{ "text-align":"center","margin-top": "11px"}}>You won’t be able to use the tag after deactivation. For activating again, you will need the tag’s secret code.</p>
         <p style={{ "text-align":"center","margin-top": "11px","font-weight":"bold"}}>Your remaining balance ₹{props&&props.tagDeatils&&props.tagDeatils.balance.toFixed(2)} will be transferred to Daalchini Points.</p>
          
          <div style={{display:"flex","justify-content":"space-around","margin-top": "8px"}}>
          <button onClick={(e)=>{props.setDeactiveModal(false)}} className="canclebtn">Cancel</button>
            <button onClick={props.Deactive} className="deactivebtn">De-activate</button>
          </div>
          </div>
        </div>}

        {props.rechargeSucessModal&&<div  className="RechargeSuccessmodal"  /* style={{width:"90%",top:"20%", height:"392px",left:"5%"}} */ >
        <div className="modal-content-RechargeSuccessmodal">
         <div style={{display:"flex","justify-content":"space-around"}}> <img src={RechargeSuccess} style={{"width":"72px"}} /></div>
          <h3 style={{"text-align":"center",color: "var(--neutral-01, #333F4E)","font-family":"Roboto",
"font-size": "20px",
"font-style":"normal",
"font-weight": "700",
"line-height": "normal",marginTop:"11px"}}>Success</h3>
          <p style={{ "text-align":"center","margin-top": "21px"}}>Tag has been successfully recharged with ₹{props&&props.amount}</p>
          
          <div style={{display:"flex","justify-content":"space-around","margin-top": "27px"}}>
          <button style={{display: "flex",
"height": "43px",
"padding":"11px 24px",
"justify-content": "center",
"align-items":"center",
"gap": "10px",
/* "flex":"1 0 0", */
"border-radius":"12px",
color:"white",
width:"50%",
"background": "var(--primary-5, #00B177)"}} onClick={(e)=>{props.setRechargeSucessModal(false)}}>OK</button>
          </div>
          </div>
        </div>}





        <div className="_2yoTv ns7Wo">
          <div>
          <BpHistoryHeader title={' Tag History'} route={"/taglist"} height={"170px"} marginTop={"-57px"} />


            <div>
 
                        <div className="_2NVhy _3hjyL">
               
               <div
                           className="styles_slider__podEu"
                           data-testid="grid-slider"
                           style={{marginTop:"20%"}}
                         >
                    
                        
                          {[1].map((el, index) => {
                          return(
                          <div
                             className="styles_row__1ivP1 shadow-box"
                             data-testid="grid-row"
                             style={{
                               marginTop: "0%",
                               "margin-right": "12px",
                               "margin-left": "12px",
                               "margin-bottom": "22px",
                               height: "181px",
                               "border-radius":" 29px" ,
                               background:"white",
                               "z-index": "9",position:"fixed",
                               width:"94%"
                                                    
                             }}
                           >
                             <div
                               className="styles_slide__2c207"
                               style={{ marginRight: 0 }}
                             >
                               <div>
                                 <div
                                   className="styles_cardWithPadding__JE1QE"
                                   data-testid="home-restaurant-card-wrapper"
                                   style={{
                                     width: "calc((100vw - 0px) - 12px)",
                                   }}
                                 >
                                   <a
                                     data-testid="resturant-card-anchor-container"
                                     className="styles_container__fLC0R"
                                     aria-label="Restaurant name: Bakingo, 
 Cuisines: Bakery, Desserts, Beverages, Snacks,
 Area: Rohini,
 3.0 km away,
 Rating: 4.4,
 Delivers in: 25 mins,
 Cost is: RUPEES 300 for two,
 
 
 Double tap to open restaurant menu."
                                     tabIndex={0}
                                     role="button"
                                   >
                                     <div
                                       className="styles_imgContainer__1uHo5"
                                       aria-hidden="true"
                                     ></div>
                                 <div>
                                   <div style={{display:"flex","justify-content": "space-between","margin-top": "17px",marginLeft:"1rem"}}>
                                     <div style={{display:"flex", "align-items":"center","justify-content":"space-around"}}>
                                         <img style={{width:"25%"}} src={TagpayIcon}/>
                                    <div style={{"margin-left":"11px"}}>
                                    <h2 style={{fontSize:"18px"}}>{localStorage.getItem("TagUUID")}</h2>
                                    { <p style={{"margin-top":"1px",color:"darkgray"}}>{props&&props.tagDeatils&&props.tagDeatils.cardType}</p> }
                                     </div>     
                                   
                                   
                                    </div>
                                    
                                   </div>
                                   <div style={{display:"flex","justify-content": "space-around","margin-top": "17px","align-items":"flex-end",}}>
                                   <div>
                                    <div style={{}}>
                                  <p style={{color:"#718198"}}>Balance amount</p>
                                  <h1 style={{"color":"#333F4E","font-size": "20px"}}>₹{props&&props.tagDeatils&&props.tagDeatils.balance&&props.tagDeatils.balance.toFixed(2)} </h1>
                                  
                                  </div>
                                  
                             
                                   </div>
                                  <button  style={{display: "flex",
 padding:"9px 32px","justify-content":"center","align-items":"center",
 gap:"8px",
 
 "border-radius": "20px",
 background: " #00B177",
 
 
 color: "#FFF",
 "font-family":"Roboto",
 "font-size": "15px",
 "font-style": "normal",
 "font-weight": "500",
 "line-height":"20px", /* 133.333% */
 "text-transform": "capitalize",
 display: "flex",
padding: "9px 24px",
"justify-content":"center",
"align-items": "center",
gap: "8px"
 
 
 }}  onClick={(e)=>{props.setRechargeModal(true)}}>Recharge</button>
                                                                   </div>
 
                                 </div>
                                   </a>
                                 </div>
                                 <div />
                               </div>
                             </div>
                           </div>)
                           })}
                         </div>
                         
               </div>
               <div>
        <div  style={{paddingTop:"50%",background:"aliceblue"}}> <div style={{padding:"5%",display:"flex","justify-content":"space-between","align-items":"center","color":"#384F6F",fontSize:"16px","font-family": "Roboto",marginBottom:"10px"}}><h4>Transactions History</h4>

        <div onClick={props.DeactiveModal} style={{width:"82px",height:"27px",borderRadius:"29px",border:"1px solid #00B17761 ",background: "linear-gradient(0deg, rgba(0, 177, 119, 0.38), rgba(0, 177, 119, 0.38)),linear-gradien(0deg, rgba(0, 177, 119, 0.05), rgba(0, 177, 119, 0.05)), linear-gradient(0deg, #FFFFFF, #FFFFFF)","box-shadow": "0px 4px 10px 0px #384F6F1F"}}>
        <p style={{fontSize:"12px","text-align":"center",padding:"3px"}}>De-activate</p>
          </div>
        </div>
</div>
              { 
              props&&props.tagListHistory&&props.tagListHistory.data&&props.tagListHistory.data.payload.reverse().map((el, index) => {
               return(
               <div className="_2NVhy _3hjyL">
               <div
                           className="styles_slider__podEu"
                           data-testid="grid-slider"
                           style={{marginTop:"-8%"}}
                         >
                                  <div
                             className="styles_row__1ivP1 shadow-box"
                             data-testid="grid-row"
                             style={{
                               marginTop: "4%",
                               "margin": "4% 12px 22px",
                               
                               height:"81px",
                               background: "white",
                               "border-radius":"16px",background:"#FFF","box-shadow":"0px 4px 10px 0px rgba(56, 79, 111, 0.12)"                      
                             }}
                           >
                             <div style={{ display:"flex","align-items":"center","justify-content":"space-around",width:"100%"}}>
                             <div style={{}}>
                               {el.opsType=="debit"?<img style={{width:"44px",}} src={PaidIcon}/>:
                               <img style={{width:"44px",}} src={Refundicon}/>
                               }
                               </div>
                               {/* <div> */}
                               <div style={{marginLeft:"-10%"}}>
                                 {el.opsType=="debit"?<h3 style={{color:"#333F4E",
 
 /* SM14 */
 "font-family": "Roboto",
 "font-size":"14px","font-style":"normal","font-weight":"600",
 "line-height": "150%", /* 21px */
 "letter-spacing": "0.2px"}}>Paid amount</h3>:<>{el.transactionType=="money_add"?<h3 style={{color:"background: #583C43",fontSize:"14px",fontWeight:"500"}}>Balance added</h3>:<h3 style={{color:"#333F4E",fontSize:"14px",fontWeight:"500"}}>Refunded amount</h3>}</>}
                                 <p style={{"margin-top":"5px",fontSize:"12px","color":"#64748B"}}>ID: <TruncatedOrderId text={el.orderId} limit={20}/></p>
                                 </div>
 
                                 <div style={{}}>
                                {el.opsType=="debit"?<h3 style={{color: "#F43F5E",
 
 /* M14 */
 "font-family":"Roboto","font-size":"14px",
 display: "flex",
     "justify-content":"flex-end",
 "font-style": "normal",
 "font-weight":"500","line-height":"150%","letter-spacing":"0.2px"}}>₹{""}{el.amount}</h3>:  <>{el.transactionType=="money_add"?<h3 style={{display:"flex","justify-content":"flex-end",color: "#00B177",
             
 /* M14 */
 "font-family":"Roboto",
 "font-size":"14px",
 "font-style": "normal",
 "font-weight": "500",
 "line-height": "150%", /* 21px */
 "letter-spacing":"0.2px"}}>₹{""}{el.amount}</h3>:<h3 style={{display:"flex","justify-content":"flex-end",color: "#583C43",
 
 /* M14 */
 "font-family":"Roboto",
 "font-size":"14px",
 "font-style": "normal",
 "font-weight": "500",
 "line-height": "150%", /* 21px */
 "letter-spacing":"0.2px"}}>₹{""}{el.amount}</h3>}</>}
                                   <p style={{color: "#94A3B8",
 "text-align": "right",
 "font-family": "Roboto",
 "font-size": "11px",
 "font-style":"normal","font-weight":"400",
 "line-height": "150%", /* 16.5px */
 "letter-spacing":"0.2px"}}>{el.timestamp.slice(0, 10)}</p>
                                   </div>
                                   </div>
 
                                {/*  </div> */}
 
 
 
                           </div>
                           </div>
 
               </div>)
               })}</div>




            </div>
          </div>
        </div>
      </div>
    </>:<></>}
    </>
    )
}

export default TagpayHistory;