import React from "react";
import { Link, useNavigate } from "react-router-dom";
import nextArrow from "../../assets/nextArrow.png";
import ActiveOrdersHeader from "../Header/ActiveOrdersHeader";
import { useState } from "react";

import "../ActiveOrder/activeorder.css";
import { useEffect } from "react";
import Qr from "../../assets/qrmark.svg";

import { Cancel } from "axios";
import CancleIcon from "../../assets/Rejected.png";
import CancleDone from "../../assets/cancle.png";
import SadFace from "../../assets/SadFace.png";
import { mpClick, mpHelp } from "../../Mixpanel";
let minutes;
let seconds;
let v=3;

const modalStyles = {
  overlay: {
    
    top: 0,
    left: 0,
    width: '100%',
    
    backgroundColor:"rgba(224, 231, 239, 1)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: '#fff',
   
    borderRadius: '8px',
   /*  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)', */
    maxWidth: '500px',
    width: '90%',
 
    marginTop: "2vw",
     marginBottom:"2vw"
  
  },


  companyName:{
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    paddingTop: '20px',
   
    paddingBottom:"5px",
    fontWeight: 500,
    lineHeight: '16.41px',
    letterSpacing: '0.02em',
    textAlign: 'start',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color:"background: rgba(113, 129, 152, 1)"

  },
  address:{
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',

    fontWeight: 400,
    lineHeight: '14.06px',
    textAlign: 'start',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color: " rgba(113, 129, 152, 0.7)"
  },
  pickupCodeSection:{
    backgroundColor:"rgba(210, 238, 229, 0.1)",
    marginTop: "5vw"
  },
  textPickupCode:{

    fontFamily: 'Roboto, sans-serif',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '21.09px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color: "rgba(56, 79, 111, 1)"

    },

    textPickupCodeSub:{

      fontFamily: 'Roboto, sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '50px',
      letterSpacing: '0.2px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color: "rgba(100, 116, 139, 1)",
      marginTop:"2vw",
     

    },
    scanText:{
      fontFamily: 'Roboto, sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.2px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color:"rgba(115, 131, 153, 1)",
      marginTop:"3vw"
    },
    scanBtn:{
border:"1px solid  rgba(0, 177, 119, 1)",
padding:"4vw",
borderRadius:"12px",
color: "rgba(0, 177, 119, 1)"


    },



    iteam:{


      fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '18.75px',
    textAlign: 'center',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color:'rgba(113, 129, 152, 1)'



    },
    price:{


      fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '18.75px',
    textAlign: 'center',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color:' rgba(0, 177, 119, 1)',
    marginLeft:"19vw"



    }
    ,
    noBtn:{
      backgroundColor: "rgb(0, 177, 119)",
      padding: "3%",
      width: "30vw",
      textAlign: "center",
      color: "white",
      fontWeight: 500,
      fontSize: "5vw",
      borderRadius: "8px",

      

    },
    yesBtn:{
     
      padding: "3%",
      width: "30vw",
      textAlign: "center",
      color: "rgb(0, 177, 119)",
      fontWeight: 500,
      fontSize: "5vw",
      borderRadius: "8px",
      border:"1px solid rgb(0, 177, 119)"

      
    }


};


function ActiveOrder(props) {


const navigate=useNavigate()
const [isOpen, setIsOpen] = useState(true);
console.log("props",props.vendingMachineAddress)


const goToPickUpOrder=(e,orderID,machineId)=>{
  
  localStorage.setItem("contactLessOrderID",orderID)
  localStorage.setItem("contactLessMachineID",machineId)
  navigate("/pickupOrder")
}



 const [diffInMs,setDiffInMs]=useState()


 function Truncatedproname({ text, limit ,fontSize}) {
  if (text&&text.length <= limit) {
    return <span style={{ fontSize: "14px", color: "#384F6F" }}>{text}</span>;
  }
  return (
    <span style={{ fontSize: fontSize?fontSize:"14px", color: "#384F6F" }}>{`${text&&text.substring(
      0,
      limit
    )}`}</span>
  );
}

function TruncatedVmadd({ text, limit }) {
  if (text.length <= limit) {
    return <span style={{color: "rgba(113, 129, 152, 1)"
    }}>{text}</span>;
  }
  return (
    <span style={{color: "rgba(113, 129, 152, 1)"
    }}>{`${text&&text.substring(
      0,
      limit
    )}`}</span>
  );
}
function TruncatedVmName({ text, limit }) {
  if (text.length <= limit) {
    return <span style={{color: "rgba(113, 129, 152, 0.7)"
    }}>{text}</span>;
  }
  return (
    <span style={{color: "rgba(113, 129, 152, 1)"
    }}>{`${text&&text.substring(
      0,
      limit
    )}`}</span>
  );
}
  
  //console.log("ActiveOrderepochtime",props.epochtime)
  const updatetime=()=>{
    //console.log("ActiveOrderepochtime update",props&&props.epochtime&&props.epochtime)
    let alltimer=[]
    props&&props.epochtime&&props.epochtime[0]&&props.epochtime.map((el,index)=>{
      //console.log("ActiveOrderepochtime update hello update")
      const time=(props.epochtime[index] - Date.now())
      const currentMinutes = Math.floor(time / 60000);
      //console.log("timemap",index,currentMinutes)
      alltimer.push(currentMinutes)
      setDiffInMs(alltimer)

    })
    for (let i = 0; i <props&& props.epochtime.length; i++) {
      //console.log("ActiveOrderepochtime update hello update")
      //console.log(`epochtime for Object ${i + 1}: ${props.epochtime[i]}`);
    
     // current epoch time in seconds- currentDate.getTime();
    // current time in minutes
    
    //console.log(" currentMinutes  diffInMs",currentMinutes*60,currentMinutes)
 
    }
  
  }


  const helpFun=(e,order_id)=>{
    mpHelp({EventName:"Help_clicked",data:{order_id:order_id}})

    window.location.replace(`https://api.whatsapp.com/send/?phone=917289005034&text=Hello%2C I+need+assistance+with+my+order.+My+order+ID +is + ${order_id}&type=phone_number&app_absent=0`)
  }
   useEffect(()=>{
 updatetime()
},[props.epochtime,diffInMs]) 
  //console.log("props diffInMs----> ",diffInMs)


  let percentage
  let convertPer
  //let remainingMinutes
  //let remainingSeconds
  let mm = 400;
  const [m, setM] = useState(0);
  const [s, setS] = useState(0);
  const [remainingSeconds,setRemainingSeconds]=useState(0)
  const [remainingMinutes,setRemainingMinutes]=useState(0)
  const [minutes,setMinutes]=useState(0);
  const [count, setCount] = useState(0);


 useEffect(() => {
  // Function to recalculate the value
  const recalculateValue = () => {
    // Perform the calculation here
   
   
    updatetime()
    //setValue(newValue);
  };
  

 

  // Schedule the recalculation every 1 minute (60000 milliseconds)
  const intervalId = setInterval(recalculateValue, 60000);

  // Clean up the interval on component unmount
  return () => {
    clearInterval(intervalId);
  };
}, [/* props.epochtime,diffInMs */]);


const CreatedTime=(props)=>{

  console.log("TIME",props.time)
  var date = new Date(props.time);

  // Convert to Indian time string
  var indianTime = date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
  
  console.log("indianTime",indianTime);
  




  

return(


  <>
  <>{indianTime}</>
  </>
)

 }




/*  const intervalId = setInterval(() => {
    
  console.log("props.currentMinutes",props.currentMinutes)
  },1000);    */

useEffect(()=>{


  


  },[props.currentMinutes])
















  const machineId = localStorage.getItem("machineId");

  //console.log("props Orders", props);
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  const navigator = useNavigate();




  return (
    <>
      <ActiveOrdersHeader title="Active Order" route="/" />
  
      {/* Cancel Order Modal */}

      {props?.cancleflag && (
        <div className="modal">
          <div className="modal-content">
            <img
              src={CancleIcon}
              alt="Cancel Icon"
              style={{ position: "absolute", top: "-41px", left: "39%" }}
            />
            <div style={{ textAlign: "center", marginTop: "15%" }}>
              <h3
                style={{
                  color: "#384F6F",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "28px",
                  letterSpacing: "1px",
                }}
              >
                Cancel Order?
              </h3>
              <p
                style={{
                  marginTop: "3%",
                  color: "rgba(56, 79, 111, 0.69)",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "20px",
                }}
              >
                Do you want to cancel the order?
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "9%" }}>
              <button
              style={modalStyles.noBtn}
                onClick={() => props.setCancleflag(false)}
             
              
              >
                NO
              </button>
              <button
                onClick={props.CancleOrder}
               style={modalStyles.yesBtn}
              >
                YES
              </button>
            </div>
          </div>
        </div>
      )}
  
      {/* Cancel Error Modal */}
      {props?.cancleErrorflag && (
        <div className="modal">
          <div className="modal-content">
            <img
              src={SadFace}
              alt="Sad Face"
              style={{ position: "absolute", top: "-50px", left: "35%" }}
            />
            <div style={{ textAlign: "center", marginTop: "15%" }}>
              <h3
                style={{
                  color: "#384F6F",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "28px",
                  letterSpacing: "0.5px",
                }}
              >
                Order can’t be cancelled
              </h3>
              <p
                style={{
                  marginTop: "3%",
                  color: "rgba(56, 79, 111, 0.69)",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "15px",
                }}
              >
                {props.cancleErrorMsg}
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "9%" }}>
              <button
                onClick={() => props.setCancleErrorflag(false)}
                className="nobtn"
                style={{
                  width: "72%",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding:0
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
  
      {/* Cancel Success Modal */}
      {props?.cancleSuccessflag && (
        <div className="modal">
          <div className="modal-content">
            <img
              src={CancleDone}
              alt="Cancel Done"
              style={{ position: "absolute", top: "-65px", left: "30%" }}
            />
            <div style={{ textAlign: "center", marginTop: "15%" }}>
              <h3
                style={{
                  color: "#384F6F",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "28px",
                  letterSpacing: "1px",
                }}
              >
                {props.cancleSuccessMsg}
              </h3>
              <p
                style={{
                  marginTop: "3%",
                  color: "rgba(56, 79, 111, 0.69)",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "20px",
                }}
              >
                Your order has been cancelled
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "7%" }}>
              <Link
                to={`/items?machineId=${machineId}`}
                className="nobtn"
                style={{ width: "72%", textDecoration: "none" ,padding:0}}
              >
                <button
                  onClick={() => props.setCancleSuccessflag(false)}
                  style={{
                    width: "72%",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  OK
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
  
      {/* Active Orders */}
      {UserJWTAuth ? (
        <div style={{overflowY:"scroll",overflowX:"hidden"}}>
          {props?.activeOrderList1 &&
            props.activeOrderList1
              .map((data, index) => (
                <div key={index}>
                  {/* Order details */}
                  <div>
  
  <div style={modalStyles.overlay}>
    <div style={modalStyles.modal}>
      <div style={{padding:"5%"}}>
      <div style={{display:"flex",       "align-items": "flex-end",
    "justify-content": "space-between"}}>
      <div>
      <h2 style={modalStyles.companyName}><TruncatedVmadd  text={data.vendingMachineAddress} limit={40}/></h2>
      <p style={modalStyles.address}><TruncatedVmadd text={data.vendingMachineAddress} limit={40}/>

      </p>
      </div>
      <div>
      { diffInMs&&diffInMs[index]>0 ?<div style={{"text-align":"center"}}>
                        <h1 style={{fontSize:"12px",color:" rgba(113, 129, 152, 1)",    fontWeight: "500"}}>Expire in </h1>
                        <h1 style={{color:"red",marginTop:"-7px",fontSize:"14px"}}>{diffInMs&& diffInMs[index]}<span style={{"font-size":"20px"}}>{" "}min</span> </h1>
                      </div>:
                      <h1 style={{color:"red",fontSize:"18px",}}>Expired </h1>
                      }
        </div>
      </div>
      </div>
      <div style={modalStyles.pickupCodeSection} >
        <div style={{padding:"20px",paddingBottom:0,  }}>
        <h1 style={modalStyles.textPickupCode}>To pick your order</h1>
        <p style={modalStyles.textPickupCodeSub}>Enter the pickup code on the vending machine </p>
        <div style={{padding:"3vw",marginTop: "0vw",paddingTop:"0vw"}}>
        <div style={{display:"flex",    justifyContent: "center"}}>
          <div style={{ marginLeft:"2vw", border: "1px solid rgba(0, 177, 119, 0.5)",  width: "16vw",padding:"3vw",
textAlign: "center",borderRadius:"8px"}}>
  <p style={{fontSize:"24px",fontWeight:"700",color:"rgba(0, 177, 119, 0.5)"}}>
  {data &&data.dcCode[0]}
</p>


          </div>
          <div style={{ marginLeft:"2vw",padding:"3vw",  border: "1px solid rgba(0, 177, 119, 0.5)",  width: "16vw",
textAlign: "center",borderRadius:"8px"}}>
  <p style={{fontSize:"24px",fontWeight:"700",color:"rgba(0, 177, 119, 0.5)"}}>
  {data &&data.dcCode[1]}
</p>


          </div>
          <div style={{  marginLeft:"2vw",padding:"3vw",  border: "1px solid rgba(0, 177, 119, 0.5)",  width: "16vw",
textAlign: "center",borderRadius:"8px"}}>
  <p style={{fontSize:"24px",fontWeight:"700",color:"rgba(0, 177, 119, 0.5)"}}>
  {data &&data.dcCode[2]}
</p>


          </div>
          <div style={{  marginLeft:"2vw", padding:"3vw", border: "1px solid rgba(0, 177, 119, 0.5)",  width: "16vw",
textAlign: "center",borderRadius:"8px"}}>
  <p style={{fontSize:"24px",fontWeight:"700",color:"rgba(0, 177, 119, 0.5)"}}>
  {data &&data.dcCode[3]}
</p>


          </div>
        </div>
        <p style={{ textAlign: "center", color: "rgba(115, 131, 153, 1)", marginTop: "5vw" }}>
  <span style={{color:"rgba(217, 217, 217, 1)"}}>----------</span> &nbsp;&nbsp; Or &nbsp;&nbsp;  <span style={{color:"rgba(217, 217, 217, 1)"}}>----------</span>
</p>

        <p style={modalStyles.scanText}>Scan the QR code on the vending machine </p>
        </div>

        </div>
        <div style={{padding:"20px",paddingBottom:"50px",paddingTop: 0}}>
        <div style={modalStyles.scanBtn}>
          <div style={{    display: "flex",justifyContent: "center",
alignItems:"center" }}  onClick={(e)=>{goToPickUpOrder(e,data.orderId,data.vendingMachineId)}}>
            <h1 style={{     fontFamily: 'Roboto, sans-serif',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '18.75px',
  textAlign: 'center',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
  marginRight: "10px"}}>Scan QR Code</h1>
  <div>

  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
<g clip-path="url(#clip0_3466_4500)">
<path d="M4.33333 4.33333H10.8333V10.8333H4.33333V4.33333ZM21.6667 4.33333V10.8333H15.1667V4.33333H21.6667ZM15.1667 16.25H17.3333V14.0833H15.1667V11.9167H17.3333V14.0833H19.5V11.9167H21.6667V14.0833H19.5V16.25H21.6667V19.5H19.5V21.6667H17.3333V19.5H14.0833V21.6667H11.9167V17.3333H15.1667V16.25ZM17.3333 16.25V19.5H19.5V16.25H17.3333ZM4.33333 21.6667V15.1667H10.8333V21.6667H4.33333ZM6.5 6.5V8.66667H8.66667V6.5H6.5ZM17.3333 6.5V8.66667H19.5V6.5H17.3333ZM6.5 17.3333V19.5H8.66667V17.3333H6.5ZM4.33333 11.9167H6.5V14.0833H4.33333V11.9167ZM9.75 11.9167H14.0833V16.25H11.9167V14.0833H9.75V11.9167ZM11.9167 6.5H14.0833V10.8333H11.9167V6.5ZM2.16667 2.16667V6.5H0V2.16667C0 1.59203 0.228273 1.04093 0.634602 0.634602C1.04093 0.228273 1.59203 0 2.16667 0L6.5 0V2.16667H2.16667ZM23.8333 0C24.408 0 24.9591 0.228273 25.3654 0.634602C25.7717 1.04093 26 1.59203 26 2.16667V6.5H23.8333V2.16667H19.5V0H23.8333ZM2.16667 19.5V23.8333H6.5V26H2.16667C1.59203 26 1.04093 25.7717 0.634602 25.3654C0.228273 24.9591 0 24.408 0 23.8333V19.5H2.16667ZM23.8333 23.8333V19.5H26V23.8333C26 24.408 25.7717 24.9591 25.3654 25.3654C24.9591 25.7717 24.408 26 23.8333 26H19.5V23.8333H23.8333Z" fill="#00B177"/>
</g>
<defs>
<clipPath id="clip0_3466_4500">
<rect width="26" height="26" fill="white"/>
</clipPath>
</defs>
</svg>
  </div>

          </div>
        </div>
        </div>

      </div>
      <div style={{position:"relative",left: "85vw", top: "-4vw"
}}>
  <div style={{width:"30px",height:"30px",background:"rgb(224, 231, 239)",borderRadius:"30px"}}>

  </div>
  </div>
  <div style={{position:"relative",left: "-3vw", top: "-11vw"
}}>
  <div style={{width:"30px",height:"30px",background:"rgb(224, 231, 239)",borderRadius:"30px"}}>

  </div>
 
  </div>
  <div style={{padding:"20px",    paddingTop:"0",marginTop: "-30px","margin-bottom": "-11vw"}}>
    <div style={{/* display: "flex" */justifyContent:"space-between"}}>
    <h1 style={{color: "rgba(113, 129, 152, 1)"}}> 
    Order ID:# 
    <span style={{color:"rgba(56, 79, 111, 1)"}}>{data && data.orderId}</span>
    </h1>
    <p style={{color:"rgba(113, 129, 152, 1)"}}>
    <CreatedTime time= {data && data.createdAt}/>
    </p>
    </div>
    </div>
          {/* Line Items */}
          <div style={{padding:"5%"}}>
                  {data.lineItems.map((el, idx) => (

                  <div style={{border:"1px solid rgba(56, 79, 111, 0.08)",borderRadius:"10px",    marginTop:"5%"}}>
                    <div style={{padding:"5%",display:"flex"}}>
                    <img style={{width:"65px"}}  src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.imageUrl}`}/>
                    <div style={{marginLeft:"20px"}}>
                    <h1 style={{color:"rgba(56, 79, 111, 1)"}}><Truncatedproname text={el.productName} limit={25} fontSize={"16px"}/></h1>
                    <div>
                      <div style={{display:"flex",marginTop:"22px"}}>
                      <h1 style={modalStyles.iteam}>{el.unitCount} Items</h1>
                      <h1 style={modalStyles.price}>Rs.{""}
                      {el.offerPrice.toFixed(2)}</h1>
                      </div>
                    </div>
                    </div>
                    </div>
              
              
                  </div>
                  
                  ))}
                  </div>
                  <div style={{padding:"5%",paddingTop:"0vw"}}>
                  <div style={{border:"1px solid rgba(56, 79, 111, 0.08)",padding:"5%",borderRadius:"10px"}} >
                  <div style={{display:"flex", justifyContent:"space-between"}}><h1 style={{color:"background: rgba(56, 79, 111, 1)"}}>Grand Total</h1>
                  <h1 style={{color:"background: rgba(56, 79, 111, 1)"}}>₹ {props && data.amount&&data.amount.toFixed(2) }</h1>
                  
                  </div>
                  </div>
                  </div>
                  <div  style={{padding:"5%"}}>
                    <div style={{display:"flex",  justifyContent:"space-between" }}>

                    <div style={{    height: "13vw",
    border: "1px solid rgba(113, 129, 152, 0.38)",
    padding: "4%",
    borderRadius: "10px",
    textAlign: "center",
    width: "39vw"}} onClick={(e) => props.CancleOrderset(e,data.orderId)}>
                    Cancel Order

                      </div>

                      <Link  to="https://api.whatsapp.com/send/?phone=917289005034&text=Hello%2C%20I+need+assistance+with+my+order.+My+order+ID%20+is%20+%20BAA9386717614F3E&type=phone_number&app_absent=0"style={{    height: "13vw",
    border: "1px solid rgba(113, 129, 152, 0.38)",
    padding: "4%",
    borderRadius: "10px",
    textAlign: "center",
    width: "39vw"}}>
                    Help & Support

                      </Link>
</div>

                  </div>

    </div>

  
    
  </div>
  
 
</div>
  
            
                </div>
              ))
              .reverse()}
        </div>
      ) : (
        <div style={{ marginTop: "50%", textAlign: "center" }}>
          <h1>Something Went Wrong</h1>
        </div>
      )}
    </>
  );
  
      
 
}

export default ActiveOrder;
