import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Procced from "../Procced/component";
import backArrow from '../../assets/backArrow.png'
import { useRef } from "react";
import HeaderSecondary from "../Header/HeaderSecondary";
import notFound from '../../assets/notFound.png'
import searchProduct from '../../assets/searchProduct.png'
import Company from '../../assets/Company.png'
import blackNextArrow from '../../assets/blackNextArrow.png'
import { Link, Navigate, useNavigate } from "react-router-dom";
import ActiveOrdersBar from "../ActiveOrdersBar/component";
import GlobalSearchHeader from "../Header/GlobalSearchHeader";
import ReplceCard from '../../assets/ReplceCard.png'
import MixPnl, { mpClick, mpSearchResult, mpSearchResultClicked } from "../../Mixpanel";

const image_base_url = "https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/";


function GlobalSearch(props) {
  console.log("globalsearchTerm", props.globalsearchTerm)
  const inputRef = useRef(null);
  const navigate = useNavigate()
  useEffect(() => {
    //inputRef.current.focus();
    window.scrollTo(0, 0); 
  }, []);
  //let flagMp = true


  const GotoMachineFun2=(e,id)=>{
    mpClick({ EventName: "Vm_clicked", data: { source: "Global Vm wise inventory", vmid: id } })
    navigate(`/items?machineId=${id}`)

  }

  const GotoMachineFun = (e, id) => {


    mpSearchResultClicked({ EventName: "Global_search_result_clicked", data: { ResultType: "machine", ResultId: id } })
    navigate(`/items?machineId=${id}`)
  }

  function TruncatedHeading({ text, limit }) {
    if (text && text.length <= limit) {
      return <p>{text}</p>;
    }
    return <p>{`${text && text.substring(0, limit)}...`}</p>;
  }
  function TruncatedHeading4({ text, limit }) {
    console.log("TruncatedHeading4", text)
    if (text && text.length <= limit) {
      return <>{text};</>
    }
    return <>{`${text && text.substring(0, limit)}...`}</>;
  }



  function TruncatedHeading3({ text, limit }) {
    if (text && text.length <= limit) {
      return <p>{text}</p>;
    }
    return <p>{`${text && text.substring(0, limit)}...`}</p>;
  }
  function TopNavBar3(props) {
    function TruncatedHeading({ text, limit }) {
      if (text && text.length <= limit) {
        return (
          <h3
            style={{ fontSize: "18px", color: "#583C43", "font-weight": "500" }}
          >
            {text}
          </h3>
        );
      }
      return (
        <h3
          style={{ fontSize: "18px", color: "#583C43", "font-weight": "500" }}
        >{`${text && text.substring(0, limit)}...`}</h3>
      );
    }
    function TruncatedSubheading({ text, limit }) {
      if (text && text.length <= limit) {
        return (
          <p style={{ width: "200%", fontSize: "13px", color: "#583C43" }}>
            {text}
          </p>
        );
      }
      return (
        <p
          style={{ width: "200%", fontSize: "13px", color: "#583C43" }}
        >{`${text.substring(0, limit)}...`}</p>
      );
    }

    return (
      <div className="_2456r">

        <div className="_3nxa7" data-testid="listing-header-location">
          <button
            className="_1NdRR"
            aria-label="Selected address is Delhi, India; Double tap to change address."
            id="change-address-btn-id"
          >
            <div style={{ display: "flex", "align-items": "center" }}>
              <div>
                <img
                  style={{ height: "18px", "margin-right": "12px" }}
                  src={backArrow}
                  onClick={(e) => props && props.props && props.props.backFromGlobalSearch()}
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <TruncatedHeading
                  text={`${props.title}`}
                  limit={20}
                ></TruncatedHeading>
                {props && props.subtitle && (
                  <TruncatedSubheading
                    text={`${props.subtitle}`}
                    limit={40}
                  ></TruncatedSubheading>
                )}
              </div>
            </div>
          </button>
        </div>
      </div>
    );
  }

  function TopNavBar2(props) {
    function TruncatedHeading({ text, limit }) {
      if (text && text.length <= limit) {
        return (
          <h3
            style={{ fontSize: "18px", color: "#583C43", "font-weight": "500" }}
          >
            {text}
          </h3>
        );
      }
      return (
        <h3
          style={{ fontSize: "18px", color: "#583C43", "font-weight": "500" }}
        >{`${text.substring(0, limit)}...`}</h3>
      );
    }
    function TruncatedSubheading({ text, limit }) {
      if (text && text.length <= limit) {
        return (
          <p style={{ width: "200%", fontSize: "13px", color: "#583C43" }}>
            {text}
          </p>
        );
      }
      return (
        <p
          style={{ width: "200%", fontSize: "13px", color: "#583C43" }}
        >{`${text.substring(0, limit)}...`}</p>
      );
    }

    return (
      <div className="_2456r">
        <div className="_3nxa7" data-testid="listing-header-location">
          <button
            className="_1NdRR"
            aria-label="Selected address is Delhi, India; Double tap to change address."
            id="change-address-btn-id"
          >
            <div style={{ display: "flex", "align-items": "center" }}>
              <div onClick={(e) => props.props.globalSearchflagBackbtn(e)}>
                <img
                  style={{ height: "18px", "margin-right": "12px" }}
                  src={backArrow}
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <TruncatedHeading
                  text={`${props.title}`}
                  limit={20}
                ></TruncatedHeading>
                {props && props.subtitle && (
                  <TruncatedSubheading
                    text={`${props.subtitle}`}
                    limit={40}
                  ></TruncatedSubheading>
                )}
              </div>
            </div>
          </button>
        </div>
      </div>
    );
  }

  console.log("vmlist cartCount", props.cartCount, props.locationAllow);

  useEffect(() => { }, []);


  console.log(
    "activeOrderList CategoryDetails",
    props.activeOrderList,
    props.CategoryDetails
  );
  return (
    <>
      {props && props.replaceCard && (
        <div className="modal">
          <div className="modal-content">
            <img
              src={ReplceCard}
              style={{ position: "absolute", top: "-41px", left: "39%", width: "73px" }}
            />
            <div style={{ "text-align": "center", "margin-top": "15%" }}>
              <h3 style={{
                color: "#384F6F",
                "text-align": "center",
                "font-family": "Roboto",
                "font-size": "16px",
                "font-style": "normal",
                "font-weight": "700",
                "line-height": "28px", /* 175% */
                "letter-spacing": "1px"
              }}>Replace cart items ?</h3>
              <p style={{
                marginTop: "3%", color: "rgba(56, 79, 111, 0.69)",
                "text-align": "center",
                "font-family": "Roboto",
                "font-size": "12px",
                "font-style": "normal",
                "font-weight": "400",
                "line-height": "20px"
              }}>
                Your current cart will be cleared when you add items from a different vending machine.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                "justify-content": "space-around",
                marginTop: "3%",
              }}
            >
              <button
                onClick={(e) => {
                  props.setReplceCard(false);
                }}
                className="yesbtn"
                style={{
                  display: "flex",
                  "width": "118px",
                  "height": "41px",
                  "padding": "10px",
                  "justify-content": "center",
                  "align-items": "center",
                  "gap": "10px",
                  "flex-shrink": "0",
                }}
              >
                NO
              </button>
              <button onClick={props.fetchClearPreCard} className="nobtn" style={{
                display: "flex",
                width: "118px",
                "height": "41px",
                "padding": "10px",
                "justify-content": "center",
                "align-items": "center",
                gap: "10px", "flex-shrink": "0"
              }}>
                YES
              </button>
            </div>
          </div>
        </div>
      )}
      {props && props.ActiveOrderItemsListGlobal && props.ActiveOrderItemsListGlobal[0] && <ActiveOrdersBar

        itemname={props && props.ActiveOrderItemsListGlobal[0] && props.ActiveOrderItemsListGlobal[0].lineItems[0].productName}
        itemlength={props && props.ActiveOrderItemsListGlobal[0] && props.ActiveOrderItemsListGlobal[0].lineItems.length}
        orderLength={props && props.ActiveOrderItemsListGlobal && props.ActiveOrderItemsListGlobal.length}
        Top={props && props.ActiveOrderItemsListGlobal[0] ? props.ActiveOrderItemsListGlobal[0] ? "77px" : "65px" : "65px"}
      />}


      {props && props.PreCartListGlobal && props.PreCartListGlobal[0] && <Procced
        onClick={localStorage.setItem("mycart", true)}
        cartCount={props && props.cartCount}
        totalamount={props && props.carttotalAmount}
        bottom={"0px"}
      />}
      {(
        <>
          <GlobalSearchHeader
            title={"Search"}

            route="/"
          />

          <div
            style={{
              background:
                "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
              //"paddingBottom":Boolean((props.ActiveOrderItems[0])&&(props.ActiveOrderItemsListGlobal[0]))?"12.5rem":Boolean(props.ActiveOrderItems[0])?"9.5rem":props.ActiveOrderItemsListGlobal[0]&&"8.5rem",
            }}
            className=" pb-1 overflow-y-auto h-screen"

          >
            {!props.vmwiseInventoryFlag && (
              <>
                <div
                  className="zGVn2e"
                  onClick={(e) => props.globalSearchflag(e)}
                >
                  <button
                    className="Cdl0yb"
                    id="tsuid_1"
                    style={{ display: "none" }}
                  >
                    <div className="Z7Bonf">
                      <span className="z1asCe IW3j9">
                        <svg
                          focusable="false"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
                        </svg>
                      </span>
                    </div>
                  </button>
                  <button
                    className="Tg7LZd search_button_suggest"
                    aria-label="Google Search"
                    type="button"
                  >
                    <div className="gBCQ5d">
                      <span className="z1asCe MZy1Rb">
                        <svg
                          focusable="false"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                        </svg>
                      </span>
                    </div>
                  </button>
                  <button
                    className="Tg7LZd u6FVyd"
                    aria-label="Google Search"
                    type="button"
                  />
                  <div className="SDkEP">
                    <div
                      className="a4bIc"
                      aria-expanded="false"
                      aria-haspopup="listbox"
                      role="combobox"
                    >
                      <input
                        placeholder="Find items or machine"
                        className="gLFyf"
                        type="text"
                        maxLength={2048}
                        name="q"
                        aria-autocomplete="list"
                        aria-describedby="sb_input_box_aria_description_FD34Y_KZG-uWseMPwK6WmAQ_1"
                        tabIndex={0}
                        title="Search"
                        ref={inputRef}
                        onChange={(e) => {
                          props.globalSearch(e.target.value);
                        }}
                      />
                      <div
                        hidden
                        id="sb_input_box_aria_description_FD34Y_KZG-uWseMPwK6WmAQ_1"
                      >
                        <span>Double-tap to search Google.</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <span
                    className="styles_headerContainerSubtitle__1WRg5"
                    aria-hidden="true"
                  ></span>
                </div>

                <div



                >
                  <div
                    className="styles_slider__podEu"

                  >
                    <>
                      {" "}
                      {props.globalsearchTerm ? (
                        <>
                          {console.log("statis", ((props.CategoryDetails[0]) || (props.ItemDetails[0]) || (props.MachineDetails[0])))}


                          {
                            (props.CategoryDetails[0] ||
                              props.ItemDetails[0] ||
                              props.MachineDetails[0]) ? (
                              <div>
                                {props.CategoryDetails[0] &&
                                  props.CategoryDetails.map(
                                    (el, index) => (
                                      <div
                                        className=" "
                                        data-testid="grid-row"
                                        style={{
                                          marginBottom: 20,
                                          marginTop: "4%",
                                          "border-radius": "10px",
                                          "margin-right": "12px",
                                          "margin-left": "12px",
                                        }}
                                        onClick={(e) => {
                                          props.vmWiseInvertory(
                                            e,
                                            el.id,
                                            "categoryId",
                                            el.name
                                          );
                                        }}
                                      >
                                        <div
                                          className="styles_slide__2c207"
                                          style={{ marginRight: 0 }}
                                        >
                                          <div>
                                            <div
                                              className="styles_cardWithPadding__JE1QE"
                                              data-testid="home-restaurant-card-wrapper"
                                              style={{
                                                width:
                                                  "calc((100vw - 0px) - 12px)",
                                              }}
                                            >
                                              <div
                                                data-testid="resturant-card-anchor-container"
                                                className="styles_container__fLC0R"
                                                aria-label="Restaurant name: Bakingo, 
  Cuisines: Bakery, Desserts, Beverages, Snacks,
  Area: Rohini,
  3.0 km away,
  Rating: 4.4,
  Delivers in: 25 mins,
  Cost is: RUPEES 300 for two,
  
  
  Double tap to open restaurant menu."
                                                tabIndex={0}
                                                role="button"
                                              >
                                                <div
                                                  className="styles_imgContainer__1uHo5"
                                                  aria-hidden="true"
                                                >
                                                  <div
                                                    className="styles_ImageContainer__2rk9a"
                                                    data-testid="resturant-card-image-container"
                                                    style={{
                                                      background:
                                                        "rgb(255, 255, 255)",
                                                      "box-shadow":
                                                        " 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%)",
                                                    }}
                                                  >
                                                    <img
                                                      className="styles_Image__1fplJ"
                                                      loading="lazy"
                                                      style={{
                                                        width: "80%",
                                                        height: "80%",
                                                        "margin-top": "7%",
                                                        "margin-left": "11%"
                                                      }}
                                                      data-testid="resturant-card-image"
                                                      src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.image_path}`}
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  aria-hidden="true"
                                                  className="styles_containerRestaurant__3vhx3"
                                                >
                                                  <div className="styles_containerImageBadge__14fk3">
                                                    <div
                                                      data-testid="resturant-card-name"
                                                      className="styles_restaurantName__29jAP"
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      <TruncatedHeading text={el.name} limit={30} />

                                                      <p
                                                        style={{
                                                          color:
                                                            "#94A3B8",
                                                          fontSize:
                                                            "12px",
                                                        }}
                                                      >
                                                        Category
                                                      </p>
                                                      <p></p>
                                                    </div>
                                                  </div>


                                                  <div className="styles_restaurantCuisines__3lBL4">
                                                    <span
                                                      data-testid="restaurant-card-cuisines"
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                    >

                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div />
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                {props.ItemDetails[0] &&
                                  props.ItemDetails.map(
                                    (el, index) => (

                                      <div
                                        className=" "
                                        data-testid="grid-row"
                                        style={{
                                          marginBottom: 5,
                                          marginTop: "4%",
                                          "border-radius": "10px",
                                          "margin-right": "12px",
                                          "margin-left": "12px",
                                        }}

                                        onClick={(e) => {
                                          props.vmWiseInvertory(
                                            e,
                                            el.id,
                                            "mvId",
                                            el.name
                                          );
                                        }}
                                      >
                                        <div
                                          className="styles_slide__2c207"
                                          style={{ marginRight: 0 }}
                                        >
                                          <div>
                                            <div
                                              className="styles_cardWithPadding__JE1QE"
                                              data-testid="home-restaurant-card-wrapper"
                                              style={{
                                                width:
                                                  "calc((100vw - 0px) - 12px)",
                                              }}
                                            >
                                              <div
                                                data-testid="resturant-card-anchor-container"
                                                className="styles_container__fLC0R"
                                                aria-label="Restaurant name: Bakingo, 
  Cuisines: Bakery, Desserts, Beverages, Snacks,
  Area: Rohini,
  3.0 km away,
  Rating: 4.4,
  Delivers in: 25 mins,
  Cost is: RUPEES 300 for two,
  
  
  Double tap to open restaurant menu."
                                                tabIndex={0}
                                                role="button"
                                              >
                                                <div
                                                  className="styles_imgContainer__1uHo5"
                                                  aria-hidden="true"
                                                >
                                                  <div
                                                    className="styles_ImageContainer__2rk9a"
                                                    data-testid="resturant-card-image-container"
                                                    style={{
                                                      background:
                                                        "rgb(255, 255, 255)",
                                                      "box-shadow":
                                                        " 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%)",
                                                    }}
                                                  >
                                                    <img
                                                      className="styles_Image__1fplJ"
                                                      loading="lazy"
                                                      style={{
                                                        width: "80%",
                                                        height: "80%",
                                                        "margin-top": "7%",
                                                        "margin-left": "11%"
                                                      }}
                                                      data-testid="resturant-card-image"
                                                      src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.image_path}`}
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  aria-hidden="true"
                                                  className="styles_containerRestaurant__3vhx3"
                                                >
                                                  <div className="styles_containerImageBadge__14fk3">
                                                    <div
                                                      data-testid="resturant-card-name"
                                                      className="styles_restaurantName__29jAP"
                                                      style={{
                                                        fontSize:
                                                          "14px",
                                                      }}
                                                    >
                                                      <TruncatedHeading text={el.name} limit={30} />
                                                      <p
                                                        style={{
                                                          color:
                                                            "#94A3B8",
                                                          fontSize:
                                                            "12px",
                                                        }}
                                                      >
                                                        Items
                                                      </p>
                                                    </div>
                                                  </div>

                                                  <div className="styles_restaurantCuisines__3lBL4">
                                                    <span
                                                      data-testid="restaurant-card-cuisines"
                                                      style={{
                                                        fontSize:
                                                          "12px",
                                                      }}
                                                    >

                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div />
                                          </div>
                                        </div>
                                      </div>

                                    )
                                  )}
                                {props.MachineDetails[0] &&
                                  props.MachineDetails.map(
                                    (el, index) => (
                                      <div
                                        onClick={(e) => { GotoMachineFun(e, el.id) }}
                                        style={{ textDecoration: "none" }}
                                      >
                                        <div
                                          className=" "
                                          data-testid="grid-row"
                                          style={{
                                            marginBottom: 10,
                                            marginTop: "4%",
                                            "border-radius": "10px",
                                            "margin-right": "12px",
                                            "margin-left": "12px",
                                          }}
                                        >
                                          <div
                                            className="styles_slide__2c207"
                                            style={{ marginRight: 0 }}
                                          >
                                            <div>
                                              <div
                                                className="styles_cardWithPadding__JE1QE"
                                                data-testid="home-restaurant-card-wrapper"
                                                style={{
                                                  width:
                                                    "calc((100vw - 0px) - 12px)",
                                                }}
                                              >
                                                <a
                                                  data-testid="resturant-card-anchor-container"
                                                  className="styles_container__fLC0R"
                                                  aria-label="Restaurant name: Bakingo, 
  Cuisines: Bakery, Desserts, Beverages, Snacks,
  Area: Rohini,
  3.0 km away,
  Rating: 4.4,
  Delivers in: 25 mins,
  Cost is: RUPEES 300 for two,
  
  
  Double tap to open restaurant menu."
                                                  tabIndex={0}
                                                  role="button"
                                                >
                                                  <div
                                                    className="styles_imgContainer__1uHo5"
                                                    aria-hidden="true"
                                                  >
                                                    <div
                                                      className="styles_ImageContainer__2rk9a"
                                                      data-testid="resturant-card-image-container"
                                                      style={{
                                                        background:
                                                          "rgb(255, 255, 255)",
                                                        "box-shadow":
                                                          " 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%)",
                                                      }}
                                                    >
                                                      <img
                                                        className="styles_Image__1fplJ"
                                                        loading="lazy"
                                                        style={{
                                                          width: "80%",
                                                          height: "80%",
                                                          "margin-top": "7%",
                                                          "margin-left": "11%"
                                                        }}
                                                        data-testid="resturant-card-image"
                                                        src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.image_path}`}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div
                                                    aria-hidden="true"
                                                    className="styles_containerRestaurant__3vhx3"
                                                  >
                                                    <div className="styles_containerImageBadge__14fk3">
                                                      <div
                                                        data-testid="resturant-card-name"
                                                        className="styles_restaurantName__29jAP"
                                                        style={{
                                                          fontSize:
                                                            "14px",
                                                        }}
                                                      >
                                                        <TruncatedHeading text={el.name} limit={30} />
                                                        <p
                                                          style={{
                                                            color:
                                                              "#94A3B8",
                                                            fontSize:
                                                              "12px",
                                                          }}
                                                        >
                                                          Machine
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div className="styles_restaurantCuisines__3lBL4">
                                                      <span
                                                        data-testid="restaurant-card-cuisines"
                                                        style={{
                                                          fontSize:
                                                            "12px",
                                                        }}
                                                      >

                                                      </span>
                                                    </div>
                                                  </div>
                                                </a>
                                              </div>
                                              <div />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            ) : (
                              <>
                                <div>
                                  <img
                                    style={{
                                      width: "100%",
                                      padding: "40%",
                                      "margin-top": "0%",
                                    }}
                                    src={notFound}
                                  />

                                  <div
                                    style={{ "margin-top": "-8%" }}
                                  ></div>
                                  <p
                                    style={{
                                      "margin-top": "-38%",
                                      fontSize: "14px",
                                      padding: "22%",
                                      display: "flex",
                                      "text-align": "center",
                                      "justify-content": "space-around",
                                      "align-items": " center",
                                      color: "#999999",
                                    }}
                                  >
                                    No items/Vending machine found
                                  </p>
                                </div>{" "}
                              </>
                            )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <div>
                            <img
                              style={{
                                width: "100%",
                                padding: "25%",
                                "margin-top": "-7%",
                              }}
                              src={searchProduct}
                            />

                            <div style={{ "margin-top": "-8%" }}></div>
                            <p
                              style={{
                                "margin-top": "-38%",
                                "font-size": "14px",
                                padding: "12%",
                                display: "flex",
                                "text-align": "center",
                                "align-items": "center",
                                "justify-content": "space-aroun",
                                color: "#94A3B8",
                              }}
                            >
                              Search food & vending machines around you
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  </div>
                </div>
              </>

            )}

            {props.vmwiseInventoryFlag && (
              <div>
                {props.vmwiseInventoryFlag && (
                  <TopNavBar3 title={"Search"} route={"/"} props={props} />
                )}

                <div
                  className="zGVn2e"
                  onClick={(e) => props.globalSearchflag(e)}
                >
                  <button
                    className="Cdl0yb"
                    id="tsuid_1"
                    style={{ display: "none" }}
                  >
                    <div className="Z7Bonf">
                      <span className="z1asCe IW3j9">
                        <svg
                          focusable="false"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
                        </svg>
                      </span>
                    </div>
                  </button>
                  <button
                    className="Tg7LZd search_button_suggest"
                    aria-label="Google Search"
                    type="button"
                  >
                    <div className="gBCQ5d">
                      <span className="z1asCe MZy1Rb">
                        <svg
                          focusable="false"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                        </svg>
                      </span>
                    </div>
                  </button>
                  <button
                    className="Tg7LZd u6FVyd"
                    aria-label="Google Search"
                    type="button"
                  />
                  <div className="SDkEP">
                    <div
                      className="a4bIc"
                      aria-expanded="false"
                      aria-haspopup="listbox"
                      role="combobox"
                    >
                      <input
                        placeholder="Find items or machine"
                        className="gLFyf"
                        type="text"
                        maxLength={2048}
                        name="q"
                        aria-autocomplete="list"
                        aria-describedby="sb_input_box_aria_description_FD34Y_KZG-uWseMPwK6WmAQ_1"
                        tabIndex={0}
                        title="Search"
                        ref={inputRef}
                        value={props.globalSearchProName}
                        onChange={(e) => {
                          props.globalSearch(e.target.value);
                        }}
                      />
                      <div
                        hidden
                        id="sb_input_box_aria_description_FD34Y_KZG-uWseMPwK6WmAQ_1"
                      >
                        <span>Double-tap to search Google.</span>
                      </div>
                    </div>
                  </div>
                </div>





                <div style={{ marginTop: "5%", marginBottom: "15%" }}>
                  <div
                    className=" "
                    data-testid="grid-row"
                    style={{
                      margin: "4% 12px 5px",
                      backgroundColor: "white",
                      borderRadius: 10,
                    }}
                  >

                    <div className="styles_slide__2c207" style={{ marginRight: 0 }}>
                      <div>
                        <div
                          className="styles_cardWithPadding__JE1QE"
                          data-testid="home-restaurant-card-wrapper"
                          style={{ width: "calc((100vw - 0px) - 12px)", "margin-top": "5%" }}
                        >
                          <div className="styles_container__fLC0R">
                            <div
                              className="styles_imgContainer__1uHo5"
                              aria-hidden="true"
                            />
                            <div className="separator">
                              <div style={{ color: "#94A3B8" }} className="line"></div>
                              {console.log("props.globalSearchProName", props.globalSearchProName)}
                              {props.globalSearchProName && <p style={{ color: "#94A3B8", "font-size": "12px", marginTop: "16px" }}>All vending machines delivering {<TruncatedHeading4 text={props.globalSearchProName} limit={10} />}</p>
                              }
                              <div style={{ color: "#94A3B8" }} className="line"></div>
                            </div>
                          </div>
                        </div>
                        <div />
                      </div>
                    </div>
                  </div>

                </div>

                {props&&props.flagMp&&props && props.VmWiseInventroyList && props.VmWiseInventroyList[0] && mpClick({ EventName: "Vm_item_page_opened" })}
                
                {props && props.VmWiseInventroyList && props.VmWiseInventroyList.map((el, index) => {
                  console.log(
                    "globalvmListglobalvmList",
                    el.slotDetails[0].slotId,
                    el.vmId
                  );



                  return (
                    <div

                    >
                      <div
                        style={{
                          display: "flex",
                          "margin-top": "-30%",
                          "margin-bottom": "15%",
                        }}
                      >
                        {props.PreCartListGlobal && props.PreCartListGlobal[0] ? (
                          <>
                            {props.PreCartListGlobal.map((c, index) => {
                              console.log("ccccccccccc", c);
                              return (
                                <>
                                  <MixPnl page={"Vm_item_page_opened"} />

                                  {index == 0 && (
                                    <div style={{ height: "100%" }}>
                                      <div className="_2yoTv ns7Wo">
                                        <div>
                                          <div
                                            style={{
                                              height: "100%",
                                              fontFamily: "Roboto",
                                            }}
                                          >
                                            <div className="_2yoTv ns7Wo">
                                              <div>
                                                <div>
                                                  <div className="_2NVhy _3hjyL">
                                                    <div>
                                                      <div style={{ padding: 0 }}>
                                                        <div>
                                                          <div />
                                                        </div>
                                                      </div>
                                                      <div style={{ padding: 0 }}>
                                                        <div>
                                                          <div />
                                                          <div
                                                            data-testid="grid-header"
                                                            className="styles_headerContainer__2UgeD"
                                                          />
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          padding: "8px 0px 0px",
                                                        }}
                                                      >
                                                        <div>
                                                          <div />
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div>
                                                          <div />
                                                        </div>
                                                      </div>

                                                      <div style={{ padding: 0 }}>
                                                        <div>
                                                          <div />
                                                          <div
                                                            data-testid="grid-header"
                                                            className="styles_headerContainer__2UgeD"
                                                          >
                                                            <div>
                                                              <span
                                                                className="styles_headerContainerSubtitle__1WRg5"
                                                                aria-hidden="true"
                                                              >
                                                                <h3
                                                                  style={{
                                                                    marginTop: "15%",
                                                                    marginLeft: "5%",
                                                                  }}
                                                                ></h3>
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div
                                                            data-testid="grid-container"
                                                            aria-hidden="false"
                                                            style={{
                                                              paddingRight: 0,
                                                            }}
                                                          >
                                                            <div
                                                              className="styles_slider__podEu"
                                                              data-testid="grid-slider"
                                                            >
                                                              <div
                                                                className=" shadow-box"
                                                                data-testid="grid-row"
                                                                style={{
                                                                  marginBottom:
                                                                    "-5px",
                                                                  backgroundColor:
                                                                    "white",
                                                                  marginTop: "4%",
                                                                  "border-radius":
                                                                    "10px",
                                                                  "margin-right":
                                                                    "12px",
                                                                  "margin-left":
                                                                    "12px",

                                                                  marginBottom: 5,
                                                                  "background-color":
                                                                    "#fffff",
                                                                }}
                                                              >
                                                                <div
                                                                  className=""
                                                                  style={{
                                                                    marginRight: 0,
                                                                  }}
                                                                >
                                                                  <div>
                                                                    <div
                                                                      className="styles_cardWithPadding__JE1QE"
                                                                      data-testid="home-restaurant-card-wrapper"
                                                                      style={{
                                                                        width:
                                                                          "100%"
                                                                      }}
                                                                    >
                                                                      <div className="styles_container__fLC0R">
                                                                        <div
                                                                          className="styles_imgContainer__1uHo5"
                                                                          aria-hidden="true"
                                                                        ></div>
                                                                      </div>
                                                                    </div>
                                                                    <div />
                                                                  </div>
                                                                </div>
                                                              </div>

                                                              <div
                                                                style={{
                                                                  "margin-top":
                                                                    "-44px",
                                                                }}
                                                              ></div>
                                                              <div
                                                                className=" shadow-box"
                                                                data-testid="grid-row"
                                                                style={{
                                                                  marginTop: "4%",
                                                                  backgroundColor:
                                                                    "white",
                                                                  "margin-right":
                                                                    "12px",
                                                                  "margin-left":
                                                                    "12px",
                                                                  "margin-bottom":
                                                                    "22px",
                                                                  height: "341px",
                                                                  "border-top-right-radius":
                                                                    "11px",
                                                                  "border-top-left-radius":
                                                                    "11px",
                                                                }}
                                                              >
                                                                <div
                                                                  className=""
                                                                  style={{
                                                                    marginRight: 0,
                                                                  }}
                                                                >
                                                                  <div>
                                                                    <div
                                                                      className="styles_cardWithPadding__JE1QE"
                                                                      data-testid="home-restaurant-card-wrapper"
                                                                      style={{
                                                                        width:
                                                                          "100%"
                                                                      }}
                                                                    >
                                                                      {/*                                                                 <Link style={{textDecoration:"none",color:"#384F6F"}} to={`https://webapp.daalchini.co.in/items?machineId=${el.vmId }`}>
 */}
                                                                      <div style={{ textDecoration: "none", color: "#384F6F" }} onClick={(e)=>{GotoMachineFun2(e,el.vmId)}}>
                                                                        <div
                                                                          style={{
                                                                            display:
                                                                              "flex",
                                                                            padding:
                                                                              "5%",
                                                                            "align-items": "flex-end", " justify-content": "space-between"
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              display:
                                                                                "flex",
                                                                              fontSize:
                                                                                "12px",
                                                                              "font-size":
                                                                                "12px", "align-items": "flex-end"
                                                                            }}
                                                                          >
                                                                            <img
                                                                              style={{
                                                                                width:
                                                                                  "23px",
                                                                              }}
                                                                              src={
                                                                                Company
                                                                              }
                                                                            />
                                                                            <h1 style={{ color: "#384F6F" }}>
                                                                              {
                                                                                el.vmName
                                                                              }
                                                                            </h1>
                                                                          </div>
                                                                          <img
                                                                            style={{
                                                                              width:
                                                                                "4%",
                                                                              "margin-right":
                                                                                "22px",
                                                                              height:
                                                                                "20px",
                                                                            }}
                                                                            src={
                                                                              blackNextArrow
                                                                            }
                                                                          />
                                                                        </div>
                                                                        <p
                                                                          style={{
                                                                            padding:
                                                                              "5%",
                                                                            "margin-top":
                                                                              "-30px",
                                                                            fontSize:
                                                                              "12px",
                                                                          }}
                                                                        >
                                                                          <TruncatedHeading
                                                                            text={
                                                                              el.address
                                                                            }
                                                                            limit={55}
                                                                          />
                                                                        </p>
                                                                      </div>

                                                                      <div>
                                                                        <div
                                                                          style={{
                                                                            /* display:
                                                                          "flex", */
                                                                            "justify-content":
                                                                              "space-around",
                                                                            marginTop:
                                                                              "6rem",

                                                                          }}
                                                                        >
                                                                          {
                                                                            <div
                                                                              style={{
                                                                                borderRadius:
                                                                                  "8px",
                                                                                height:
                                                                                  "161px",
                                                                                width:
                                                                                  "90%",
                                                                                border:
                                                                                  "1px solid #11405C14",
                                                                                "margin-top":
                                                                                  "-53px",
                                                                                marginLeft:
                                                                                  "5%",
                                                                              }}
                                                                            >
                                                                              <div
                                                                                style={{
                                                                                  display:
                                                                                    "flex",
                                                                                  "justify-content":
                                                                                    "space-around",
                                                                                  "align-items":
                                                                                    "center",
                                                                                  "margin-top":
                                                                                    "6%",
                                                                                }}
                                                                              >
                                                                                <div
                                                                                  style={{
                                                                                    display:
                                                                                      "flex",
                                                                                  }}
                                                                                >
                                                                                  <img
                                                                                    style={{
                                                                                      width:
                                                                                        "75px",
                                                                                      top: "101px",
                                                                                      "margin-left":
                                                                                        "-6%",
                                                                                      position:
                                                                                        "absolute",
                                                                                    }}
                                                                                    src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.slotDetails[0].imageUrl}`}
                                                                                  //src="https://i.pinimg.com/564x/14/fb/f5/14fbf589a2f366f1c3c38a217bf04876.jpg"
                                                                                  />{" "}
                                                                                </div>
                                                                                <div
                                                                                  style={{

                                                                                  }}
                                                                                >

                                                                                  {" "}
                                                                                  <TruncatedHeading3 text={el.slotDetails[0].mvName} limit={15} />

                                                                                  <h5
                                                                                    style={{
                                                                                      width:
                                                                                        "100%",

                                                                                      color:
                                                                                        "#718198",
                                                                                    }}
                                                                                  >
                                                                                    Rs
                                                                                    {el.slotDetails[0].mvMrp
                                                                                    }
                                                                                  </h5>
                                                                                </div>
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  padding:
                                                                                    "5%",
                                                                                  marginTop:
                                                                                    "5%",
                                                                                }}
                                                                              >
                                                                                <>
                                                                                  {console.log(
                                                                                    "props.cartMachineId",
                                                                                    el
                                                                                      .slotDetails[0]
                                                                                      .slotId,
                                                                                    c.slotId,
                                                                                    props.cartMachineId,
                                                                                    el.vmId
                                                                                  )}
                                                                                  {c.slotId ==
                                                                                    el
                                                                                      .slotDetails[0]
                                                                                      .slotId &&
                                                                                    props.cartMachineId ==
                                                                                    el.vmId ? (
                                                                                    <>
                                                                                      <div
                                                                                        style={{
                                                                                          border:
                                                                                            "2px solid rgb(0, 177, 119)",
                                                                                          display:
                                                                                            "flex",
                                                                                          width:
                                                                                            "100%",
                                                                                          backgroundColor:
                                                                                            "rgb(0, 177, 119)",
                                                                                          color:
                                                                                            "white",
                                                                                          justifyContent:
                                                                                            "space-around",
                                                                                          alignItems:
                                                                                            "center",
                                                                                          padding:
                                                                                            "5px",
                                                                                          borderRadius:
                                                                                            "7px",
                                                                                          "margin-right":
                                                                                            "18px",
                                                                                        }}
                                                                                      >
                                                                                        <h3
                                                                                          onClick={(
                                                                                            e
                                                                                          ) => {
                                                                                            props.removefromCart(
                                                                                              e,
                                                                                              el
                                                                                                .slotDetails[0]
                                                                                                .slotId,
                                                                                              el.vmId,
                                                                                              c.count
                                                                                            );
                                                                                          }}
                                                                                        >
                                                                                          -
                                                                                        </h3>
                                                                                        {
                                                                                          c.count
                                                                                        }
                                                                                        <h3
                                                                                          onClick={(
                                                                                            e
                                                                                          ) => {
                                                                                            props.addintoCart(
                                                                                              e,
                                                                                              el
                                                                                                .slotDetails[0]
                                                                                                .slotId,
                                                                                              el.vmId,
                                                                                              c.count
                                                                                            );
                                                                                          }}
                                                                                        >
                                                                                          +
                                                                                        </h3>
                                                                                      </div>
                                                                                    </>
                                                                                  ) : (
                                                                                    <button
                                                                                      style={{
                                                                                        width:
                                                                                          "100%",
                                                                                        border:
                                                                                          "1px solid #00B177",
                                                                                        padding:
                                                                                          "8px",
                                                                                        borderRadius:
                                                                                          "6px",
                                                                                        background:
                                                                                          "linear-gradient(0deg, rgba(236, 255, 249, 0.49), rgba(236, 255, 249, 0.49))",
                                                                                        "text-align":
                                                                                          "center",
                                                                                        color:
                                                                                          "#00B177",
                                                                                      }}
                                                                                      onClick={(
                                                                                        e
                                                                                      ) => {
                                                                                        props.addintoCart(
                                                                                          e,
                                                                                          el
                                                                                            .slotDetails[0]
                                                                                            .slotId,
                                                                                          el.vmId,
                                                                                          0
                                                                                        );
                                                                                      }}
                                                                                    >
                                                                                      Add
                                                                                    </button>
                                                                                  )}
                                                                                </>
                                                                              </div>
                                                                            </div>
                                                                          }
                                                                        </div>
                                                                      </div>
                                                                      {el &&
                                                                        el.slotDetails &&
                                                                        el.slotDetails
                                                                          .length -
                                                                        1 >
                                                                        1 && (
                                                                          <a
                                                                            href={`/items?machineId=${el.vmId}`}
                                                                            style={{
                                                                              "text-decoration":
                                                                                "none",
                                                                            }}
                                                                          >
                                                                            <div
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                "align-items":
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p
                                                                                style={{
                                                                                  padding:
                                                                                    "6%",
                                                                                  color:
                                                                                    "#384F6F",
                                                                                }}
                                                                              >& {" "}
                                                                                {el &&
                                                                                  el.slotDetails &&
                                                                                  el
                                                                                    .slotDetails
                                                                                    .length -
                                                                                  1}
                                                                                {"  "}
                                                                                more
                                                                              </p>

                                                                            </div>
                                                                          </a>
                                                                        )}
                                                                      <a
                                                                        data-testid="resturant-card-anchor-container"
                                                                        className="styles_container__fLC0R"
                                                                        aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                                                                        tabIndex={0}
                                                                        role="button"
                                                                      >
                                                                        <div
                                                                          className="styles_imgContainer__1uHo5"
                                                                          aria-hidden="true"
                                                                        ></div>
                                                                      </a>
                                                                    </div>
                                                                    <div />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                           
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {
                              <div style={{ height: "100%" }}>
                                <div className="_2yoTv ns7Wo">
                                  <div>
                                    <div
                                      style={{
                                        height: "100%",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      <div className="_2yoTv ns7Wo">
                                        <div>
                                          <div>
                                            <div className="_2NVhy _3hjyL">
                                              <div>
                                                <div style={{ padding: 0 }}>
                                                  <div>
                                                    <div />
                                                  </div>
                                                </div>
                                                <div style={{ padding: 0 }}>
                                                  <div>
                                                    <div />
                                                    <div
                                                      data-testid="grid-header"
                                                      className="styles_headerContainer__2UgeD"
                                                    />
                                                  </div>
                                                </div>
                                                <div
                                                  style={{ padding: "8px 0px 0px" }}
                                                >
                                                  <div>
                                                    <div />
                                                  </div>
                                                </div>
                                                <div>
                                                  <div>
                                                    <div />
                                                  </div>
                                                </div>

                                                <div style={{ padding: 0 }}>
                                                  <div>
                                                    <div />
                                                    <div
                                                      data-testid="grid-header"
                                                      className="styles_headerContainer__2UgeD"
                                                    >
                                                      <div>
                                                        <span
                                                          className="styles_headerContainerSubtitle__1WRg5"
                                                          aria-hidden="true"
                                                        >
                                                          <h3
                                                            style={{
                                                              marginTop: "15%",
                                                              marginLeft: "5%",
                                                            }}
                                                          ></h3>
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div
                                                      data-testid="grid-container"
                                                      aria-hidden="false"
                                                      style={{
                                                        paddingRight: 0,
                                                      }}
                                                    >
                                                      <div
                                                        className="styles_slider__podEu"
                                                        data-testid="grid-slider"
                                                      >
                                                        <div
                                                          className="styles_row__1ivP1 shadow-box"
                                                          data-testid="grid-row"
                                                          style={{
                                                            marginBottom: "-5px",
                                                            backgroundColor: "white",
                                                            marginTop: "4%",
                                                            "border-radius": "10px",
                                                            "margin-right": "12px",
                                                            "margin-left": "12px",

                                                            marginBottom: 5,
                                                            "background-color":
                                                              "#fffff",
                                                          }}
                                                        >
                                                          <div
                                                            className="styles_slide__2c207"
                                                            style={{
                                                              marginRight: 0,
                                                            }}
                                                          >
                                                            <div>
                                                              <div
                                                                className="styles_cardWithPadding__JE1QE"
                                                                data-testid="home-restaurant-card-wrapper"
                                                                style={{
                                                                  width:
                                                                    "100%"
                                                                }}
                                                              >
                                                                <div className="styles_container__fLC0R">
                                                                  <div
                                                                    className="styles_imgContainer__1uHo5"
                                                                    aria-hidden="true"
                                                                  ></div>
                                                                </div>
                                                              </div>
                                                              <div />
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div
                                                          style={{
                                                            "margin-top": "-44px",
                                                          }}
                                                        ></div>
                                                        <div
                                                          className=" shadow-box"
                                                          data-testid="grid-row"
                                                          style={{
                                                            marginTop: "4%",
                                                            backgroundColor: "white",
                                                            "margin-right": "12px",
                                                            "margin-left": "12px",
                                                            "margin-bottom": "22px",
                                                            height: "341px",
                                                            "border-top-right-radius":
                                                              "11px",
                                                            "border-top-left-radius":
                                                              "11px",
                                                          }}
                                                        >
                                                          <div
                                                            className=""
                                                            style={{
                                                              marginRight: 0,
                                                            }}
                                                          >
                                                            <div>

                                                              <div
                                                                className="styles_cardWithPadding__JE1QE"
                                                                data-testid="home-restaurant-card-wrapper"
                                                                style={{
                                                                  width:
                                                                    "100%"
                                                                }}
                                                              >
                                                                {/*                                                         <Link style={{textDecoration:"none",color:"#384F6F"}}to={`https://webapp.daalchini.co.in/items?machineId=${el.vmId }`}>
 */}
                                                                <div style={{ textDecoration: "none", color: "#384F6F" }} onClick={(e)=>{GotoMachineFun2(e,el.vmId)}}>
                                                                  <div
                                                                    style={{
                                                                      display: "flex",
                                                                      padding: "5%",
                                                                      "align-items": "flex-end", "justify-content": "space-between"
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        display: "flex",
                                                                        fontSize:
                                                                          "12px",
                                                                        "font-size":
                                                                          "12px",
                                                                        "align-items": "flex-end"
                                                                      }}
                                                                    >
                                                                      <img
                                                                        style={{
                                                                          width: "23px",
                                                                        }}
                                                                        src={Company}
                                                                      />
                                                                      <h1>
                                                                        {el.vmName}
                                                                      </h1>
                                                                    </div>
                                                                    <img
                                                                      style={{
                                                                        width: "4%",
                                                                        "margin-right":
                                                                          "22px",
                                                                        height: "20px",
                                                                      }}
                                                                      src={
                                                                        blackNextArrow
                                                                      }
                                                                    />
                                                                  </div>

                                                                  <p
                                                                    style={{
                                                                      padding: "5%",
                                                                      "margin-top":
                                                                        "-30px",
                                                                      fontSize: "12px",
                                                                    }}
                                                                  >
                                                                    <TruncatedHeading
                                                                      text={el.address}
                                                                      limit={55}
                                                                    />
                                                                  </p>

                                                                </div>

                                                                <div>
                                                                  <div
                                                                    style={{
                                                                      /* display:
                                                                          "flex", */
                                                                      "justify-content":
                                                                        "space-around",
                                                                      marginTop:
                                                                        "6rem",

                                                                    }}
                                                                  >
                                                                    {
                                                                      <div
                                                                        style={{
                                                                          borderRadius:
                                                                            "8px",
                                                                          height:
                                                                            "161px",
                                                                          width:
                                                                            "90%",
                                                                          border:
                                                                            "1px solid #11405C14",
                                                                          "margin-top":
                                                                            "-53px",
                                                                          marginLeft:
                                                                            "5%",
                                                                        }}
                                                                      >
                                                                        <div
                                                                          style={{
                                                                            display:
                                                                              "flex",
                                                                            "justify-content":
                                                                              "space-around",
                                                                            "align-items":
                                                                              "center",
                                                                            "margin-top":
                                                                              "6%",
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              display:
                                                                                "flex",
                                                                            }}
                                                                          >
                                                                            <img
                                                                              style={{
                                                                                width:
                                                                                  "75px",
                                                                                top: "101px",
                                                                                "margin-left":
                                                                                  "-6%",
                                                                                position:
                                                                                  "absolute",
                                                                              }}
                                                                              src={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${el.slotDetails[0].imageUrl}`}
                                                                            //src="https://i.pinimg.com/564x/14/fb/f5/14fbf589a2f366f1c3c38a217bf04876.jpg"
                                                                            />{" "}
                                                                          </div>
                                                                          <div
                                                                            style={{

                                                                            }}
                                                                          >

                                                                            {" "}
                                                                            <TruncatedHeading3 text={el.slotDetails[0].mvName} limit={15} />

                                                                            <h5
                                                                              style={{
                                                                                width:
                                                                                  "100%",

                                                                                color:
                                                                                  "#718198",
                                                                              }}
                                                                            >
                                                                              Rs {el.slotDetails[0].mvMrp}
                                                                            </h5>
                                                                          </div>
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            padding:
                                                                              "5%",
                                                                            marginTop:
                                                                              "5%",
                                                                          }}
                                                                        >
                                                                          <>
                                                                            {true ? (
                                                                              <button
                                                                                style={{
                                                                                  width:
                                                                                    "100%",
                                                                                  border:
                                                                                    "1px solid #00B177",
                                                                                  padding:
                                                                                    "8px",
                                                                                  borderRadius:
                                                                                    "6px",
                                                                                  background:
                                                                                    "linear-gradient(0deg, rgba(236, 255, 249, 0.49), rgba(236, 255, 249, 0.49))",
                                                                                  "text-align":
                                                                                    "center",
                                                                                  color:
                                                                                    "#00B177",
                                                                                }}
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  props.addintoCart(
                                                                                    e,
                                                                                    el
                                                                                      .slotDetails[0]
                                                                                      .slotId,
                                                                                    el.vmId,
                                                                                    0,
                                                                                    el.slotDetails[0].name,
                                                                                    
                                                                                    el.slotDetails[0].mvMrp
                                                                                  );
                                                                                }}
                                                                              >
                                                                                Add
                                                                              </button>
                                                                            ) : (
                                                                              <>
                                                                                TAHZEEB
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        </div>
                                                                      </div>
                                                                    }
                                                                  </div>
                                                                </div>
                                                                {el &&
                                                                  el.slotDetails &&
                                                                  el.slotDetails
                                                                    .length -
                                                                  1 >
                                                                  1 && (
                                                                    <a
                                                                      href={`/items?machineId=${el.vmId}`}
                                                                      style={{
                                                                        "text-decoration":
                                                                          "none",
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          display:
                                                                            "flex",
                                                                          "align-items":
                                                                            "center",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        <p
                                                                          style={{
                                                                            padding:
                                                                              "6%",
                                                                            color:
                                                                              "#384F6F",
                                                                          }}
                                                                        >&{" "}
                                                                          {el &&
                                                                            el.slotDetails &&
                                                                            el
                                                                              .slotDetails
                                                                              .length -
                                                                            1}
                                                                          {"  "}more
                                                                        </p>

                                                                      </div>
                                                                    </a>
                                                                  )}

                                                                <a
                                                                  data-testid="resturant-card-anchor-container"
                                                                  className="styles_container__fLC0R"
                                                                  aria-label="Restaurant name: Bakingo, 
Cuisines: Bakery, Desserts, Beverages, Snacks,
Area: Rohini,
3.0 km away,
Rating: 4.4,
Delivers in: 25 mins,
Cost is: RUPEES 300 for two,


Double tap to open restaurant menu."
                                                                  tabIndex={0}
                                                                  role="button"
                                                                >
                                                                  <div
                                                                    className="styles_imgContainer__1uHo5"
                                                                    aria-hidden="true"
                                                                  ></div>
                                                                </a>
                                                              </div>
                                                              <div />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}



          </div>









        </>
      )}

      < MixPnl page="Global_search_page_opened" />
    </>
  );
}

export default GlobalSearch;

