import React, { useEffect } from "react";
import WaitingLoder from "../WaitingLoader/component";
import "../Login/login.css";
import { useNavigate } from "react-router-dom";
import keypad from '../../assets/keypad.png'
import otpkeyicon from '../../assets/otpkeyicon.png'
import { GoogleLogin } from "react-google-login";


import loginBackground from '../../assets/loginBackground.png'
import MixPnl from "../../Mixpanel";
function LoginPage(props) {
  const navigate = useNavigate();
  const UserJWTAuth = localStorage.getItem('UserJWTAuth');
useEffect(()=>{
  if(UserJWTAuth){
    if(localStorage.getItem('Guest_clientId')){
    console.log('UserJWTAuthnavigate')
    //navigate('/',{ replace: true });
    }
    else{
      navigate('/',{ replace: true });
    }
  }
  
},[])





const GuestBack=()=>{
  navigate('/',{ replace: true });


}

useEffect(()=>{

  console.log("Paste otp",props.otp)


},[props.otp])

const Loading=(e)=>{
  //props&&props.setLoader(true)
}
return (
    <>
    {/* {props&&props.loader&&<WaitingLoder/>} */}
        <div
    style={{
      background:
        "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
        //"paddingBottom":Boolean((props.ActiveOrderItems[0])&&(props.PreCardItems[0]))?"12.5rem":Boolean(props.ActiveOrderItems[0])?"9.5rem":props.PreCardItems[0]&&"8.5rem",
    }}
   className={" overflow-y-auto h-screen"}


  >
    {/* {props.loader&&<WaitingLoder/>} */}
     {props.login &&
      <>

{/* <MixPnl page={"auth_opened"} pro={"auth_opened Properties"}/>
 */}    
          <img style={{}} src={loginBackground}/>
        

          <div style={{padding:"33px"}}>
       
          
          
   <h3 style={{    margin: "15px","text-align": "center",color: "#384F6F","text-align":"center",
"font-family":"Roboto","font-size":"20px","font-style":"normal",
"font-weight": "700",
"line-height": "25px"}}>India's First 24*7 Instant Automated Store</h3>
   <p className="hr" style={{"margin-top":"46px"}} ><span className="hrspan">Login</span></p>
        
          <div className="a-accordion-inner">
            <div className="auth-validate-form fwcim-form auth-clearable-form"
             style={{marginTop:"15%"}}
            >
              
              
              <div className="a-input-text-group a-spacing-medium a-spacing-top-micro">
                <div className="a-row a-spacing-base">
                  <span
                    className="a-declarative"
                   
                  >
                    <div className="a-input-text-wrapper auth-required-field auth-fill-claim moa-single-claim-input-field-container">
                      <div
                        className="a-section country-picker aok-hidden"
                       
                        style={{ display: "none" }}
                      >
                        <span
                          className="a-declarative"
                          
                        >
                          <span className="country-display-text">IN +91</span>
                        </span>
                      </div>
           
                      <div className="inputWithIcon">
  <input className="hello" type="number" placeholder="Your Mobile Number" onChange={(e)=>{props.onchangeMobile(e)}} />
  <i style={{"margin-top":"11px","margin-left": "4%"}}><img style={{width:"40%"}} src={keypad}/></i>
</div>
                    </div>
                  </span>
                </div>
              </div>

              {props.mobile.length==10?<div className="a-section" >
                <div className="a-button-stack" >
                  <span
                    id="continue"
                    className={ "continue a-button-span12"}
                  >
                    
                    <span className="a-button-inner"  style={{backgroundColor:"rgb(0, 177, 119)",color:"white"}}>
                      <button
                       
                        className="a-button-input"
                        onClick={props.LoginApi}
                       
                        style={{backgroundColor:"rgb(0, 177, 119)",color:"white"}}
                      />
                      <span
                      
                        className="a-button-text"
                        style={{fontSize:"16px","backgroundColor":"#00B177",color:"white"}}
                       
                      >
                        Submit
                      </span>


                    </span>
          
                  </span>
                  
      
                  <div className="a-section a-spacing-medium">
                   <a  style={{textDecoration:"none"}} href="https://www.daalchini.co.in/terms.html"> <div
                      id="legalTextRow"
                      className="a-row a-spacing-top-medium a-size-small"
                      style={{"margin-top": "22%","text-align": "center",fontSize:"12px"}}
                    >
                     <span style={{"color":"#718198"}}>By continuing, you agree to our </span><span style={{"color":"#583C43"}}>Terms of Service<span style={{"color":"#718198"}}>and</span>  Privacy Policy.</span>

                    </div>
                    </a>
                  </div>
                </div>
              </div>
              :   
              
              <div className="a-section" >
                <div className="a-button-stack" >
                  <span
                    id="continue"
                    className={"a-button a-button-span12 a-button-primary"}
                  >
                    
                    <span className="a-button-inner"  style={{backgroundColor:"rgb(0, 177, 119)",color:"white"}}>
                      <button
                       
                        className="a-button-input"
                       
                       
                        style={{backgroundColor:"rgb(0, 177, 119)",color:"white"}}
                      />
                      <span
                      
                        className="a-button-text"
                        style={{fontSize:"16px","background-color": "#BCBDBD",color:"white"
                      }}
                       
                      >
                        Submit
                      </span>

                    </span>
                  </span>
                  <h1 style={{textAlign:"center",    marginBottom:"3%"}}>Or</h1>


                  <GoogleLogin
                  onClick={(e)=>{Loading(e)}}
            clientId="79634458363-nhi15g8c5sdoa24bm8pp5c0ooc4fps7i.apps.googleusercontent.com" //TO BE CREATED
            render={renderProps => (<>

      <button
                className="button"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                style={{    textAlign: "center",
                  padding: "5%",
                  border: "2px solid #80808087",
                  borderRadius:"5px",
                  width:"100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems:"center"
              }}
              >
                           <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.64 9.20443C17.64 8.56625 17.5827 7.95262 17.4764 7.36353H9V10.8449H13.8436C13.635 11.9699 13.0009 12.9231 12.0477 13.5613V15.8194H14.9564C16.6582 14.2526 17.64 11.9453 17.64 9.20443Z" fill="#4285F4"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C11.43 18 13.4673 17.1941 14.9564 15.8195L12.0477 13.5613C11.2418 14.1013 10.2109 14.4204 9 14.4204C6.65591 14.4204 4.67182 12.8372 3.96409 10.71H0.957275V13.0418C2.43818 15.9831 5.48182 18 9 18Z" fill="#34A853"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.96409 10.7101C3.78409 10.1701 3.68182 9.59325 3.68182 9.00007C3.68182 8.40689 3.78409 7.83007 3.96409 7.29007V4.95825H0.957273C0.347727 6.17325 0 7.5478 0 9.00007C0 10.4523 0.347727 11.8269 0.957273 13.0419L3.96409 10.7101Z" fill="#FBBC05"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9 3.57955C10.3214 3.57955 11.5077 4.03364 12.4405 4.92545L15.0218 2.34409C13.4632 0.891818 11.4259 0 9 0C5.48182 0 2.43818 2.01682 0.957275 4.95818L3.96409 7.29C4.67182 5.16273 6.65591 3.57955 9 3.57955Z" fill="#EA4335"/>
</svg>
          <span style={{marginLeft:"5%"}}>Log in with Google</span> 
              </button>
              </>
            )}
            onSuccess={props&&props.responseGoogle}
            onFailure={props&&props.responseGoogle}
          />
         
               { localStorage.getItem('Guest_clientId')?"": <h1 style={{    
          position: "fixed",
    "top":"25px",
    "right": "20px",
    "border-radius": "27px",
    background:"rgba(0, 0, 0, 0.62)",


    color: "#FFF",
    "text-align":"center",
    "font-family":"Roboto",
    "font-size":"12px",
    "font-style":"normal",
    "font-weight":"500",
    "line-height":"28px",
    
    "padding": "5px",
   
    width:"20%","text-align":"center",
    display: "flex",width:"66px",
height:"25px","padding":"10px",
"flex-direction":"column",
"justify-content":"center",
"align-items": "center",
gap: "10px","flex-shrink":"0"
    
    
    }} onClick={props.skip}>skip</h1> } 

{ localStorage.getItem('Guest_clientId')&&<h1 style={{    
          position: "fixed",
    "top":"25px",
   
    "border-radius": "27px",
    background:"rgba(0, 0, 0, 0.62)",


    color: "#FFF",
    "text-align":"center",
    "font-family":"Roboto",
    "font-size":"12px",
    "font-style":"normal",
    "font-weight":"500",
    "line-height":"28px",
    
    "padding": "5px",
   
    width:"20%","text-align":"center",
    display: "flex",width:"66px",
height:"25px","padding":"10px",
"flex-direction":"column",
"justify-content":"center",
"align-items": "center",
gap: "10px","flex-shrink":"0"
    
    
    }} onClick={(e)=>{GuestBack()}}>Back</h1> }


                  <div className="a-section a-spacing-medium">
                  <a  style={{textDecoration:"none"}} href="https://www.daalchini.co.in/terms.html"> <div
                      id="legalTextRow"
                      className="a-row a-spacing-top-medium a-size-small"
                      style={{"margin-top": "22%","text-align": "center",fontSize:"12px"}}
                    >
                     <span style={{"color":"#718198"}}>By continuing, you agree to our </span><span style={{"color":"#583C43"}}>Terms of Service  <span style={{"color":"#718198"}}>and</span>  Privacy Policy.</span>

                    </div>
                    </a>
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </>
      
      }
      {props.loginOtp &&
      <>
      
      <MixPnl page={"auth_otp_send"} pro={"otp Properties"}/>
    
   
          <img style={{width:"107%"}} src={loginBackground}/>
      
        
    
          <div style={{padding:"33px"}}>
          <div className="a-accordion-inner">
            <div className="auth-validate-form fwcim-form auth-clearable-form"
             
            >
              <div className="a-input-text-group a-spacing-medium a-spacing-top-micro" >
                <div className="a-row a-spacing-base">
                  <span
                    className="a-declarative"
                   
                  >
                    <div className="a-input-text-wrapper auth-required-field auth-fill-claim ">
                      <div
                        className="a-section country-picker aok-hidden"
                       
                        style={{ display: "none" }}
                      >
                        <span
                          className="a-declarative"
                          
                        >
                       
                        </span>
                      </div>
                      <h3 style={{    margin: "15px","text-align": "center",    color: "#384F6F"}}>India's First 24*7 Instant Automated Store</h3>

                      <div style={{marginTop:"20%"}}>
                        

                      <p className="hr" style={{"margin-bottom":"50px"}}><span className="hrspan">OTP</span></p>
                 
                                            <div className="inputWithIcon">
  <input className="hello" maxLength={4} type="text"  placeholder="ENTER OTP" onChange={(e)=>{props.onchangeOtp(e)}} onInput={(e) => {props.setOtp(e.target.value)}}   onPaste={(e) => {props.setOtp(e.clipboardData.getData("text")) }}/>
  <i style={{"margin-top":"15px","margin-left": "4%"}}><img style={{width:"50%"}} src={otpkeyicon}/></i>
</div>
</div>
                    </div>
                  </span>
                </div>
              </div>
              
              {props.otp.length==4?<div className="a-section">
                <div className="a-button-stack">
                  <span
                    id="continue"
                    className={ "continue a-button-span12"}
                  >
                    <span className="a-button-inner">
                    <button
                        id="continue"
                        className="a-button-input"
                        onClick={props.SubmitOTP}
                        
                      /> 
                      <span
                      style={{color:"white",fontSize:"16px", }}
                        className="a-button-text"
                        
                      >
                      Verify
                      </span>
                    </span>
                  </span>
                  <div className="a-section a-spacing-medium">
                
                  </div>
                </div>
              </div>:<div className="a-section">
                <div className="a-button-stack">
                  <span
                    id="continue"
                    className="a-button a-button-span12 a-button-primary"
                  >
                    <span className="a-button-inner">
                      <input
                        id="continue"
                        className="a-button-input"
                       
                       
                      />
                      <span
                      
                        className="a-button-text"
                        style={{fontSize:"16px","background-color":"rgb(188, 189, 189)",color:"white"}}
                       
                      >
                      Verify
                      </span>
                    </span>
                  </span>
                  <div className="a-section a-spacing-medium">
                 
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>
        </>
      }
      </div>
    </>
  );
}

export default LoginPage;