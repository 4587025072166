import { useState } from "react";

const Google_Popup_Number=({closePop,handleNumber,numberError,googleAddnumber})=>{





    return(
        <>
        <div className="modal">
      
        
        <div style={{padding:"5%",marginTop:"20%"}}>

              <div style={{background:"white",widows:"100%" ,height:"399px",borderRadius:"10px"}}>

              <div style={{display:"flex",justifyContent:"center",position: "relative",
    top:"-70px"}}><svg xmlns="http://www.w3.org/2000/svg" width="124" height="124" viewBox="0 0 124 124" fill="none">
<g clip-path="url(#clip0_83_382)">
<path d="M79.4837 103.027C102.384 93.125 112.921 66.5329 103.019 43.6324C93.1164 20.732 66.5243 10.195 43.6238 20.0974C20.7233 29.9998 10.1863 56.5919 20.0888 79.4924C29.9912 102.393 56.5832 112.93 79.4837 103.027Z" fill="#009846"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M45.9086 38.8813L53.7773 45.6473C53.7773 45.6473 55.6852 47.8822 54.5372 49.9086C48.2219 61.1602 65.447 75.9554 75.6141 68.0047C77.4648 66.569 79.9435 68.1099 79.9435 68.1099L87.8122 74.8758C93.2011 78.9009 80.8114 88.1892 71.4763 84.9996C62.749 82.018 54.1075 74.0091 53.012 72.9999C51.8431 72.0931 42.6223 64.7585 38.3547 56.5766C33.7987 47.8368 41.0905 34.1622 45.8888 38.8899L45.9086 38.8813Z" fill="#FEFEFE"/>
</g>
<defs>
<clipPath id="clip0_83_382">
<rect width="93.6566" height="93.6566" fill="white" transform="translate(0 37.1719) rotate(-23.3842)"/>
</clipPath>
</defs>
</svg></div>

<div style={{position:"relative",top:"-86px",textAlign:"center",padding:"5%"}}>



<h1 style={{fontSize:"20px",fontWeight:"700"}}>Verify Your Number</h1>
<p style={{fontSize:"14px",fontWeight:"400",marginTop:"5%"}}>Please enter your phone number to link it and continue to payment.</p>

<div style={{border:"2px solid #8080805e",borderRadius:"10px",marginTop:"7%"}}>
  <input  style={{padding:"2%",paddingLeft:"0%", fontSize:"20px",marginLeft:"0px",}} type="number"  
  onChange={(e)=>{handleNumber(e)}}
  placeholder="Your Mobile Number" />
</div>
{numberError&&<p style={{color:"rgba(234, 67, 53, 1)",textAlign:"start",marginTop:"3%"}}  > Enter a valid mobile number</p>}

<div style={{display:"flex",    justifyContent:"space-between",    marginTop:"15%"}}>
    <button style={{    padding:" 5%",
    width: "132px",
    border: "2px solid rgba(0, 177, 119, 1)",
    textAlign: "center",
    borderRadius: "10px",color:"rgba(0, 177, 119, 1)"}} onClick={(e)=>closePop(e)}>
    Cancel
    </button>

    <button  onClick={googleAddnumber} style={{    padding:" 5%",
    width: "131px",
   
    textAlign: "center",
    borderRadius: "10px",background:" rgba(0, 177, 119, 1)",fontSize:"15px",fontWeight:"700",color:"white"}}>
    Continue
    </button>
</div>

</div>



              </div>
        
           
            </div>
            </div>
            </>
    )
}

export default Google_Popup_Number;