import React, { useState, useEffect } from "react";
import bgimage from "../../assets/game/background.png";
import rock from "../../assets/game/rock.png";
import characterImage from "../../assets/game/character.png";
import pathFill from "../../assets/game/pathF.png";
import pathEmpty from "../../assets/game/pathE.png";
import flagImage from '../../assets/game/targetFlag.png'
import Gift from  '../../assets/game/gift.png'
import Board from '../../assets/game/offerBoard.png'
import C from '../../assets/game/clock.png'
import coupon from "../../assets/game/coupon 1.png"

import Bronze from "../../assets/game/loyalty rewards/bronzeL.png";
import Sliver from "../../assets/game/loyalty rewards/Sliver.png";
import SliverLocked from '../../assets/game/loyalty rewards/sliverL.png'
import Gold from '../../assets/game/loyalty rewards/Gold.png'
import GoldLocked from '../../assets/game/loyalty rewards/GoldL.png'
import Platinum from  '../../assets/game/loyalty rewards/Platinum.png'
import PlatinumLocked from  '../../assets/game/loyalty rewards/sliverL.png'
import BackArrow from '../../assets/game/backArrow.png'
import NextArrow from '../../assets/game/NextArrow.png'
import Itmes from '../../assets/game/coupon 1.png'
import Cashback from '../../assets/game/cashback.png'
import './index.css'


//import BackArrow from '../../assets/game/backArrow.png'
import NextPage from '../../assets/game/Next Arrow Button.gif'
import BackPage from '../../assets/game/BackArrow.gif'
import KnowMore from '../../assets/game/Know More.png'
import axios from "axios";
import { Link } from "react-router-dom";

const MilestonePagination = () => {

  const [showPopup, setShowPopup] = useState(false);
  const [showPopupData, setShowPopupData] = useState(null);
  const [rewardToShow, setRewardToShow] = useState(null);
  const [gameData,setGameData]=useState(null)

  const currentMilestone = 2; 

/*   const levelRewardss = [
    { milestoneCount: 1, rewardType: "cashback", value: 15.0, isSurprise: false, isRedeemed: false },
    { milestoneCount: 2, rewardType: "cashback", value: 15.0, isSurprise: false, isRedeemed: false },
    { milestoneCount: 4, rewardType: "cashback", value: 20.0, isSurprise: false, isRedeemed: false },
  ];

   useEffect(() => {
      // Check the reward for currentMilestone - 1
      console.log("currentLevelMilestoneCount",currentLevelMilestoneCount)
     const nextMilestone = currentLevelMilestoneCount&&currentLevelMilestoneCount;
     //const nextMilestone = 6
     const reward = levelRewards && levelRewards.find((r) => {
      //console.log("Checking:", r.milestoneCount); // Log each milestoneCount being checked
      return r.milestoneCount === nextMilestone;
  });
  
      // Retrieve the last seen milestone from localStorage
      const lastSeenMilestone = localStorage.getItem("lastSeenMilestone");
   
      console.log("khan Checking----",lastSeenMilestone,reward)
      if ( reward&&reward.milestoneCount === currentLevelMilestoneCount) {
        
        if(lastSeenMilestone || !(lastSeenMilestone)){
        console.log("khan Checking--- Showing popup:", reward, lastSeenMilestone, nextMilestone);
        setShowPopupData(reward)
        setRewardToShow(reward);
        setShowPopup(true);
        
      }
      }
    }, [currentMilestone, levelRewardss]);
  
    const handlePopupClose = (e) => {
      
      console.log("close")
      const lastSeenMilestone = localStorage.getItem("lastSeenMilestone");
      
      localStorage.setItem("lastSeenMilestone",lastSeenMilestone);
     
      setShowPopup(false);
 
    }; */



  /*   const levelRewards = [
      { milestoneCount: 1, rewardType: "cashback", value: 15.0, isSurprise: false, isRedeemed: false },
      { milestoneCount: 2, rewardType: "cashback", value: 15.0, isSurprise: false, isRedeemed: false },
      { milestoneCount: 4, rewardType: "cashback", value: 20.0, isSurprise: false, isRedeemed: false },
    ]; */
    

    

  const Coupon=()=>{


return(<>

{!(showPopupData.rewardType=="cashback")?
<div className="modal">
  <div style={{padding:"5%",marginTop:"30vw"}}>
<div class="itmes">
    <div class="frame-34818" onClick={(e)=>{handleClosePopup(e)}}>
        <div class="toggle">
            <div class="apply">
                <span><span class="apply-span"> O </span><span class="apply-span2"> k </span></span>
            </div>
        </div>
    </div>
    <div class="frame-12870">
        <div class="are-you-sure"> Reward Unlocked </div>
        <div class="_20-cashback-earned-enjoy-your-savings">
            <span><span class="_20-cashback-earned-enjoy-your-savings-span2"> You’ve earned  <br />a <span class="_20-cashback-earned-enjoy-your-savings-span"> ₹{showPopupData.value} </span>% cashback reward </span></span>
        </div>
    </div>
    <div style={{display:"flex",justifyContent:"center"}}><img class="coupon-1" src={Cashback} /></div>
</div>
</div>


</div>:<div className="modal">
  <div style={{padding:"5%",marginTop:"30vw"}}>
<div class="itmes">
    <div class="frame-34818" onClick={(e)=>{handleClosePopup(e)}}>
        <div class="toggle">
            <div class="apply">
                <span><span class="apply-span"> O </span><span class="apply-span2"> k </span></span>
            </div>
        </div>
    </div>
    <div class="frame-12870">
        <div class="are-you-sure"> Reward Unlocked </div>
        <div class="_20-cashback-earned-enjoy-your-savings">
            <span><span class="_20-cashback-earned-enjoy-your-savings-span"> ₹{showPopupData.value} </span><span class="_20-cashback-earned-enjoy-your-savings-span2"> cashback earned! <br />Enjoy your savings. </span></span>
        </div>
    </div>
    <div style={{display:"flex",justifyContent:"center"}}><img class="coupon-1" src={Itmes} /></div>
</div>
</div>


</div>}



</>)


  }

  const[totalMilestones,setTotalMilestones] =useState(5); // Total milestones from API
  const [milestonesPerPage,setMilestonesPerPage]  = useState(5);
  const [currentLevelMilestoneCount,setCurrentLevelMilestoneCount]=useState(2);
 // Current character position from API

  // Calculate total pages
  const [totalPages,setTotalPages]= useState(Math.ceil(totalMilestones / milestonesPerPage))

  // Calculate current page based on the current milestone count
  const [initialPage, setInitialPage]= useState(Math.ceil(currentLevelMilestoneCount / milestonesPerPage));
const [levelId,setLevelId]=useState(1)
  // State to manage the current page
  const [currentPage, setCurrentPage] = useState(initialPage);
const [daysLeft,setDaysLeft]=useState(null)
  // Calculate milestones for the current page
  const startMilestone = (currentPage - 1) * milestonesPerPage + 1;
  const endMilestone = startMilestone + milestonesPerPage - 1;

  // Determine the current milestone's page
  const currentMilestonePage = Math.ceil(currentLevelMilestoneCount / milestonesPerPage);
  const [levelRewards,setLevelRewards]= useState(null)

  const leftPositions = ["14", "28", "48", "67", "83"];
  const leftPositionsChar = ["18vw", "29vw", "56vw", "70vw", "78vw"];
  const TopPositionsChar = ["44vw", "54vw", "49vw", "54vw", "64vw"];

  const fetchData = async () => {
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
    const uuid = localStorage.getItem("uuid");
    const mydata = {
      method: "get",
      url: "https://api-prod.daalchini.co.in/consumerbe/api/v2/user/loyalty/details",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        "x-device-id": uuid,
      },
    };

    try {
      const response = await axios(mydata);
      const data = response.data.data;
      setGameData(data);
      console.log("levelId response.data.data",response.data.data.levelId)
      setCurrentLevelMilestoneCount(data.currentLevelMilestoneCount);
      setTotalMilestones(data.totalMilestones);
      const calculatedTotalPages = Math.ceil(data.totalMilestones / milestonesPerPage);
      setTotalPages(calculatedTotalPages);
      setCurrentPage(Math.ceil(data.currentLevelMilestoneCount / milestonesPerPage));
      setLevelRewards(data.levelRewards)
setDaysLeft(data.daysLeft)
setLevelId(data.levelId)















    } catch (error) {
      console.error("Error during API call:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    console.log("reward----- currentMilestone:", currentLevelMilestoneCount);
  
    if (!currentLevelMilestoneCount) return; // Ensure milestone count exists
  
    // Find if there's a reward for the current milestone
    const reward =levelRewards&& levelRewards.find((r) => r.milestoneCount === currentLevelMilestoneCount);
  
    // Retrieve the last seen milestone from localStorage
  console.log("reward-----",reward)
    const lastSeenMilestone = Number(localStorage.getItem("lastSeenMilestone")) || 0;
  
    console.log("Checking:", { lastSeenMilestone, reward });
  
    // Show popup only if:
    // 1. There's a reward for the current milestone.
    // 2. The popup has NOT been shown for this milestone (not stored in localStorage).
    if (reward && lastSeenMilestone !== currentLevelMilestoneCount) {
      console.log("Showing popup for milestone:", currentLevelMilestoneCount);
  
      setShowPopupData(reward);
      setRewardToShow(reward);
      setShowPopup(true);
  
    
    }
  }, [currentLevelMilestoneCount, levelRewards]); // Ensure dependencies are correct
  
  // Function to handle closing the popup
  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
      // Store the current milestone in localStorage (mark as seen)
      localStorage.setItem("lastSeenMilestone", currentLevelMilestoneCount);
  };

  // Handlers for Next and Previous buttons
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };


  useEffect(()=>{
    fetchData()

  },[currentLevelMilestoneCount,totalMilestones,totalPages])


  

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };




  const getPagePathImage = () => {

  
    // If the current milestone is equal to the total milestones, show filled path
    if (currentLevelMilestoneCount === totalMilestones) {
      return pathFill; // Last milestone filled
    } 
    
    // If the current page is before the milestone page, show filled path
    if (currentPage < currentMilestonePage) {
      return pathFill; // Previous pages
    } 
    
    // If the current page is after the milestone page, show empty path
    if (currentPage > currentMilestonePage) {
      return pathEmpty; // Future pages
    }
  
    // Handle the current milestone page
    if (currentPage === currentMilestonePage) {
      if (currentLevelMilestoneCount % milestonesPerPage === 0) {
        return pathFill; // Show filled path for multiples of milestonesPerPage
      }
      return require(`../../assets/game/path${((currentLevelMilestoneCount - 1) % milestonesPerPage) + 1}.png`);
    }
  
    // Default fallback
    return pathEmpty;
  };
  
  const Medal = () => {
    const [allmodelShow, SetallmodelShow] = useState(false);
  
    const handleShowAllModels = () => {
      SetallmodelShow(!allmodelShow);
    };
  
    return (
      <>
        <div
          style={{
            background: "linear-gradient(180deg, #239FBD 80.53%, rgba(131, 211, 231, 0) 93.66%)",
            height: "10vw",
            position: "absolute",
            top: "18vw",
            display:"flex"
          }}
        >
          <div style={{ display: "flex", padding: "5%", alignItems: "center" }}>
            <div>
              {levelId>=1&&<img src={Bronze} style={{ padding:"5%",width: "10vw" }} />}
              <h1 style={{fontFamily: "Roboto",
fontSize: "12px",
fontWeight: "700"}}>Bronze</h1>
            </div>
            <div>
             { (levelId>=2)?<img src={Sliver} style={{ width: "10vw", padding:"5%",}} />:<img src={SliverLocked} style={{ padding:"5%",width: "10vw", }} />}
              <h1 style={{fontFamily: "Roboto",
fontSize: "12px",
fontWeight: "700",marginLeft:"4px",}}>Sliver</h1>
            </div>
            <div
              style={{
                width: (levelId>=3)?"10vw":"10vw",
                display: allmodelShow ? "block" : "none",
                opacity: allmodelShow ? 1 : 0,
                transition: "opacity 5s ease-in-out",
              }}
            >
              {(levelId>=3)?<img src={Gold} style={{ padding:"5%",width: "100%" }} />:<img src={GoldLocked} style={{ width: "100%",padding:"5%", }} />}
              <h1 style={{fontFamily: "Roboto",
fontSize: "12px",
fontWeight: "700",marginLeft:(levelId>=3)?"2px":"4px",marginTop:(levelId>=3)?"0px":"0px"}}>Gold</h1>
            </div>
            <div
              style={{
                width: "10vw",
                display: allmodelShow ? "block" : "none",
                opacity: allmodelShow ? 1 : 0,
                transition: "opacity 5s ease-in-out",
            
              }}
            >
           {(levelId>=4)?<img src={Platinum} style={{ padding:"5%",width: "100%" }} />:<img src={PlatinumLocked} style={{ padding:"5%",width: "100%" }} />}
              <h1 style={{fontFamily: "Roboto",
fontSize: "12px",
fontWeight: "700"}}>Platinum</h1>
            </div>
            <div
              style={{
                width: "5vw",
                display: allmodelShow ? "flex" : "none",
                opacity: allmodelShow ? 1 : 0,
                transition: "opacity 5s ease-in-out",
              }}
              onClick={handleShowAllModels}
            >
              <img src={BackArrow} style={{ width: "100%" }} />
            </div>
            <div
              style={{
                width: "5vw",
                display: allmodelShow ? "none" : "flex",
                opacity: allmodelShow ? 0 : 1,
                transition: "opacity 5s ease-in-out",
              }}
              onClick={handleShowAllModels}
            >
              <img src={NextArrow} style={{ width: "100%" }} />
            </div>
          </div>
      
        </div>
        <div style={{    position: "absolute",
    right: "0",
    top: "15vw"}}>

<img src={C} style={{width:"100px"}}/>

<h2 style={{color:"white",   
    textAlign: "center",
    position: "absolute",
    top: "-2px",
    right: "8px",
    fontWeight: "bold"}}>
Days <br/>{daysLeft}{" "}Left
</h2>
</div>
      </>
    );
  };
  

  const Rock = () => {
    return (
      <>



     
        <div style={{ position: "absolute", top: "66vw", left: "7vw", zIndex: 2 }}>
          <img style={{ width: "18vw" }} src={rock} alt="Rock" />
        </div>
        <div style={{ position: "absolute", top: "41vw", left: "20vw", zIndex: 2 }}>
          <img style={{ width: "18vw" }} src={rock} alt="Rock" />
        </div>
        <div style={{ position: "absolute", top: "66vw", left: "41vw", zIndex: 2 }}>
          <img style={{ width: "18vw" }} src={rock} alt="Rock" />
        </div>
        <div style={{ position: "absolute", top: "41vw", left: "60vw", zIndex: 2 }}>
          <img style={{ width: "18vw" }} src={rock} alt="Rock" />
        </div>
        <div style={{ position: "absolute", top: "66vw", left: "77vw", zIndex: 2 }}>
          <img style={{ width: "18vw" }} src={rock} alt="Rock" />
        </div>
      </>
    );
  };

  useEffect(() => {


  }, [currentPage]);


    const rewardPositionData = [
      { imageTop: 58, imageLeft: 7, textTop: 53, textLeft: 9 },
      { imageTop: 33, imageLeft: 24, textTop: 33, textLeft: 25 },
      { imageTop: 58, imageLeft: 45, textTop: 53, textLeft: 46 },
      { imageTop: 34, imageLeft: 54, textTop: 29, textLeft: 59 },
      { imageTop: 58, imageLeft: 80, textTop: 54, textLeft: 80 },
    ];
    const rewardPositionDatatxt = [
      { textTop: 58, textLeft: 8 },
      {  textTop: 33, textLeft: 25 },
      {textTop: 58, textLeft: 46 },
      { textTop: 34, textLeft: 65 },
      {  textTop: 58, textLeft: 81 },
    ];
    const rewardPositionData2 = [
      { imageTop: 58, imageLeft: 9, textTop: 53, textLeft: 9 },
      { imageTop: 33, imageLeft: 24, textTop: 35, textLeft: 24 },
      { imageTop: 58, imageLeft: 44, textTop: 53, textLeft: 46 },
      { imageTop: 33, imageLeft: 62, textTop: 28, textLeft: 59 },
      { imageTop: 58, imageLeft: 80, textTop: 54, textLeft: 80 },
    ];

  // Level Rewards mapping



  return (
    <>
    {gameData&&<div>
      {showPopup&&<Coupon/>}
      <div style={{}}>
      <Rock />
      </div>
<div>
      <img src={bgimage} alt="Background" />
      <Link to='/guideLines'><img style={{width:"25vw",   
    position: "absolute",
    right: "4px",
    top: "80vw"}} src={KnowMore}/></Link>
      {/* Navigation Buttons */}
      <>
      {!(currentPage === totalPages) &&
      <div onClick={handleNext}
        style={{ position: "absolute", zIndex: 4, top: "41vw", right: 0 }}
      >
        <img style={{ width: "18vw" }} src={NextPage} alt="Next" />
      </div>}
      </>
      {!(currentPage==1)&&
      <div
        onClick={handlePrev}
        style={{ position: "absolute", zIndex: 4, top: "41vw" }}
      >
       <img style={{ width: "18vw" }} src={BackPage} alt="Next" />
      </div>}



      <div>
        {Array.from({ length: milestonesPerPage }).map((_, index) => (
          <div key={index}>
          
            <h1
          style={{
            color: "white",
            fontSize: "6vw",
            position: "absolute",
            fontWeight: "bold",
            zIndex: 3,
            top: `${index % 2 === 0 ? "65vw" : "40vw"}`,
            left: (String(startMilestone).length == 1) ? `${(leftPositions[index])}vw` :`${(Number(leftPositions[index]))-2}vw`}}
          
            >
              {startMilestone + index}
            </h1>
          </div>
        ))}
      </div>

      {/* Character only on current milestone */}
     
      {currentLevelMilestoneCount >= startMilestone && currentLevelMilestoneCount <= endMilestone && (
        <>
        <img
          src={characterImage}
          alt="Character"
          style={{
            position: "absolute",
            width: "13vw",
            top: `${TopPositionsChar[(currentLevelMilestoneCount - 1) % milestonesPerPage]}`,

        
            left:`${leftPositionsChar[(currentLevelMilestoneCount - 1) % milestonesPerPage]}` ,
            zIndex: 5,
          }}
         
        />
    </>
      )}

{levelRewards&&levelRewards.map((reward) => {
  // Determine the page of the reward
  const rewardPage = Math.ceil(reward.milestoneCount / milestonesPerPage);

  // Only render rewards for the current page
  console.log("rewardPage === currentPage",rewardPage === currentPage,reward)
  if (rewardPage === currentPage) {
    // Find the position of the reward within the current page
    const rewardIndex = (reward.milestoneCount - startMilestone) % milestonesPerPage;
    const rewardPosition = rewardPositionData[rewardIndex];
    const rewardPositionText = rewardPositionDatatxt[rewardIndex];
    const rewardPosition2 = rewardPositionData2[rewardIndex];
    if (rewardPosition) {
      return (
        <React.Fragment key={reward.milestoneCount}>
          {/* Conditional Rendering: Regular Reward or Surprise Gift Card */}
          {reward.isSurprise ? (
            // Render a separate div for the surprise reward with the gift card image
            <div
              style={{
                position: "absolute",
                top: `${rewardPosition2.imageTop}vw`,
                left: `${rewardPosition2.imageLeft}vw`,
                zIndex: 6,
              }}
            >



              <img
                src={Gift} // Replace with the actual gift card image path
                alt="Gift Card"
                style={{
                  width: "13vw",
                }}
              />
     
            </div>
          ) : (
            // Regular reward rendering
            <React.Fragment key={reward.milestoneCount}>
            {/* Reward Image */}
            <img
              src={Board}
              alt={`Reward for milestone ${reward.milestoneCount}`}
              style={{
                position: "absolute",
                width: "13vw",
              
                  position: "absolute",
                  top: `${rewardPosition.imageTop}vw`,
                  left: `${rewardPosition.imageLeft}vw`,
                
                zIndex: 1,
              }}
            />
            {/* Reward Text */}
            <div
              style={{
                position: "absolute",
                top: `${rewardPositionText.textTop}vw`,
                left: `${rewardPositionText.textLeft}vw`,
                zIndex: 7,
              }}
            >
              <p style={{textAlign:"center",fontSize:"3vw",color:"white",fontWeight:"900"}}>{reward.value}%</p>
              <h1 style={{ fontSize: "2.7vw", color: "white", textAlign: "center",    marginTop: "-2vw" }}>
                
                {reward.rewardType}
              </h1>
            </div>
          </React.Fragment>
          )}
        </React.Fragment>
      );
    }
  }
  return null;
})}
{currentPage === totalPages && (
        <img
          src={flagImage}
          alt="Flag"
          style={{
            position: "absolute",
            width: "10vw",
            top: `59vw`,
            left: `89vw`,
            zIndex: 10,
          }}
        />
      )}

      {/* Single Path Image */}
      <img
        src={getPagePathImage()}
        alt="Path"
        style={{
          position: "absolute",
          width: "100vw",
          top: "46vw",
          left: "0vw",
          zIndex: 1,
        }}
      />
      <Medal/>
      </div>
    </div>}
    </>
  );
};

export default MilestonePagination;




