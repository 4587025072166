export const FETCH_ORDER_HISTORY_LIST_REQUEST="FETCH_ORDER_HISTORY_LIST_REQUEST"
export const FETCH_ORDER_HISTORY_LIST_SUCCESS="FETCH_ORDER_HISTORY_LIST_SUCCESS"
export const FETCH_ORDER_HISTORY_LIST_FAILURE="FETCH_ORDER_HISTORY_LIST_FAILURE"
export const GOOGLE_ADD_NUMBER="GOOGLE_ADD_NUMBER";
export const GOOGLE_SUMIT_OTP="GOOGLE_SUMIT_OTP";
export const googleAddNumber=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:GOOGLE_ADD_NUMBER,
        data:myurldata
    }

    
   
}


export const googleOtpSumit=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:GOOGLE_SUMIT_OTP,
        data:myurldata
    }

    
   
}



export const fetchOrdersHistoryRequest=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:FETCH_ORDER_HISTORY_LIST_REQUEST,
        data:myurldata
    }

    
   
}

export const fetchOrdersHistorySuccess=(OrderHistory)=>{
return{
    type:FETCH_ORDER_HISTORY_LIST_SUCCESS,
    OrderHistory:OrderHistory
}
}

export const fetchOrdersHistoryFailure=(error)=>{
    return{
    type:FETCH_ORDER_HISTORY_LIST_FAILURE,
    error:error
    }
}