import ReferEarnComp from "./component";
import { PostReferCodeRequest } from "./action";
import { useState } from "react";
import { connect } from "react-redux";
import {  toast } from 'react-toastify';
const ReferEarnContainer=(props)=>{

  const [earnModal, setEarnModal] = useState(false);
  const [referCode,setReferCode]=useState(null)


  const handleReferCode=(e)=>{

    console.log("input refer code --",e.target.value)

    setReferCode(e.target.value);



  }


  const ApplyReferCode=()=>{
    if(referCode&&referCode.length>3){
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
    props.PostReferCodeRequest({
        url: `/auth/verify/referral/code`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,
           "x-app-platform": "web",
                 "x-app-version": "v1.0.1",
                 //HEADER ERROR
                 "x-device-id":localStorage.getItem("uuid")
        },
        request:{
          request:{
            
            "referCode":referCode
          
          
          }
          
          },
          
    
    
  
        handleErrorApplyReferCode ,
        handleResponseApplyReferCode,
      });
    }
    else{

       
  toast.error("Enter Refer Code", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
});
    }
}


const handleErrorApplyReferCode=(err)=>{
 
  toast.error(`${err&&err.response&&err.response.data.statusMessage}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
});
}

const handleResponseApplyReferCode=(res)=>{

  console.log("Error",res.data)
  
  toast.error(`${res&&res.data&&res.data.statusMessage}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
});

}


    return(<>
    
    
    <ReferEarnComp earnModal={earnModal} setEarnModal={setEarnModal} handleReferCode={handleReferCode} ApplyReferCode={ApplyReferCode}/>
    
    </>)
}

function mapStateToProps( props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    PostReferCodeRequest
   
  })(ReferEarnContainer);
