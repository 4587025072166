export const POST_REFER_ERAN_REQUEST="POST_REFER_ERAN_REQUEST"
export const POST_REFER_ERAN_SUCCESS="POST_REFER_ERAN_SUCCESS"
export const POST_REFER_ERAN_FAILURE="POST_REFER_ERAN_FAILURE"


export const PostReferCodeRequest=(mydata)=>{
    console.log("etchPreCardRequest myurldata---->",mydata)
    return{
        type:POST_REFER_ERAN_REQUEST,
        data:mydata
    }

    
   
}

export const POSTUserDetailsSuccess=(UserDetails)=>{
return{
    type:POST_REFER_ERAN_SUCCESS,
    UserDetails:UserDetails
}
}

export const POSTUserDetailsFailure=(error)=>{
    return{
    type:POST_REFER_ERAN_FAILURE,
    error:error
    }
}
