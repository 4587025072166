import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { SEND_SUBMIT_OTP_REQUEST, SEND_USER_LOGIN_REQUEST, sendUserLoginFailure, sendUserLoginSuccess ,sendSubmitOtpSuccess,sendSubmitOtpFailure, SEND_SKIP_LOGIN_REQUEST, sendSkipLoginSuccess, sendSkipLoginFailure, SEND_GOOGLE_REQUEST} from "./action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
//////////////////////////////////////////////////////////////////////////////////////////////////
  




/////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* sendUserLoginDetails(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(sendUserLoginSuccess(response));
  
      action.data.handleResponseUserLogin(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorUserLogin(error.message)
      yield put(sendUserLoginFailure(error.message));
    }
  }





  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* sendSubmitOtoDetails(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(sendSubmitOtpSuccess(response));
  
      action.data.handleResponseSubmitOtp(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      yield put(sendSubmitOtpFailure(error.message));
    }
  }



  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* sendSkipLogin(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
      yield put(sendSkipLoginSuccess(response));
  
      action.data.handleResponseSkipLogin(response);
    } catch (error) {
      console.log("fetchPreCard error", error);
      yield put(sendSkipLoginFailure(error.message));
    }
  }



  function* sendGoogleLogin(action) {
    console.log("fetchPreCard saga", action);
    try {
      const response = yield call(callApi, action);
  
     
  
      action.data.handleResponseGoogleLoginApi(response);
    } catch (error) {
      console.log("fetchPreCard error", error);
      action.data.handleErrorGoogleLoginApi(error.message);
  
    }
  }





  function* UserLoginSaga() {
    yield takeLatest(SEND_USER_LOGIN_REQUEST, sendUserLoginDetails);
    yield takeLatest(SEND_SUBMIT_OTP_REQUEST,sendSubmitOtoDetails)
    yield takeLatest(SEND_SKIP_LOGIN_REQUEST,sendSkipLogin)
    yield takeLatest(SEND_GOOGLE_REQUEST,sendGoogleLogin)
    
  }
  
  export default UserLoginSaga;