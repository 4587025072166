export const GOOGLE_ADD_NUMBER="GOOGLE_ADD_NUMBER";


export const googleAddNumber=(myurldata)=>{
    console.log("etchPreCardRequest myurldata---->",myurldata)
    return{
        type:GOOGLE_ADD_NUMBER,
        data:myurldata
    }

    
   
}