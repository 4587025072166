import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { GOOGLE_ADD_NUMBER } from "./action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
//////////////////////////////////////////////////////////////////////////////////////////////////
  




/////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* addnumberGoogle(action) {
    console.log("addnumberGoogle saga", action);
    try {
      const response = yield call(callApi, action);
  
     
  
      action.data.handleResponsegoogleAddnumber(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
      action.data.handleErrorgoogleAddnumber(error)
      
    }
  }




  function* NumberAddGoogleSaga() {
    yield takeLatest(GOOGLE_ADD_NUMBER, addnumberGoogle);
   
  }
  
  export default NumberAddGoogleSaga;