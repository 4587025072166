import React from 'react';

export const handleShare = async () => {
  // Check if Web Share API is available
  if (navigator.share) {
    try {
      // Define the URLs for iOS and Android
      const shareUrlAndroid = "http://play.google.com/store/apps/details?id=app.daalchini.com";
      const shareUrlIos = "https://apps.apple.com/in/app/daalchini-healthy-home-food/id1443105751";

      // Check the platform (iOS or Android)
      const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      const isAndroid = /android/i.test(navigator.userAgent);

      // Set the URL based on the platform
      const shareUrl = isIos ? shareUrlIos : isAndroid ? shareUrlAndroid : window.location.href;

      // Define the content to be shared
      const title = 'Buying Healthy Home Food becomes faster and better at Daalchini Kiosk.';
      const text = `Now try this App to buy from Daalchini Kiosk.\n${shareUrl}\nUse code ${localStorage.getItem('referCode')} to earn Daalchini Cashback.`;

      // Perform the share action
      await navigator.share({
        title: title,
        text: text,
        url: shareUrl,  // The appropriate platform-specific URL
      });

      console.log('Content shared successfully');
    } catch (error) {
      console.error('Error sharing:', error);
    }
  } else {
    alert('Web Share API not supported on this browser.');
  }
};
