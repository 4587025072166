import woodboard from "../../../assets/game/woodboard.png";
import guideBg from "../../../assets/game/guideBg.png";
import penicon from "../../../assets/game/penicon.png";
import Journey from "../../../assets/game/journey.png";

import aboutActive from "../../../assets/game/aboutActive.png"
import Reward from "../../../assets/game/Reward.png"
import Levels from "../../../assets/game/Levels.png"
import active from "../../../assets/game/active.png"
import rewardActive from "../../../assets/game/rewardActive.png"
import levelsActive from "../../../assets/game/levelsActive.png"
import Gift from "../../../assets/game/gift.png"
import offerBoard from "../../../assets/game/offerBoard.png"
import wallet from "../../../assets/game/wallet.png"
import HowStart from "../../../assets/game/howStart.png"
import Re from "../../../assets/game/Re.png"
import { useState } from "react";
import Level from "../../../assets/game/LevelsandMil.png"
import Back from "../../../assets/game/back.png"

import { useNavigate } from "react-router-dom";


const Guidelines = () => {

    const [activeMenu,setActiveMenu]=useState(1)

    const handleMenu=(e,active)=>{
        setActiveMenu(active)

    }


    const HeaderSecondary = (props) => {
        const navigator=useNavigate()
         const  handleBack=()=>{
        
          navigator('/')
          }
        
          return (
            <div className='h-[60px] flex items-center daalchini-header-color '>
                <img src={Back}  style={{    width: "24px"}}className='ml-5' onClick={handleBack}/>
                <p className='font-body font-medium  ml-2 text-gray-800'>{props&&props.title}</p>
            </div>
          )
        }
        
        

    const BottomMenu=()=>{
        return(<>
        
        <div style={{background: "#b45d24",width:"100vw",height:"14vw", position:"fixed",bottom:0 ,}}>
            <div style={{display:"flex",justifyContent: "space-around"}}>
    {
       activeMenu==1?
       <div style={{position: "relative",
        top: "-20px"}}><img src={aboutActive} style={{width:"15vw"}} /></div>: <div style={{position: "relative",
        top: "2px"}}><img src={active} style={{width:"15vw"}} onClick={(e)=>{handleMenu(e,1)}} /></div>
        
         }
    
    
    {activeMenu==2?<div style={{position: "relative",
        top: "-20px"}}><img src={rewardActive}  style={{width:"15vw"}}/></div>:<div style={{position: "relative",
            top: "2px"}}><img src={Reward}  style={{width:"15vw"}} onClick={(e)=>{handleMenu(e,2)}}/></div>}
    
    
    
    
    {activeMenu==3?<div style={{position: "relative",
        top: "-20px"}}  ><img src={levelsActive}  style={{width:"15vw"}} /></div>:<div style={{position: "relative",
            top: "2px"}}  ><img src={Levels}  style={{width:"15vw"}}  onClick={(e)=>{handleMenu(e,3)}}/></div>}
    
    
        </div>
    
    
    
        </div>
        
        </>)
    }
  return (
    <>
    <HeaderSecondary title={"Guidelines"}/>
      {activeMenu==1&&
    <img style={{    height: "85vh",
        width: "100vw",
        padding: "5%",paddingBottom:"10vw"}} src={HowStart}/>
    }
 


{activeMenu==2&&<>
    <img style={{    height: "85vh",
        width: "100vw",
        padding: "5%",paddingBottom:"10vw"}} src={Re}/>
        </>
    }
{activeMenu==3&&<>
<img style={{    height: "85vh",
    width: "100vw",
    padding: "5%",paddingBottom:"10vw"}} src={Level}/>

</>}

<BottomMenu/>

    </>
  );
};

export default Guidelines;
 


/* import woodboard from "../../../assets/game/woodboard.png";
import guideBg from "../../../assets/game/guideBg.png";
import penicon from "../../../assets/game/penicon.png";
import Journey from "../../../assets/game/journey.png";
import HeaderSecondary from "../../Header/AddTagHeader";
import aboutActive from "../../../assets/game/aboutActive.png";
import Reward from "../../../assets/game/Reward.png";
import Levels from "../../../assets/game/Levels.png";
import active from "../../../assets/game/active.png";
import rewardActive from "../../../assets/game/rewardActive.png";
import levelsActive from "../../../assets/game/levelsActive.png";
import Gift from "../../../assets/game/gift.png";
import offerBoard from "../../../assets/game/offerBoard.png";
import wallet from "../../../assets/game/wallet.png";
import { useState } from "react";
import "./index.css"; // Create a separate CSS file for styling

const Guidelines = () => {
  const [activeMenu, setActiveMenu] = useState(1);

  const handleMenu = (e, active) => {
    setActiveMenu(active);
  };

  const BottomMenu = () => {
    return (
      <div className="bottom-menu">
        <div className="menu-items">
          {activeMenu === 1 ? (
            <div className="menu-item active">
              <img src={aboutActive} alt="About Active" />
            </div>
          ) : (
            <div className="menu-item">
              <img src={active} alt="About" onClick={(e) => handleMenu(e, 1)} />
            </div>
          )}

          {activeMenu === 2 ? (
            <div className="menu-item active">
              <img src={rewardActive} alt="Reward Active" />
            </div>
          ) : (
            <div className="menu-item">
              <img
                src={Reward}
                alt="Reward"
                onClick={(e) => handleMenu(e, 2)}
              />
            </div>
          )}

          {activeMenu === 3 ? (
            <div className="menu-item active">
              <img src={levelsActive} alt="Levels Active" />
            </div>
          ) : (
            <div className="menu-item">
              <img
                src={Levels}
                alt="Levels"
                onClick={(e) => handleMenu(e, 3)}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="guidelines">
      <HeaderSecondary />
      {activeMenu === 1 && (
        <div className="content">
          <div className="header">
            <h1>How to Get Started</h1>
            <img src={woodboard} alt="Woodboard" className="woodboard" />
          </div>
          <div className="guide-section">
            <img src={guideBg} alt="Guide Background" />
          </div>
          <div className="instructions">
            <div className="instruction">
              <img src={penicon} alt="Step" />
              <h1>Place your first order to start at Bronze.</h1>
            </div>
            <div className="instruction">
              <img src={penicon} alt="Step" />
              <h1>Each milestone represents one order.</h1>
            </div>
            <div className="instruction">
              <img src={penicon} alt="Step" />
              <h1>
                Level Up: Complete all milestones within 30 days to move up.
              </h1>
            </div>
            <div className="instruction">
              <img src={penicon} alt="Step" />
              <h1>
                Aim to complete levels on time to avoid downgrades.
              </h1>
            </div>
            <h1 className="journey-title">Your Journey</h1>
            <div className="journey-image">
              <img src={Journey} alt="Journey" />
            </div>
          </div>
        </div>
      )}

      {activeMenu === 2 && (
        <div className="content">
          <div className="header">
            <h1>Rewards</h1>
            <img src={woodboard} alt="Woodboard" className="woodboard" />
          </div>
          <div className="guide-section">
            <img src={guideBg} alt="Guide Background" />
          </div>
          <div className="rewards-section">
            <h1>
              You can see your progress and rewards on the Milestone Map on the
              home screen.
            </h1>
            <div className="reward-details">
              <h1>
                <span>The map shows:</span> Each milestone you’ve reached,
                milestones where rewards await you.
              </h1>
              <h1>
                <span>The type of reward:</span> Mystery Rewards
              </h1>
              <img src={Gift} alt="Gift" />
            </div>
            <div className="cashback">
              <h1>Cashback rewards will be credited in your wallet.</h1>
              <img src={wallet} alt="Wallet" />
            </div>
          </div>
        </div>
      )}

      <BottomMenu />
    </div>
  );
};

export default Guidelines; */
