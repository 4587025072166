import { connect } from "react-redux";
import Orders from "./component";
import {fetchOrdersHistoryRequest,googleAddNumber,googleOtpSumit} from './action'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import { mpClick } from "../../Mixpanel";

const OrdersContainer = (props) => {

const OrderHistory=props&&props.props&&props.props.OrderHistory&&props.props.OrderHistory.OrederHistoryList



console.log("OrderHistory",OrderHistory)
const [orderhistory,setOrderhistory]=useState('')
const [loader,setLoader]=useState(true);
const [google_Pop_flag,setGoogle_Pop_flag]=useState(false)
const [google_OTP_Pop_flag,setGoogle_OTP_Pop_flag]=useState(false)
const [number,setNumber]=useState("")
const [otp,setOtp]=useState("")
const [numberError,setNumberError]=useState(false)
const [numberErrorMsg,setNumberErrorMsg]=useState('')
const [otpError,setOtpError]=useState(false)
const [google_success_flag,setGoogle_success_flag]=useState(false);
const [google_requestId,setGoogle_requestId]=useState(null)
const [otpErrorMsg,setOtpErrorMsg]=useState(null)
const [gmail,setGmail]=useState(null)
const navigate=useNavigate()
useEffect(()=>{
  if(localStorage.getItem("google_jwt")){
    setGoogle_Pop_flag(true)
  }
  if(navigator.onLine){
  fetchOrderHistory()
}
else{
  setLoader(false)
}
},[])

    
useEffect(()=>{
  mpClick({EventName:"past_orders_page_opened"})
},[])
const UserJWTAuth = localStorage.getItem('UserJWTAuth');
useEffect(()=>{
 
 console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
 if(!UserJWTAuth){
   navigate('/')
 }
},[])
useEffect(()=>{
 
  console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
  if(localStorage.getItem('Guest_clientId')){
    navigate('/login')
  }
 },[])
 

 const closePop=(e)=>{
  setGoogle_Pop_flag(false);
  console.log("localStorage.getItem(",localStorage.getItem("machineId"))
  if(!(localStorage.getItem("machineId")==null)){
  navigate(`/`)
  }
  else{
    navigate('/')
  }
}



const fetchOrderHistory = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.fetchOrdersHistoryRequest({
    url: `/order/order/orderhistory`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
    "x-device-id":localStorage.getItem("uuid")
    },
    "request": {
      request:{}
      


      },
    handleErrorOrderHistory ,
    handleResponseOrderHistory ,
  });
};


const handleErrorOrderHistory=(error)=>{
  console.log("res")
  if(error.response.status==401|| error.response.status==403){

    console.log("khan handleErrorVmInventory", error.response.data.status.code);
    //Logout
    //localStorage.removeItem("UserJWTAuth")
      navigate("/login");
}
else{ toast.error(`${error.response.data.status.code}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}
  setLoader(false)
}

const handleResponseOrderHistory=(res)=>{
  console.log("handleResponseOrderHistory",res)
  setLoader(false)
}



const handle_Number = (e) => {
  e.preventDefault(); // Prevent default action (if needed)
  console.log("number", e.target.value);
  setNumber(e.target.value); // Update the state with the new value
};


const googleAddnumber=()=>{
 console.log("RESEND")
  const UserJWTAuth=localStorage.getItem('UserJWTAuth')
if(number.length==10){
setNumberError(false)

  props.googleAddNumber({
    url: `/auth/user/authenticate`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
     
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
    },
    "request": {
      "request": {
"mobileNo": number,
"googleId":localStorage.getItem("gmail")
      }
  
      


      },
    handleErrorgoogleAddnumber ,
    handleResponsegoogleAddnumber:(res)=>handleResponsegoogleAddnumber(res),
  });
}
else{
  setNumberError(true)
  setNumberErrorMsg("Enter a valid mobile number")

}
};

const handleResponsegoogleAddnumber=(res)=>{
 console.log("setGoogle_requestId",res.data.response.requestId)
 setGoogle_requestId(res.data.response.requestId)
  setGoogle_Pop_flag(false)
  setGoogle_OTP_Pop_flag(true)
  
 

}

const handleErrorgoogleAddnumber=(err)=>{
  console.log("handleErrorgoogleAddnumber",err.response.data.statusMessage)
  toast.error(`${err.response.data.statusMessage}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
  setNumberError(true)
  setNumberErrorMsg(err.response.data.statusMessage)
  
}




const googleOtpSubmit=()=>{
  const card_id = localStorage.getItem("card_id");
 console.log("otp")
  const UserJWTAutgoogleOtpSumith=localStorage.getItem('UserJWTAuth')
  setOtpErrorMsg("Invalid OTP")
if(otp.length==4){
  setOtpError(false)
 
  props.googleOtpSumit({
    url: `/auth/user/login`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "guest-cart-id":card_id,
     
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
    },
    "request": {
      "request": {
        "deviceId":localStorage.getItem('uuid'),
        "fcmUserToken":null,
        "mobileNo":number,

        otp:otp,
        "requestId":google_requestId,
        "sourcePlatform":"web"
      }
  
      


      },
      handleErrorgoogleOTp ,
    handleResponsegoogleOtp:(res)=>handleResponsegoogleOtp(res),
  });
}
else{
  setOtpError(true)
}
};

const handleResponsegoogleOtp=(res)=>{
  console.log("handleResponsegoogleOtp",res.data.response,res.data.status,res.data.statusMessage)
  if(res&&res.data&&res.data.response){

  setGoogle_success_flag(true)
  setGoogle_OTP_Pop_flag(false)

  localStorage.setItem("UserJWTAuth",res.data.response.jwt)
  localStorage.setItem("referCode",res.data.response.referCode)
  localStorage.setItem("isUserLogged",res.data.response.isUserLogged)
  localStorage.setItem("isGuest",res.data.response.isGuest)
  localStorage.setItem("isTokenExpired",res.data.response.isTokenExpired)
  }
 if(res&&res.data&&res.data.status=="FAILURE"){

  setOtpError(true)

  setOtpErrorMsg(res&&res.data&&res.data.statusMessage)
 }

}

const handleErrorgoogleOTp=()=>{

}



const closeOTpPop=(e)=>{
  setGoogle_Pop_flag(true);
 
setGoogle_OTP_Pop_flag(false)
}










return(
    <Orders
    orderhistory={OrderHistory}
    loader={loader}
    setGoogle_Pop_flag={setGoogle_Pop_flag}

    google_Pop_flag={google_Pop_flag}
    closePop={closePop}
    handle_Number={handle_Number}
    googleAddnumber={googleAddnumber}
    number={number}
    setNumber={setNumber}
    setGoogle_OTP_Pop_flag={setGoogle_OTP_Pop_flag}
    google_OTP_Pop_flag={google_OTP_Pop_flag}
    closeOTpPop={closeOTpPop}
    numberError={numberError}
    googleOtpSubmit={googleOtpSubmit}
    setOtp={setOtp}
    otp={otp}
    otpError={otpError}
    otpErrorMsg={otpErrorMsg}
    setGoogle_success_flag={setGoogle_success_flag}
    google_success_flag={google_success_flag}
    numberErrorMsg={numberErrorMsg}
   
    
    />
)


}



function mapStateToProps( props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    fetchOrdersHistoryRequest,
    googleAddNumber,
    googleOtpSumit
   
  })(OrdersContainer);