import { connect } from "react-redux";
import { sendUserLoginRequest ,sendSubmitOtpRequest,sendSkipLoginRequest,sendGoogleLoginRequest} from "./action";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import LoginPage from '../Login/component'
import { v4 as uuidv4 } from 'uuid';
import { mpClick } from "../../Mixpanel";
import mixpanel from 'mixpanel-browser'
//import { v4 as uuidv4 } from 'uuid';





const LoginPageContainer = (props) => {
 console.log("LoginPageContainer", )

 const Guest_ClientId=props&&props.props.LoginData&&props.props.LoginData.SkipLogin_Guest_ClientId
 const Guest_Jwt=props&&props.props.LoginData&&props.props.LoginData.SkipLogin_Guest_Jwt
 const Guest_OauthToken=props&&props.props.LoginData&&props.props.LoginData.SkipLogin_Guest_OauthToken






  const navigate = useNavigate();
  const generatedUUID = uuidv4();
  //console.log("  const generatedUUID = uuidv4();",generatedUUID)
  const [mobile,setMobile]=useState("");
  const [otp,setOtp]=useState("");
const [login,setlogin]=useState(true);
const [loginOtp,setloginOtp]=useState(false);
const [loader,setLoader]=useState(false)
const [requestId,setRequestId]=useState(null)
const [notnewUser,setNotNewuser]=useState("true")
const [continueflag,setContinueflag]=useState(false)
const [otpFlag,setOtpFlag]=useState(false)
const UserJWTAuth = localStorage.getItem('UserJWTAuth');
const [GuestLogin,setGuestLogin]=useState(true)






useEffect(()=>{
  //UserLoginApi()
  //SubmitOtpApi()
  //SkipLoginApi()

},[])

  




const responseGoogle = (response) => {
  setLoader(true)
  if (response.error) {
    console.error("Google login failed:", response.error);
    // Optional: Show a user-friendly message
   // alert("Login process was not completed. Please try again.");
  } else {
    console.log("Google login success:", response);
    localStorage.setItem("uuid",generatedUUID)
    GoogleLoginApi(response.tokenId)
    localStorage.setItem("gmail",response.profileObj.email)

    // Proceed with your application's login logic
  }
};





const GoogleLoginApi=(tokenId)=>{
  const card_id = localStorage.getItem("card_id");
  setLoader(true)
  localStorage.setItem("uuid",generatedUUID)
   props.sendGoogleLoginRequest({
    url: `/auth/user/google/login`,
    method: "POST",
    headers: {
      //Authorization: `Bearer ${UserJWTAuth}`,
    
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      "x-device-id":localStorage.getItem("uuid"),
      "guest-cart-id": card_id,
      
    },
   
     "request":{"googleIdToken":tokenId}
          
      ,
      
    

    
    handleErrorGoogleLoginApi ,
    handleResponseGoogleLoginApi:(res)=>handleResponseGoogleLoginApi(res) ,
  });
};


const handleResponseGoogleLoginApi=(res)=>{

  console.log("res jwt",res.data&&res.data.response)

  localStorage.setItem("google_jwt",res.data&&res.data.response&&res.data.response.jwt)
  localStorage.setItem("UserJWTAuth",res.data&&res.data.response&&res.data.response.jwt)
  localStorage.setItem("isGuest",res.data&&res.data.response&&res.data.response.isGuest)
  localStorage.removeItem("Guest_clientId")


  navigate("/")

}


const handleErrorGoogleLoginApi=()=>{

}

const handleErrorUserLogin=()=>{

  mpClick({EventName:"auth_error"})

}

const handleResponseUserLogin=(res)=>{
 // 
  
  console.log("handleResponseUserLogin",res.data.successful,res.data.successful==false)
  if(false==res.data.successful){

    toast.error(`${res.data.statusMessage}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
  })
  setLoader(false)


  }
  if(true==res.data.successful){

  setRequestId(res.data.response.requestId)
  setNotNewuser(res.data.response.isValidUser)
  setlogin(false)
  setloginOtp(true)
  setLoader(false)
  console.log("UserId-----------",res.data.response)
  localStorage.setItem("uuid",generatedUUID)
 mpClick({EventName:"auth_opened"})
  setLoader(false)


  }

  if(res.data.response.isValidUser)
  {
    mpClick({EventName:"login_requested"})
  }
  else{
    mpClick({EventName:"signup_requested"})
  }

}


const onchangeMobile=(e)=>{
  setMobile(e.target.value)

}


const UserLoginApi=()=>{
  setLoader(true)
   props.sendUserLoginRequest({
    url: `/auth/user/authenticate`,
    method: "POST",
    headers: {
      //Authorization: `Bearer ${UserJWTAuth}`,
    
      //"x-app-platform": "web",
      //"x-app-version": "v1.0.1",
    },
    request: {
     "request":{"mobileNo":mobile}
          
      },
      
    

    
    handleErrorUserLogin ,
    handleResponseUserLogin:(res)=>handleResponseUserLogin(res) ,
  });
};




const handleErrorSubmitOtp=()=>{



}

const handleResponseSubmitOtp=(res)=>{
  localStorage.setItem("defaultPgName","WALLET_LOYALTY")
  localStorage.setItem("defaultPgId",9)
  const enteryUrl=localStorage.getItem('enteryUrl')

  console.log("res.data.successful",res.data.successful,true==res.data.successful)
  if(false==res.data.successful){

    toast.error(`${res.data.statusMessage}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
  })

  }
  if(true==res.data.successful){

    localStorage.setItem("UserJWTAuth",res.data.response.jwt);
    setlogin(false)
    setloginOtp(true)
    setLoader(false)
    localStorage.setItem("UserId",res.data.response.userId)
    mixpanel.identify(res.data.response.userId)
    const redirect_url=localStorage.getItem("redirect_url")
    if(redirect_url)
    {
      window.location.href = redirect_url
    }
    else{
      if(enteryUrl)
      {
        navigate(enteryUrl,{ replace: true })
      }
     else
     {navigate('/',{ replace: true })
    }
    }
   
  }

  

}


const SubmitOtpApi=()=>{

  localStorage.removeItem("Guest_clientId")

  localStorage.setItem("mobileNo",mobile)
  const card_id = localStorage.getItem("card_id");
  props.sendSubmitOtpRequest({
    url: !(notnewUser=="false")?"/auth/user/login":"/auth/user/register",
    method: "POST",
    headers: {
      //Authorization: `Bearer ${UserJWTAuth}`,
      
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid"),
      "guest-cart-id": card_id,

      
    },
    "request": {
      request:{
        
          "mobileNo": mobile,
          "requestId": requestId,
          "otp": otp,
          "fcmUserToken": null,
          "sourcePlatform": "web",
          //"x-app-platform": "web",
          "deviceId": localStorage.getItem("uuid"),
          
      }
      


      },
    handleErrorSubmitOtp ,
    handleResponseSubmitOtp ,
  });
};





const handleResponseSkipLogin=(res)=>{
  localStorage.setItem("uuid",generatedUUID)

  console.log("handleResponseSkipLogin",res&&res.data)

  localStorage.setItem("Guest_clientId",res&&res.data&&res.data.response&&res.data.response.clientId)

  localStorage.setItem("UserJWTAuth",res&&res.data&&res.data.response&&res.data.response.jwt)

  localStorage.setItem("Guest_OauthToken",res&&res.data&&res.data.response&&res.data.response.oauthToken)
  navigate("/")
  setLoader(false)
}

const handleErrorSkipLogin=(error)=>{
  setLoader(false)
    toast.error(`${error.res.data.statusMessage}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
  })


}

const SkipLoginApi=()=>{
  localStorage.setItem("defaultPgName","WALLET_LOYALTY")
  localStorage.setItem("defaultPgId",9)
  setLoader(true)
  props.sendSkipLoginRequest({
    url: `/auth/user/guest/login`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
     
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
    },
    "request": {
      request:{

        "request": {
          clientId:"null",
         

          }
      }
      


      },
    handleErrorSkipLogin ,
    handleResponseSkipLogin:(res)=>handleResponseSkipLogin(res),
  });
};










const onchangeOtp=(e)=>{
  
  setOtp(e.target.value)
  if(otp.length==4 || otp.length==3)
  {
    setOtpFlag(true)
  }
  else{
    setOtpFlag(false)
  }
 


}

const onPasteOtp=(e)=>{
  const pasteOtp=e.clipboardData.getData("text")
  console.log('onchangeOtp',e.clipboardData.getData("text"))
  setOtp(e.target.value)
  if(pasteOtp.length==3)
  {
    setOtpFlag(true)
  }
  else{
    setOtpFlag(false)
  }
 


}














 


  
return(
  <>
  <LoginPage
    LoginApi={UserLoginApi}
    setMobile={setMobile}
    login={login}
    loginOtp={loginOtp}
    loader={loader}
    setOtp={setOtp}
    SubmitOTP={SubmitOtpApi}
    onchangeMobile={onchangeMobile}
    mobile={mobile}
    otpFlag={otpFlag}
    continueflag={continueflag}
    onchangeOtp={onchangeOtp}
    onPasteOtp={onPasteOtp}
    otp={otp}
    skip={SkipLoginApi}
    responseGoogle={responseGoogle}
    setLoader={setLoader}

    
      />
 

</>
)


}



function mapStateToProps( props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    
    sendUserLoginRequest,
    sendSkipLoginRequest,
    sendSubmitOtpRequest,
    sendGoogleLoginRequest
  })(LoginPageContainer);
