import { connect } from "react-redux";
import Userprofile from "./component";
import { fetchUserDetailsRequest,fetchLogoutCurrentDeviceRequest ,fetchLogoutAllDeviceRequest,fetchLogInDeviceCountRequest} from "./action";
import { googleOtpSumit } from "../Orders/action";
import { googleAddNumber } from "../Orders/action";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import clearAllCookies from "../Cookies";
const UserprofileContainer = (props) => {
  const navigate=useNavigate()

const [loader,setLoader]=useState(false)
const [profileData,setProfileData]=useState('')
const [activeSessionCount,setActiveSessionCount]=useState('')
const [numberErrorMsg,setNumberErrorMsg]=useState('')

const [google_OTP_Pop_flag,setGoogle_OTP_Pop_flag]=useState(false)
const [number,setNumber]=useState("")
const [otp,setOtp]=useState("")
const [google_requestId,setGoogle_requestId]=useState(null)
const [numberError,setNumberError]=useState(false)
const [otpError,setOtpError]=useState(false)
const [google_success_flag,setGoogle_success_flag]=useState(false);
const [otpErrorMsg,setOtpErrorMsg]=useState("")
const [google_Pop_flag,setGoogle_Pop_flag]=useState(false)
useEffect(()=>{
  fetchUserDeatls()
  
},[])






const UserJWTAuth = localStorage.getItem('UserJWTAuth');
useEffect(()=>{
 
 console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
 if(!UserJWTAuth){
   navigate('/')
 }
},[])

useEffect(()=>{
 
  console.log("UserJWTAuth BusinessPaymentContainer",UserJWTAuth)
  if(localStorage.getItem('Guest_clientId')){
    //navigate('/login')
  }
 },[])
 
//

const closePop=(e)=>{
  setGoogle_Pop_flag(false);
  console.log("localStorage.getItem(",localStorage.getItem("machineId"))
  
  navigate(`/`)


}


const goToPaymentsOption=()=>{
   

  localStorage.setItem("paytmOptionFrom",window.location.pathname)
  navigate('/paymentOption')
  
    }


    const goToAddTag=()=>{
     
      navigate('/taglist')
      

    }

const fetchUserDeatls = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.fetchUserDetailsRequest({
    url: `/auth/user/profile/details`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      
    },
    "request": {
      request:{

        "request": {
          "oauthToken":`Bearer ${UserJWTAuth}`,
          }
      }
      


      },
    handleErrorUserDetails,
    handleResponseUserDetails ,
  });
};


const handleErrorUserDetails=(error)=>{
  console.log("khan handleErrorVmInventory", error.response.data.status.code);
  if(error.response.status==401|| error.response.status==403){

    console.log("khan handleErrorVmInventory", error.response.data.status.code);
    //Logout
    //localStorage.removeItem("UserJWTAuth")
    const google_jwt= localStorage.getItem("google_jwt")
    console.log("!google_jwt",!google_jwt)
    if(!google_jwt){
    
     navigate("/login");
    }
    if(google_jwt){
      setGoogle_Pop_flag(true)
    }
}
else{ toast.error(`${error&&error.response&&error.response.data&&error.response.data.status}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}
  
  setLoader(false)
  
}

const handleResponseUserDetails=(res)=>{
  console.log("handleResponseUserHistory",res.data.response)
  localStorage.setItem("referCode",res.data.response.referCode)
  setProfileData(res.data.response)
  setLoader(false)
}




const LogoutCurrentDevice = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");
//https://api-prod.daalchini.co.in
  props.fetchLogoutCurrentDeviceRequest({
    url: `/auth/user/logout`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      
    },
    "request": {

      


      },
    handleErrorLogoutCurrentDevice,
    handleResponseLogoutCurrentDevice  ,
  });
};


const handleErrorLogoutCurrentDevice =(error)=>{
  console.log("khan handleErrorVmInventory", error.response.data.status.code);
  if(error.response.status==401|| error.response.status==403){

    console.log("khan handleErrorVmInventory", error.response.data.status.code);
    //Logout
    //localStorage.removeItem("UserJWTAuth")
     navigate("/login");
}
else{ toast.error(`${error&&error.response&&error.response.data&&error.response.data.status}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}
  
  setLoader(false)
  
}

const handleResponseLogoutCurrentDevice =(res)=>{
clearAllCookies()
  setLoader(false)
  localStorage.removeItem('UserJWTAuth')
  navigate('/login')
  //clearAllCookies()
  window.location.reload()
 
}

const handleErrorLogoutAllDevice =(error)=>{
  console.log("khan handleErrorVmInventory", error.response.data.status.code);
  if(error.response.status==401|| error.response.status==403){

    console.log("khan handleErrorVmInventory", error.response.data.status.code);
    //Logout
    localStorage.removeItem("UserJWTAuth")
     navigate("/login");
  
 
}
else{ toast.error(`${error&&error.response&&error.response.data&&error.response.data.status}`, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 1000,
});
}
  
  setLoader(false)
  
}

const handleResponseLogoutAllDevice =(res)=>{
  clearAllCookies()
  setLoader(false)
  setLoader(false)
  localStorage.removeItem('UserJWTAuth')
  navigate('/login')
  window.location.reload()
}

const LogoutAllDevice = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.fetchLogoutAllDeviceRequest({
    url: `/auth//user/logout/all`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      
    },
    "request": {
    
      


      },
    handleErrorLogoutAllDevice,
    handleResponseLogoutAllDevice  ,
  });
};








const handle_Number = (e) => {
  e.preventDefault(); // Prevent default action (if needed)
  console.log("number", e.target.value);
  setNumber(e.target.value); // Update the state with the new value
};


const googleAddnumber=()=>{
 
  const UserJWTAuth=localStorage.getItem('UserJWTAuth')
if(number.length==10){
setNumberError(false)
  props.googleAddNumber({
    url: `/auth/user/authenticate`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
     
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
    },
    "request": {
      "request": {
"mobileNo": number,
"googleId":localStorage.getItem("gmail")
      }
  
      


      },
    handleErrorgoogleAddnumber ,
    handleResponsegoogleAddnumber:(res)=>handleResponsegoogleAddnumber(res),
  });
}
else{
  setNumberError(true)
  
  setNumberErrorMsg("Enter a valid mobile number")
}
};

const handleResponsegoogleAddnumber=(res)=>{
  console.log("setGoogle_requestId",res.data.response.requestId)
  setGoogle_requestId(res.data.response.requestId)
   setGoogle_Pop_flag(false)
   setGoogle_OTP_Pop_flag(true)
   
  
 
 }

 const handleErrorgoogleAddnumber=(err)=>{
  setNumberError(true)
  setNumberErrorMsg(err.response.data.statusMessage)
  console.log("handleErrorgoogleAddnumber",err.response.data.statusMessage)
  toast.error(`${err.response.data.statusMessage}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
 
  
}



const googleOtpSubmit=()=>{
  console.log("otp")
   const UserJWTAutgoogleOtpSumith=localStorage.getItem('UserJWTAuth')
   setOtpErrorMsg("Invalid OTP")
 if(otp.length==4){
   setOtpError(false)
  
   props.googleOtpSumit({
     url: `/auth/user/login`,
     method: "POST",
     headers: {
       Authorization: `Bearer ${UserJWTAuth}`,
      
       "x-app-platform": "web",
       "x-app-version": "v1.0.1",
     },
     "request": {
       "request": {
         "deviceId":localStorage.getItem('uuid'),
         "fcmUserToken":null,
         "mobileNo":number,
 
         otp:otp,
         "requestId":google_requestId,
         "sourcePlatform":"web"
       }
   
       
 
 
       },
       handleErrorgoogleOTp ,
     handleResponsegoogleOtp:(res)=>handleResponsegoogleOtp(res),
   });
 }
 else{
   setOtpError(true)
 }
 };
 const handleResponsegoogleOtp=(res)=>{
  console.log("handleResponsegoogleOtp",res.data.response,res.data.status,res.data.statusMessage)
  if(res&&res.data&&res.data.response){

  setGoogle_success_flag(true)
  setGoogle_OTP_Pop_flag(false)

  localStorage.setItem("UserJWTAuth",res.data.response.jwt)
  localStorage.setItem("referCode",res.data.response.referCode)
  localStorage.setItem("isUserLogged",res.data.response.isUserLogged)
  localStorage.setItem("isGuest",res.data.response.isGuest)
  localStorage.setItem("isTokenExpired",res.data.response.isTokenExpired)
  }
 if(res&&res.data&&res.data.status=="FAILURE"){

  setOtpError(true)

  setOtpErrorMsg(res&&res.data&&res.data.statusMessage)
 }

}

const handleErrorgoogleOTp=()=>{

}



const closeOTpPop=(e)=>{
  setGoogle_Pop_flag(true);
 
setGoogle_OTP_Pop_flag(false)
}








return(
    <Userprofile
    profileData={profileData}
    goToPaymentsOption={goToPaymentsOption}
    goToAddTag={goToAddTag}
    LogoutAllDevice={LogoutAllDevice}
    LogoutCurrentDevice={LogoutCurrentDevice}  
    setGoogle_Pop_flag={setGoogle_Pop_flag}

    google_Pop_flag={google_Pop_flag}
    closePop={closePop}
    handle_Number={handle_Number}
    googleAddnumber={googleAddnumber}
    number={number}
    setNumber={setNumber}
    setGoogle_OTP_Pop_flag={setGoogle_OTP_Pop_flag}
    google_OTP_Pop_flag={google_OTP_Pop_flag}
    closeOTpPop={closeOTpPop}
    numberError={numberError}
    googleOtpSubmit={googleOtpSubmit}
    setOtp={setOtp}
    otp={otp}
    otpError={otpError}
    setGoogle_success_flag={setGoogle_success_flag}
    google_success_flag={google_success_flag}
    otpErrorMsg={otpErrorMsg}
    numberErrorMsg={numberErrorMsg}
    />
)


}



function mapStateToProps( props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    fetchUserDetailsRequest,
    fetchLogInDeviceCountRequest,
    fetchLogoutCurrentDeviceRequest,
    fetchLogoutAllDeviceRequest,
    googleOtpSumit,
    googleAddNumber
   
  })(UserprofileContainer);
