import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchInventory, initiatedPaymentApi } from "./action";
import { sendComboCartADDRequest,sendComboCartREMOVERequest, } from "../VmInventory/action";
import { sendGoogleLoginRequest } from "../Login/action";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { googleAddNumber } from "../Orders/action";
import { googleOtpSumit } from "../Orders/action";
import ReactDOM from "react-dom";
import { fetchApplyCouponRequest } from "./action1";
import { mpChagePg, mpClick } from "../../Mixpanel";

import {
  sendSubmitOtpMycartRequest,
  fetchCreateOrderRequest,
  fetchRemoveFormCartMycartRequest,
  fetchAddToCartMycartRequest,
  fetchCouponRequest,
  sendUserLoginMycartRequest,
  fetchPaymentOptionMycartRequest,
  fetchPreCardMycartRequest,
  fetchApplyCopuonRequest,
  fetchInitiatedPaymentRequest,
  fetchCheckSodexoOrderStatusRequest,
  fetchCheckSodexoLinkRequest,
  fetchRemoveCouponRequest
} from "./action1";
import MyCart from "./component";

const ItemsCardContainer = (props) => {
  console.log("ItemsCardContainer", props);
  const Guest_clientId = localStorage.getItem("Guest_clientId");
  const machineId = localStorage.getItem("machineId");
  const PreCartVm = localStorage.getItem("PreCartVm");
  const [itemsList, setItemsList] = useState(null);
  const [itemsList1, setItemsList1] = useState(null);

  const [total_itemBook, setTotal_itemBook] = useState([]);
  const [count, setCount] = useState(0);
  const [cart, setCart] = useState([]);
  const [otpErrorMsg,setOtpErrorMsg]=useState(null)
  const [address, setAddress] = useState();
  const [apihit, setApihit] = useState(true);
  const [cartCount, setCartCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [cartFlag, setCartFlag] = useState(true);
  const [websocketRes, setWebsocketRes] = useState(null);
  const [orderid, setOrderId] = useState(null);
  const [couponList, setCouponList] = useState();
  const [couponAppled, setCouponAppled] = useState();
  const navigate = useNavigate();
  const [razorpayonce, setRazorpayonce] = useState(false);
  const [couponApply, setcouponApply] = useState(false);
  const [getCardfalg, setgetCardflag] = useState(true);
  const [paymentOptionsList, setPaymentOptionsList] = useState();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [precardVm, setPrecardVm] = useState(null);
  const [numberErrorMsg,setNumberErrorMsg]=useState('')
  const [clientId, setClientId] = useState("true");
  /////////////////////////////////////LOGIN///////////////////////////////////////////////
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [login, setlogin] = useState(true);
  const [loginOtp, setloginOtp] = useState(false);
  const [requestId, setRequestId] = useState(null);
  const [notnewUser, setNotNewuser] = useState("true");
  const [continueflag, setContinueflag] = useState(false);
  const [otpFlag, setOtpFlag] = useState(false);
  const [GuestLogin, setGuestLogin] = useState(true);

  const [comboCart,setComboCart]=useState(null)
  const [google_requestId,setGoogle_requestId]=useState(null);


  
const [google_OTP_Pop_flag,setGoogle_OTP_Pop_flag]=useState(false)
const [number,setNumber]=useState("")
const [g_otp,set_g_Otp]=useState("")
const [numberError,setNumberError]=useState(false)
const [otpError,setOtpError]=useState(false)
const [google_success_flag,setGoogle_success_flag]=useState(false);


  const [google_Pop_flag,setGoogle_Pop_flag]=useState(true);

  const cartCountTotal = cart.reduce((acc, item) => acc + item.quantity, 0);
  console.log("cartCount", cartCountTotal);
  let webdata;
  const OrderId = localStorage.getItem("OrderId");
  const [razorpayClick, setrazorpayClick] = useState(null);
  //const [finalAmount,setFinalAmount]=useState("")
  const [prevmId, setPrevmId] = useState();
  const [couponReward,setCouponReward]=useState(null)
  const totalAmount =
    itemsList &&
    itemsList.reduce(
      (accumulator, item) => accumulator + item.amount * item.count,
      0
    );
  const distotalAmount =
    itemsList &&
    itemsList.reduce(
      (accumulator, item) => accumulator + item.discountedAmount,
      0
    );

  let finalAmount;
  finalAmount = distotalAmount ? distotalAmount : totalAmount;
  finalAmount=finalAmount?finalAmount:comboCart&&comboCart.totalPrice

  useEffect(() => {
    const Guest_clientId = localStorage.getItem("Guest_clientId");
    const google_jwt = localStorage.getItem("google_jwt");
    
    if(google_jwt){
      setGoogle_Pop_flag(true);
      console.log("google_jwt",google_jwt,google_Pop_flag)
    }
    else{

      setGoogle_Pop_flag(false);
    }
    console.log("Guest_clientId useeff",Guest_clientId)
    if (Guest_clientId) {
      setClientId(false);
    
    } else {
      setClientId(true);
    }
  }, [GuestLogin,Guest_clientId]);

  const UserJWTAuth = localStorage.getItem("UserJWTAuth");


  useEffect(()=>{
localStorage.removeItem("move_cart_vmid")
  },[])
  useEffect(() => {
    //UserLoginApi()
    //SubmitOtpApi()
    //PaymentOptionApi()
  PreCard();
    //CreateOrderapi()
    //fetchCoupon()
    //ApplyCoupon()
    //addtoCart(7,34,4)
    //removefromCart()
  }, []);

  const GuestBack=()=>{
    setlogin(false)
    setGuestLogin(true);
  }

  const ComboCartADD=(e,count,cid)=>{


    //setLoader(true)
    let payloadcount=0;
    if(count>0){
      payloadcount=count+1

     }
  else{
  //payloadcount=comboList[i].mycount+1
       } 
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  
    props.sendComboCartADDRequest({
      url: `/consumerbe/api/v2/cart/combo?machineId=${precardVm}`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
       "x-device-id":localStorage.getItem("uuid")
      },
      request: 
        {

          "comboId":parseInt(cid),
	        "count":payloadcount
        
        },
      
      handleErrorComboCartADD:(error)=>handleErrorComboCartADD(error,cid),
      handleResponseComboCartADD: (res) => handleResponseComboCartADD(res,cid),
    });





  

    

  }

  const handleErrorComboCartADD=(error)=>{
    if(error&&error.response)
      {
        toast.error(`${error&&error.response&&error.response.data.status.message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
    
  
  }
  }
  
  const handleResponseComboCartADD=(res,cid)=>{
    setComboCart(res.data.data.comboDetails);
  
 
 
  //setComboId(cid)
  
  
  
  }

  const ComboCartRemove=(e,count,cid)=>{


  //setLoader(true)
  let payloadcount=0;
  if(count<0){
    navigate("/")
  }
  if(count>0){
    payloadcount=count-1

   }
else{

     } 
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");

  props.sendComboCartREMOVERequest({
    url: `/consumerbe/api/v2/cart/combo?machineId=${precardVm}`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      //HEADER ERROR
     "x-device-id":localStorage.getItem("uuid")
    },
    request: 
      {

        "comboId":parseInt(cid),
        "count":payloadcount
      
      },
    
    handleErrorComboCartRemove:(res)=>handleErrorComboCartRemove(res,cid),
    handleResponseComboCartRemove: (res) => handleResponseComboCartRemove(res,cid),


    }   

  )
}
/* console.log("combo id", count,id) */

const handleErrorComboCartRemove=(res,cid)=>{

}

const handleResponseComboCartRemove=(res,cid)=>{
  if(res.data.data.comboDetails==null){

    navigate(`/items?machineId=${machineId}`, { replace: true });
  }
  setComboCart(res.data.data.comboDetails);
}

  


  const goToPaymentOption=()=>{

    localStorage.setItem("paytmOptionFrom",window.location.pathname)
    mpChagePg({EventName:"Change_pg",data:{Current_default_gateway:localStorage.getItem("defaultPgName")}})
    navigate('/paymentOption')

  }

  const handleErrorUserLoginForMyCart = () => {};

  const handleResponseUserLoginForMyCart = (res) => {
    console.log(
      "handleResponseUserLogin",
      res.data.successful,
      res.data.successful == false
    );
    if (false == res.data.successful) {
      toast.error(`${res.data.statusMessage}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setLoader(false);
    }
    if (true == res.data.successful) {
      setRequestId(res.data.response.requestId);
      setNotNewuser(res.data.response.isValidUser);

      setlogin(false);
      setloginOtp(true);
      setLoader(false);
      console.log(
        "-----------------",
        res.data.response.requestId,
        res.data.response.isValidUser
      );
      setLoader(false);
    }
  };

  const UserLoginApi = () => {
    setLoader(true);
    props.sendUserLoginMycartRequest({
      url: `/auth/user/authenticate`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
        "x-device-id":localStorage.getItem("uuid")

      },
      request: {
        request: { mobileNo: mobile },
      },

      handleErrorUserLoginForMyCart,
      handleResponseUserLoginForMyCart: (res) =>
        handleResponseUserLoginForMyCart(res),
    });
  };

  const handleErrorSubmitOtpMyCart = () => {};

  const handleResponseSubmitOtpMyCart = (res) => {
 
    if (false == res.data.successful) {
      toast.error(`${res.data.statusMessage}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
    if (true == res.data.successful) {
      localStorage.setItem("UserJWTAuth", res.data.response.jwt);
      setlogin(false);
      setloginOtp(true);
      setLoader(false);
      setGuestLogin(true);
      setClientId(true)

      localStorage.removeItem("Guest_clientId");
     
      console.log("Guest_clientId remove PaymentOptionApi")
      PaymentOptionApi();
    }
  };


  const SubmitOtpApi = () => {
    const card_id = localStorage.getItem("card_id");
    console.log("SubmitOtpApi",card_id)

    if(notnewUser == "false") {
    props.sendSubmitOtpMycartRequest({
      url:"/auth/user/guest/promote",
      method: "POST",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
        "x-device-id":localStorage.getItem("uuid")
       
      },
      request: {
        "request": {
        
    "mobileNo":mobile,
    "otp": otp,
    "requestId": requestId,
    "sourcePlatform": "web",
    //"x-app-platform": "web",
    "deviceId": localStorage.getItem("uuid"),
        },
      },
     handleErrorSubmitOtpMyCart,
      handleResponseSubmitOtpMyCart,
    }); 
  }

    if(notnewUser == "true"){
  props.sendSubmitOtpMycartRequest({
      url: "/auth/user/login",
      method: "POST",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
        "x-device-id":localStorage.getItem("uuid"),
        "guest-cart-id": card_id,
      },
      request: {
        request: { //userkey: "userkey",
          //"x-app-platform": "web",
          //"x-app-version": "v1.0.1",
          mobileNo: mobile,
          requestId: requestId,
          otp: otp,
          fcmUserToken: null,
          "sourcePlatform": "web",
          //"x-app-platform": "web",
          "deviceId": localStorage.getItem("uuid"),
        },
      },
     handleErrorSubmitOtpMyCart,
      handleResponseSubmitOtpMyCart,
    }); 
  }







  };
useEffect(()=>{
PreCard()

},[UserJWTAuth])


  const handleErrorPaymentOption = () => {};

  const handleResponsePaymentOption = (res) => {
    localStorage.setItem("BpBalance", res.data.data[2].balance);
    setPaymentOptionsList(res.data.data);
  };

  const PaymentOptionApi = (machId) => {
    console.log("Guest_clientId remove PaymentOptionApi")
const UserJWTAuth=localStorage.getItem("UserJWTAuth")
const machineId=precardVm?precardVm:machId
   if(true){
    props.fetchPaymentOptionMycartRequest({
      url: `/consumerbe/api/v2/payment/option?machineId=${machineId}&balancePgArr=PAYTM%7CWALLET_LOYALTY%7CWALLET`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
        "x-device-id":localStorage.getItem("uuid")
        
      },

      handleErrorPaymentOption,
      handleResponsePaymentOption,
    });
   }
  };

  const handleErrorPreCardMyCart = (error) => {
    console.log("khan handl", error.response.data.status.code);
    if(error.response.status==401|| error.response.status==403){

      console.log("khan handleErrorVmInventory", error.response.data.status.code);
      //Logout
      //localStorage.removeItem("UserJWTAuth")
      navigate("/login");
  }
  else{ toast.error(`${error.response.data.status.code}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
  }

  };

  const handleResponsePreCardMyCart = (res, secondTime) => {
    console.log("handleResponsePreCardMyCart------------>", res);

    setPrecardVm(res.headers.machineid);
    setLoader(false);
    setPrevmId(res.headers.machineid);
   
    setItemsList(res.data.data.items);
  
    setComboCart(res.data.data.comboDetails)
    setCouponReward(res.data.data.reward)
    PaymentOptionApi(res.headers.machineid);
    localStorage.setItem("card_id", res.headers["cart-id"]);
    localStorage.setItem(" machineId",res.headers.machineid )

 
    if (secondTime == false) {
      if (!Guest_clientId) {
        setLoader(true);
        fetchCoupon();
      }
      //setLoader(true)
    }
  };

  const PreCard = (secondTime) => {
    setLoader(false);
    const card_id = localStorage.getItem("card_id");
    props.fetchPreCardMycartRequest({
      url: `/consumerbe/api/v2/cart`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,

        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      },
      request: {
       
      },

      handleErrorPreCardMyCart,
      handleResponsePreCardMyCart: (res) =>
        handleResponsePreCardMyCart(res, secondTime),
    });
  };

  const handleErrorFetchCouponMyCart = () => {};

  const handleResponseFetchCouponMyCart = (res) => {
    console.log("handleResponseFetchCouponMyCart", res.data.data);
    setLoader(false);
    setCouponList(res.data.data);
    setTimeout(() => {
      if (res.data.data && res.data.data[0] && res.data.data[0].couponName) {
        setLoader(true);
        console.log(
          "couponList[0].couponName ApplyCoupon hit",
          res.data.data[0].couponName
        );
        ApplyCoupon(res.data.data[0].couponName);
      }
    }, 1000);
  };

  const fetchCoupon = () => {
    const card_id = localStorage.getItem("card_id");
    const defaultPgName = localStorage.getItem("defaultPgName");
    const googel_jwt = localStorage.getItem("google_jwt");
    if (!Guest_clientId) {
      if(!googel_jwt){
      props.fetchCouponRequest({
        url: `/consumerbe/api/v2/coupon/auto-applicable`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,

          "x-app-platform": "web",
          "x-app-version": "v1.0.1",
          "x-device-id": localStorage.getItem("uuid"),
        },

        handleErrorFetchCouponMyCart:(error)=>handleErrorFetchCouponMyCart(error),
        handleResponseFetchCouponMyCart: (res) =>
          handleResponseFetchCouponMyCart(res),
      });
    }
    else{
      setLoader(false)
    }
  };
 
  
};

  const handleErrorApplyCoupon = () => {
    setLoader(false);
  };
  const handleResponseApplyCoupon = (res) => {
    setLoader(false);

    console.log("handleResponseApplyCoupon", res.data.data);
    setcouponApply(true);
    //setItemsList(res.data.data.items);

    PreCard(true);

    setgetCardflag(false)
    setCouponAppled(res.data.data);
  };

  const ApplyCoupon = (name) => {
    const card_id = localStorage.getItem("card_id");
    const defaultPgName = localStorage.getItem("defaultPgName");
    const defaultPgId = localStorage.getItem("defaultPgId");
    if (!Guest_clientId) {
      props.fetchApplyCopuonRequest({
        url: `/consumerbe/api/v2/cart/coupon`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,
          "x-app-platform": "web",
          "x-app-version": "v1.0.1",
          //HEADER ERROR
          "x-device-id":localStorage.getItem("uuid")
        },
        request: {
          couponName: name,
          pgName: "WALLET_LOYALTY",
        },

        handleErrorApplyCoupon,
        handleResponseApplyCoupon: (res) => handleResponseApplyCoupon(res),
      });
    }
  };

  const handleResponseAddToCartMyCart = (res, sId) => {
    setLoader(false);
    console.log("Get response addtoCart", res.data);
    setCartFlag(true);

    const updatedproducts = itemsList.map((p) => {
      console.log("handleAddToCart ppppppppp", sId, p.slot_id);
      if (p.slot_id === sId) {
        console.log("handleAddToCart mymy ", p);

        return { ...p, mycount: p.mycount + 1 };
      }
      return p;
    });
    setItemsList(updatedproducts);

    PreCard();
    if (!Guest_clientId) {
      fetchCoupon();
    } else {
      setLoader(false);
    }
  };

  const handleErrorAddToCartMyCart = (res) => {
    console.log("handleErrorAddToCartMyCart", res.response);
    toast.error(`${res && res.response.data.status.message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };

  const addtoCart = (e, i, sId) => {
    const card_id = localStorage.getItem("card_id");
    props.fetchAddToCartMycartRequest({
      url: `/consumerbe/api/v2/cart?machineId=${precardVm}`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,

        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      },
      request: [{ slotId: parseInt(sId), count: itemsList[i].count + 1 }],

      handleErrorAddToCartMyCart,
      handleResponseAddToCartMyCart: (res) =>
        handleResponseAddToCartMyCart(res, sId),
    });
  };

  const handleResponseRemoveFromCartMyCart = (res, sId) => {
    console.log("addtoCartGet response ", res.data);

    const updatedproducts = itemsList.map((p) => {
      console.log("handleAddToCart ppppppppp", sId, p.slot_id);
      if (p.slot_id === sId) {
        console.log("handleAddToCart mymy ", p);
        //setCartCount(cartCount-1)
        //setTotalAmount(totalamount-p.mrp)
        //setLoader(false)
        return { ...p, count: p.count - 1 };
      }
      return p;
    });
    setItemsList(updatedproducts);
    PreCard();

    fetchCoupon();
  };

  const handleErrorRemoveFromCartMyCart = (res) => {
    console.log("handleErrorRemoveFromCartMyCart", res);
  };
  const removefromCart = (e, i, sId) => {
    const card_id = localStorage.getItem("card_id");
    props.fetchRemoveFormCartMycartRequest({
      url: `/consumerbe/api/v2/cart?machineId=${precardVm}`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      },
      request: [{ slotId: parseInt(sId), count: itemsList[i].count - 1 }],

      handleErrorRemoveFromCartMyCart,
      handleResponseRemoveFromCartMyCart: (res) =>
        handleResponseRemoveFromCartMyCart(res, sId),
    });
  };

  const handleResponseCreateOrder = (res) => {
    console.log("Get response inventory", res.data.data.orderId);
    setOrderId(res.data.data.orderId);
    localStorage.setItem("orderId", res.data.data.orderId);
    InitiatedPaymentapi(res.data.data.orderId);
    Webwalasocket(res.data.data.orderId);
  };

  const handleErrorCreateOrder = (res) => {
    toast.error(`${res.response.data.status.message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };
  const CreateOrderapi = () => {
    console.log("khan CreateOrderapi",CreateOrderapi)
    props.fetchCreateOrderRequest({
      url: `/consumerbe/api/v2/outlet/${precardVm}/order`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,

        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
        "x-device-id":localStorage.getItem("uuid")
      },
      request: {
        type: "regular",
      },

      handleErrorCreateOrder,
      handleResponseCreateOrder: (res) => handleResponseCreateOrder(res),
    });
  };

  const handleResponseInitiatedPayment = (res) => {

    console.log("Tahzeebkhan PAY ment Init ",res.data.data.paytmTransactionToken)
  /*   onScriptLoad(res.data.data.paytmTransactionToken) */
    const defaultPgName = localStorage.getItem("defaultPgName");
    mpClick({EventName:"Checkout_Initiated"})

     if ("SODEXO" == defaultPgName) {
      console.log(
        "Get response initPaymentApi",
        res.data.data.sodexoRedirectOptions &&
          res.data.data.sodexoRedirectOptions.redirectUrl
      );

      localStorage.setItem(
        "SodexoPaymentRequestId",
        res.data.data.sodexoRedirectOptions.requestId
      );
      window.location.replace(res.data.data.sodexoRedirectOptions.redirectUrl);

      Webwalasocket(orderid);
    }




    if ("WALLET" == defaultPgName) {
      setrazorpayClick(null); 
      console.log(
        "Get response initPaymentApi",
        res.data.data.sodexoRedirectOptions &&
          res.data.data.sodexoRedirectOptions.redirectUrl
      );
      Webwalasocket(res.data.data.orderId);
      //
      Webwalasocket(orderid);

      setTimeout(() => {
        console.log(
          "Webwalasocket data -------------------------websocketRes ",
          websocketRes,
          webdata
        );
        localStorage.setItem("OrderId", orderid);
        setLoader(true);
        if (webdata) {
          if (defaultPgName == "SODEXO") {
            navigate("/order/status/sodexo", { replace: true });
          } else {
            //navigate('/order/status')
          }

          localStorage.setItem("OrderIdSuccess", true);
          //setLoader(false)
        } else {
          setLoader(true);
          localStorage.setItem("OrderIdSuccess", false);
          if (defaultPgName == "SODEXO") {
            navigate("/order/status/sodexo", { replace: true });
          } else {
            //navigate('/order/status')
          }
        }
      }, 3000);
    }


    if ("WALLET_LOYALTY" == defaultPgName) {
      setrazorpayClick(null); 
      console.log(
        "Get response initPaymentApi",
        res.data.data.sodexoRedirectOptions &&
          res.data.data.sodexoRedirectOptions.redirectUrl
      );
      Webwalasocket(res.data.data.orderId);

      Webwalasocket(orderid);

      Webwalasocket(res.data.data.orderId);
    }



    if (
      "R_CARD" == defaultPgName ||
      "R_WALLET" == defaultPgName ||
      "R_UPI" == defaultPgName
    ) {
      console.log("Get response initPaymentApi", res, OrderId);
      razorpay();
      setRazorpayonce(true);

      Webwalasocket(orderid);
    }





    if ("PAYTM" == defaultPgName) {
      console.log(
        "Get response initPaymentApi",
        res.data.data.sodexoRedirectOptions &&
          res.data.data.sodexoRedirectOptions.redirectUrl
      );
      //window.location.replace(response.data.data.sodexoRedirectOptions.redirectUrl)
      Webwalasocket(res.data.data.orderId);
      //
      Webwalasocket(orderid);

      setTimeout(() => {
        console.log(
          "Webwalasocket data -------------------------websocketRes ",
          websocketRes,
          webdata
        );
        localStorage.setItem("OrderId", orderid);
        setLoader(true);
        if (webdata) {
          //localStorage.setItem('OrderId',orderid);
          if (defaultPgName == "SODEXO") {
            navigate("/order/status/sodexo", { replace: true });
          } else {
            //navigate('/order/status')
          }

          localStorage.setItem("OrderIdSuccess", true);
          //setLoader(false)
        } else {
          setLoader(true);
          localStorage.setItem("OrderIdSuccess", false);
          if (defaultPgName == "SODEXO") {
            navigate("/order/status/sodexo", { replace: true });
          } else {
            //navigate('/order/status')
          }
        }
      }, 3000);
    }


 

  };

  const handleErrorInitiatedPayment = (res) => {
    toast.error(`${res.data.status.message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
    setLoader(false);
    navigate(`/items?machineId=${machineId}`, { replace: true });
  };
  const InitiatedPaymentapi = () => {
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
    const defaultPgId = localStorage.getItem("defaultPgId");
    const defaultPgName = localStorage.getItem("defaultPgName");
    const OrderId = localStorage.getItem("orderId");

    props.fetchInitiatedPaymentRequest({
      url: `/consumerbe/api/v2/order/${OrderId}/payment`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,

        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
        "x-device-id":localStorage.getItem("uuid")
      },
      request: {
        pgId: defaultPgId,
      },

      
      handleErrorInitiatedPayment: (res) =>
      handleErrorInitiatedPayment(res),
      handleResponseInitiatedPayment: (res) =>
        handleResponseInitiatedPayment(res),
    });
  };



  const onchangeOtp = (e) => {
    setOtp(e.target.value);
    if (otp.length >= 3) {
      setOtpFlag(true);
    } else {
      setOtpFlag(false);
    }
  };

  const onPasteOtp = (e) => {
    const pasteOtp = e.clipboardData.getData("text");
    console.log("onchangeOtp", e.clipboardData.getData("text"));
    setOtp(e.target.value);
    if (pasteOtp.length == 3) {
      setOtpFlag(true);
    } else {
      setOtpFlag(false);
    }
  };

  const onchangeMobile = (e) => {
    setMobile(e.target.value);
    if (mobile.length == 9) {
      setContinueflag(true);
    } else {
      setContinueflag(false);
    }
  };

  useEffect(() => {
    let intervalId;
    const ws = new WebSocket(
      `wss://api-prod.daalchini.co.in/consumerbe/status/payment?${
        OrderId && OrderId
      }`
    );

    ws.onopen = () => {
      console.log("Connected to WebSocket server");
      intervalId = setInterval(() => {
        ws.send("Hello, server!");
      }, 2000);
    };

    ws.onmessage = (event) => {
      console.log(`Received message: ${event.data}`);
    };

    ws.onclose = () => {
      console.log("Disconnected from WebSocket server");
    };

    return () => {
      clearInterval(intervalId);
      ws.close();
    };

    setDiscountAmount(distotalAmount);
  }, []);

  useEffect(() => {
    window.onpopstate = (e) => {
      console.log("onpopstate", e);
    };

    const handleBackButton = () => {
      console.log("HELLO tahzevv");
      //navigate("/");
      // Handle the back button action here
      // You can perform custom logic or navigate to a specific route
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  useEffect(() => {
    const handleBlockedNavigation = (event) => {
      event.preventDefault();
      console.log("back button");
     // navigate(`/items?machineId=${8}`);
      // You can display a custom error message or perform any other action here
    };

    const blockNavigation = () => {
      //window.history.pushState(null, '', "/");
      window.addEventListener("popstate", handleBlockedNavigation);
    };

    blockNavigation();

    return () => {
      window.removeEventListener("popstate", handleBlockedNavigation);
    };
  }, []);

  useEffect(() => {
    //setLoader(true)
    //fetchCoupon();
    setLoader(true);
    PreCard(false)
    checksodexoLink();
  }, []);

const handleResponseCheckSodexoLink=(response)=>{
  console.log("Get response inventory", response.data);

  //setPaymentOptionsList(response.data.data);
  //setAddress("tahzeev")
  localStorage.removeItem("SodexoRequestId");
}


const handleErrorCheckSodexoLink=()=>{

}

  const checksodexoLink = () => {
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
    const SodexoRequestId = localStorage.getItem("SodexoRequestId");
    if(SodexoRequestId){
    props.fetchCheckSodexoLinkRequest({
      url: `/payment/api/v2/sodexo/check/link/${SodexoRequestId}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
        "x-device-id":localStorage.getItem("uuid")
      },


      handleErrorCheckSodexoLink:(res)=>handleErrorCheckSodexoLink(res),
      handleResponseCheckSodexoLink: (res) =>
      handleResponseCheckSodexoLink(res),
    });
  }
  };


  const handleResponseCheckSodexoOrder=(response)=>{
    console.log("Get response CheckSodexoOrderStatus", response);
    localStorage.setItem(
      "sodexoPaymentStatus",
      JSON.stringify(response.data)
    );

    navigate("/order/status/sodexo", { replace: true });
  }


const handleErrorCheckSodexoOrder=(err)=>{
  console.log("initiatedPaymentApi err", err);
  toast.error(`${err.response.data.status.message}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
}

  const CheckSodexoOrderStatus = () => {
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
    const OrderId = localStorage.getItem("orderId");


    props.fetchCheckSodexoOrderStatusRequest({
      url:  `/payment/api/v2/sodexo/payment/status/${OrderId}`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
        "x-device-id":localStorage.getItem("uuid")
      },


      handleErrorCheckSodexoOrder,
      handleResponseCheckSodexoOrder: (res) =>
      handleResponseCheckSodexoOrder(res),
    });
  
  };




 

  useEffect(() => {
    console.log("CONSOLE NUMBER ->> 3");
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  //https://api-prod.daalchini.co.in

  const handlePaymentError = (error) => {
    console.log("Payment failed:", error);
    navigate(`/items?machineId=${machineId}`);
    // Implement any additional logic for handling payment failure here
  };

  const handlePaymentSuccess = (response) => {
    Webwalasocket(OrderId);
    console.log("Payment successful:", response);
    // Implement any additional logic for handling payment success here
  };

  const razorpayMethod=localStorage.getItem("defaultPgName")=="R_CARD"?"card":localStorage.getItem("defaultPgName")=="R_WALLET"?"wallet":"UPI"


  const razorpay = () => {
    setrazorpayClick(true);
    const payAmount = finalAmount * 100
    console.log("razorpay response no");
  
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
    const defaultPgName = localStorage.getItem(" defaultPgName ");
    const OrderId = localStorage.getItem("orderId");
    console.log("razorpay OrderId", OrderId);
    const options = {
      key: "rzp_live_ZVpG4pXQKTLFCS",
      amount: `${payAmount}`,
      description: `OrderID:-${OrderId}`,
      //image: logo,
      handler: function (response) {
        console.log("razorpay response", response, response);
        let userKey;
        userKey = UserJWTAuth;
        handlePaymentSuccess(response);

        const payload = {
          header: {
            oauthToken: UserJWTAuth,
            userkey: userKey,
          },
          data: {
            request: {
              orderId: OrderId,
              //paymentGateway: orderData.paymentGateway,
              paymentId: 9,
              paymentStatus: 9 ? "SUCESS" : "FAILED",
            },
            onClose: function () {
              alert("Payment modal closed!");

              document.getElementById("razorpay-container").remove();
            },

            /*   bottomText === "OTHER"
            ? "upi"
            : bottomText === "Wallet"
            ? "wallet"
            : "card", */
          },
        };
      },

      prefill: {
        email: "info@daalchini.co.in",
        contact:
          JSON.parse(localStorage.getItem("mobileNo")) &&
          JSON.parse(localStorage.getItem("mobileNo")),
        method: razorpayMethod,

        /*   ? "wallet"
          : "card", */
      },

      theme: {
        color: "#432B2B",
        hide_topbar: false,
      },
      notes: {
        order_id: OrderId,
      },
      onpaymenterror: function (error) {
        handlePaymentError(error);
      },
      modal: {
        ondismiss: function (error) {
          console.log("error", error);
          handlePaymentError(error);
        },
      },
    };

    var rzp1 = new window.Razorpay(options);
    setLoader(false);
    rzp1.open();
  };

 

  const Webwalasocket = (orderid) => {
    setLoader(true);
    const OrderId = localStorage.getItem("orderId");
    console.log("Webwalasocket data", OrderId);
    const ws = new WebSocket(
      `wss://api-prod.daalchini.co.in/consumerbe/status/payment?order_id=${OrderId}`
    );

    console.log("next Linew  WWWWWWWWWWWWWWWWWWWWWWWww", ws);
    ws.onmessage = (event) => {
      console.log("Webwalasocket data", event);
      const data = JSON.parse(event.data);
      console.log("Webwalasocket data", data);
      setWebsocketRes(data);
      localStorage.setItem("websocketRes", JSON.stringify(data));
      navigate("/order/status", { replace: true });
      /*   setTimeout(() => {
      if(data){
        //setLoader(true)
      //navigate('/order/status')
      }
      
    }, 2000); */

      webdata = data;
      const currentPrice = data.orderId;

      //console.log({ price, oldPrice });
    };

    console.log("next Linew");
  };

  useEffect(() => {
    console.log("razorpayonce", razorpayonce);
    const OrderId = localStorage.getItem("orderId");
    const SodexoPaymentRequestId = localStorage.getItem("SodexoPaymentRequestId");
    if (SodexoPaymentRequestId) {
      CheckSodexoOrderStatus();
      //Webwalasocket(OrderId)
    }
  }, []);

  const RazorpayStatus = () => {
    console.log("WALLET_LOYALTYWALLET_LOYALTY  RaZOR");
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
    const defaultPgId = localStorage.getItem("defaultPgId");
    const defaultPgName = localStorage.getItem("defaultPgName");
    const OrderId = localStorage.getItem("OrderId");
  };

  const LoginFLagFun = (e) => {
    e.preventDefault();
    setlogin(true)
    setGuestLogin(false);
  };
  const RemoveCoupon = () => {
    const card_id = localStorage.getItem("card_id");
    const defaultPgName = localStorage.getItem("defaultPgName");
    const defaultPgId = localStorage.getItem("defaultPgId");
    if (!Guest_clientId) {
      props.fetchRemoveCouponRequest({
        url: `/consumerbe/api/v2/cart/coupon`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${UserJWTAuth}`,

          "x-app-platform": "web",
          "x-app-version": "v1.0.1",
          "x-device-id": localStorage.getItem("uuid"),
        },

        handleErrorRemoveCoupon:(error)=>handleErrorRemoveCoupon(error),
        handleResponseRemoveCoupon: (res) =>
          handleResponseRemoveCoupon(res),
      });
    }
  };

  const handleResponseRemoveCoupon = (res) => {
    navigate("/coupon")
    };

    const handleErrorRemoveCoupon = (error) => {
      console.log("handleErrorRemoveCoupon",error.response.data.status.message)
      toast.error(`${error.response.data.status.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
  
  
    };

    console.log("combo MyCartList",comboCart)
 const closePop=(e)=>{
  setGoogle_Pop_flag(false);
  console.log("localStorage.getItem(",localStorage.getItem("machineId"))
  if(!(localStorage.getItem("machineId")==null)){
  navigate(`/items?machineId=${localStorage.getItem("machineId")}`)
  }
  else{
    navigate('/')
  }
}


    const handle_Number = (e) => {
      e.preventDefault(); // Prevent default action (if needed)
      console.log("number", e.target.value);
      setNumber(e.target.value); // Update the state with the new value
    };

    const googleOtpSubmit=()=>{
     
      const card_id = localStorage.getItem("card_id");
      console.log("otp")
       const UserJWTAutgoogleOtpSumith=localStorage.getItem('UserJWTAuth')
       setOtpErrorMsg("Invalid OTP")
     if(otp.length==4){
       setOtpError(false)
      
       props.googleOtpSumit({
         url: `/auth/user/login`,
         method: "POST",
         headers: {
           Authorization: `Bearer ${UserJWTAuth}`,
           
          "guest-cart-id": card_id,
           "x-app-platform": "web",
           "x-app-version": "v1.0.1",
         },
         "request": {
           "request": {
             "deviceId":localStorage.getItem('uuid'),
             "fcmUserToken":null,
             "mobileNo":number,
     
             otp:otp,
             "requestId":google_requestId,
             "sourcePlatform":"web"
           }
       
           
     
     
           },
           handleErrorgoogleOTp ,
         handleResponsegoogleOtp:(res)=>handleResponsegoogleOtp(res),
       });
     }
     else{
       setOtpError(true)
     }
     };
 

     
     const handleResponsegoogleOtp=(res)=>{
      console.log("handleResponsegoogleOtp",res.data.response,res.data.status,res.data.statusMessage)
      if(res&&res.data&&res.data.response){
    
      setGoogle_success_flag(true)
      setGoogle_OTP_Pop_flag(false)
    
      localStorage.setItem("UserJWTAuth",res.data.response.jwt)
      localStorage.setItem("referCode",res.data.response.referCode)
      localStorage.setItem("isUserLogged",res.data.response.isUserLogged)
      localStorage.setItem("isGuest",res.data.response.isGuest)
      localStorage.setItem("isTokenExpired",res.data.response.isTokenExpired)
      }
     if(res&&res.data&&res.data.status=="FAILURE"){
    
      setOtpError(true)
    
      setOtpErrorMsg(res&&res.data&&res.data.statusMessage)
     }
    
    }
    
    const handleErrorgoogleOTp=()=>{
    
    }
    
 
 
 const closeOTpPop=(e)=>{
   setGoogle_Pop_flag(true);
  
 setGoogle_OTP_Pop_flag(false)
 }

 const googleAddnumber=()=>{console.log("RESEND")

 
  const UserJWTAuth=localStorage.getItem('UserJWTAuth')
if(number.length==10){
setNumberError(false)
  props.googleAddNumber({
    url: `/auth/user/authenticate`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
     
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
    },
    "request": {
      "request": {
"mobileNo": number,
"googleId":localStorage.getItem("gmail")
      }
  
      


      },
    handleErrorgoogleAddnumber ,
    handleResponsegoogleAddnumber:(res)=>handleResponsegoogleAddnumber(res),
  });
}
else{
  setNumberError(true)
  setNumberErrorMsg("Enter a valid mobile number")
}
};


const handleResponsegoogleAddnumber=(res)=>{
  console.log("setGoogle_requestId",res.data.response.requestId)
  setGoogle_requestId(res.data.response.requestId)
   setGoogle_Pop_flag(false)
   setGoogle_OTP_Pop_flag(true)
   
  
 
 }

 const handleErrorgoogleAddnumber=(err)=>{
  console.log("handleErrorgoogleAddnumber",err.response.data.statusMessage)
  toast.error(`${err.response.data.statusMessage}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
  setNumberError(true)
  setNumberErrorMsg(err.response.data.statusMessage)
  
}


const responseGoogle = (response) => {
  console.log("khan responseGoogle mycart")
  //setLoader(true)
  if (response.error) {
    console.error("Google login failed:", response.error);
    // Optional: Show a user-friendly message
   // alert("Login process was not completed. Please try again.");
  } else {
    console.log("Google login success:", response);
 
    GoogleLoginApi(response.tokenId)
    localStorage.setItem("gmail",response.profileObj.email)

    // Proceed with your application's login logic
  }
};
const GoogleLoginApi=(tokenId)=>{
  console.log("-------------------------------------------------------------------------------------")

  const card_id = localStorage.getItem("card_id");
  setLoader(true)
   props.sendGoogleLoginRequest({
    url: `/auth/user/google/login`,
    method: "POST",
    headers: {
      //Authorization: `Bearer ${UserJWTAuth}`,
    
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
      "guest-cart-id": card_id,
      "x-device-id":localStorage.getItem("uuid")
    },
   
     "request":{"googleIdToken":tokenId}
          
      ,
      
    

    
    handleErrorGoogleLoginApi ,
    handleResponseGoogleLoginApi:(res)=>handleResponseGoogleLoginApi(res) ,
  });
};


const handleResponseGoogleLoginApi=(res)=>{

  console.log("res jwt",res.data&&res.data.response)

  console.log("-------------------------------------------------------------------------------------")

  localStorage.setItem("google_jwt",res.data&&res.data.response&&res.data.response.jwt)
  localStorage.setItem("UserJWTAuth",res.data&&res.data.response&&res.data.response.jwt)
  localStorage.setItem("isGuest",res.data&&res.data.response&&res.data.response.isGuest)
  localStorage.removeItem("Guest_clientId")


  navigate("/")
  navigate("/myCart")

}


const handleErrorGoogleLoginApi=()=>{

}





  return (
 
    <MyCart
      loader={loader}
      itemsList={itemsList}
      increaseQuantity={addtoCart}
      decreaseQuantity={removefromCart}
      total_itemBook={total_itemBook}
      address={address}
      cartCount={cartCount}
      CreateOrderapi={CreateOrderapi}
      totalAmount={totalAmount}
      couponList={couponList}
      distotalAmount={distotalAmount}
      couponApply={couponApply}
      couponAppled={couponAppled}
      precardVm={precardVm}
      paymentOptionsList={paymentOptionsList}
      prevmId={prevmId}
      LoginApi={UserLoginApi}
      setMobile={setMobile}
      login={login}
      loginOtp={loginOtp}
      setOtp={setOtp}
      SubmitOTP={SubmitOtpApi}
      onchangeMobile={onchangeMobile}
      mobile={mobile}
      otpFlag={otpFlag}
      continueflag={continueflag}
      onchangeOtp={onchangeOtp}
      onPasteOtp={onPasteOtp}
      otp={otp}
      GuestLogin={GuestLogin}
      LoginFLagFun={LoginFLagFun}
      goToPaymentOption={goToPaymentOption}
      clientId={clientId}
      setlogin={setlogin}
      GuestBack={GuestBack}
      RemoveCoupon={RemoveCoupon}
      couponReward={couponReward}
      comboCart={comboCart}
      ComboCartRemove={ComboCartRemove}
      ComboCartADD={ComboCartADD}
      setGoogle_Pop_flag={setGoogle_Pop_flag}
      google_Pop_flag={google_Pop_flag}
      closeOTpPop={closeOTpPop}
      
      closePop={closePop}
      handle_Number={handle_Number}
      googleAddnumber={googleAddnumber}
      number={number}
      setNumber={setNumber}
      google_OTP_Pop_flag={google_OTP_Pop_flag}
      googleOtpSubmit={googleOtpSubmit}
      otpError={otpError}
      numberError={numberError}
      google_success_flag={google_success_flag}
      otpErrorMsg={otpErrorMsg}
      numberErrorMsg={numberErrorMsg}
      handleResponseGoogleLoginApi={handleResponseGoogleLoginApi}
      responseGoogle={responseGoogle}


      
    />
  );
};

function mapStateToProps(props) {
  return {
    props,
  };
}
export default connect(mapStateToProps, {
  fetchInventory,
  initiatedPaymentApi,
  //loginuser,

  sendSubmitOtpMycartRequest,
  sendUserLoginMycartRequest,
  fetchPaymentOptionMycartRequest,
  fetchPreCardMycartRequest,
  fetchCouponRequest,
  fetchApplyCouponRequest,
  fetchAddToCartMycartRequest,
  fetchRemoveFormCartMycartRequest,
  fetchCreateOrderRequest,
  fetchInitiatedPaymentRequest,
  fetchCheckSodexoOrderStatusRequest,
  fetchCheckSodexoLinkRequest,
  fetchRemoveCouponRequest,
  sendComboCartADDRequest,
  sendComboCartREMOVERequest,
  googleAddNumber,
  googleOtpSumit,
  sendGoogleLoginRequest
})(ItemsCardContainer);
