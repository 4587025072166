import {   GOOGLE_LOGIN_REQUEST_SUCCESS } from "./action";


const initialState = {
    //////////////VM LIST VARIABLES///////////////
     
mobileNumber:null,
googleLoginRequestId:null
     
}    



const GoogleLoginReducer = (state = initialState, action) => {
    console.log("tahzeeb GoogleLoginReducer",action)
    switch (action.type) {
//////////////////////SWITCH CASES FOR ACTIVE ORDERS //////////////////////////////////////


  case GOOGLE_LOGIN_REQUEST_SUCCESS: 
    return {
      ...state,
      
      mobileNumber: action&&action
    
    };


  
  
  
  
  
    default:
      return state;

  }
};

export default GoogleLoginReducer;
