import Google_Popup_Number from "./component"
import { googleAddNumber } from "./action";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
const GooglePopupSumbitOtpContainer=({googleAddNumber,setGoogle_Pop_flag,GoogleLoginReducer,props})=>{
  console.log("GoogleLoginReducer props",props&&props.GoogleLoginReducer&&props.GoogleLoginReducer.mobileNumber&&props&&props.GoogleLoginReducer&&props.GoogleLoginReducer.mobileNumber.mobileNumber
  )  

  console.log("GoogleLoginReducer props",props&&props.GoogleLoginReducer&&props.GoogleLoginReducer.mobileNumber&&props&&props.GoogleLoginReducer&&props.GoogleLoginReducer.mobileNumber.GLoginRes&&props.GoogleLoginReducer.mobileNumber.GLoginRes.data&&props.GoogleLoginReducer.mobileNumber.GLoginRes&&props.GoogleLoginReducer.mobileNumber.GLoginRes.data.response&&props.GoogleLoginReducer.mobileNumber.GLoginRes&&props.GoogleLoginReducer.mobileNumber.GLoginRes.data.response.requestId



  )  
    const navigate=useNavigate()
    const [number,setNumber]=useState(null)
    const [numberError,setNumberError]=useState(false)
    const closePop=(e)=>{
        setGoogle_Pop_flag(false);
        console.log("localStorage.getItem(",localStorage.getItem("machineId"))
        if(!(localStorage.getItem("machineId")==null)){
        navigate(`/items?machineId=${localStorage.getItem("machineId")}`)
        }
        else{
          navigate('/')
        }
    }

    const handleNumber=(e)=>{

        console.log("number",e.target.value)
        setNumber(e.target.value)
    }

    const googleAddnumber=()=>{
       
        const UserJWTAuth=localStorage.getItem('UserJWTAuth')
if(number.length==10){
    setNumberError(false)
        googleAddNumber({
          url: `/auth/user/authenticate`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${UserJWTAuth}`,
           
            "x-app-platform": "web",
            "x-app-version": "v1.0.1",
          },
          "request": {
            "request": {
		"mobileNo": number,
		"googleId":null
            }
        
            
      
      
            },
          handleErrorgoogleAddnumber ,
          handleResponsegoogleAddnumber:(res)=>handleResponsegoogleAddnumber(res),
        });
    }
    else{
        setNumberError(true)
    }
      };

      const handleResponsegoogleAddnumber=(res)=>{
        

      }

      const handleErrorgoogleAddnumber=()=>{

      }
      

    return(
    
    
    <>
    <Google_Popup_Number 
    setGoogle_Pop_flag={setGoogle_Pop_flag}
     handleNumber={handleNumber}
     closePop={closePop} 
     numberError={numberError}
     googleAddnumber={googleAddnumber}
     />
    
    
    </>)
}


function mapStateToProps( props ) {
    return {
      props,
    };
  }
  export default connect(mapStateToProps, {
    googleAddNumber
    
  })(GooglePopupSumbitOtpContainer);
  