import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { POST_REFER_ERAN_REQUEST } from "./action";
///////////////////////////////////// API CALL ONLY ///////////////////////////////////////////
const callApi = async (mydata) => {
    console.log("callApi", mydata.data);
  
    const url = `https://api-prod.daalchini.co.in${mydata.data.url}`;
    try {
      const response = await axios({
        method: mydata.data.method,
        url: url,
        headers: mydata.data.headers,
        data: mydata.data.request
        
      });
  
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////////
  







  /////////////////////////////////// ACTIVE ORDER DATA////////////////////////////////////////////////////

  function* applyRefercode(action) {
    console.log("refer code saga", action);
    try {
      const response = yield call(callApi, action);
  
      //yield put(fetchVerifyPaytmOtpSuccess());
  
      action.data.handleResponseApplyReferCode(response);
    } catch (error) {
      console.log("fetchPreCard error", error.message);
    action.data.handleErrorApplyReferCode(error)
      //yield put(fetchVerifyPaytmOtpFailure(error.message));
    }
  }




  function* ReferCodeSaga() {
    yield takeLatest(POST_REFER_ERAN_REQUEST,applyRefercode)
    
  }
  
  export default ReferCodeSaga;