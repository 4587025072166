import { Logo_header } from "../Header/Logo_header";
import QrButtonLogo from "../../assets/qr_button_logo.png";
import "./vmlist.css";
import {
  AppBar, Box, Button,CardMedia,Skeleton, Card,CardContent, Typography, CssBaseline, CircularProgress,TextField,InputAdornment
} from '@mui/material';
import VmImgnotfound from '../../assets/vmImgnotfound.png'
import BottomNarBar from "../BottomNav/Bottom";
import Banner_Container from "../ImagesAds/container";
import { useEffect, useState } from "react";
import PopupModal from "../LocationModal/LocationModal";
import looocat from "../../assets/looocat.png";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import LocationBanner from "../../assets/location_banner.png";
import { toast } from "react-toastify";
import HeaderSecondary from "../Header/HeaderSecondary";
import Webcam from "react-webcam";
import WaitingLoder from "../WaitingLoader/component"
import QrReader from "react-web-qr-reader";
//import { QrReader } from "react-qr-reader";
import { Header_with_Qr } from "../Header/Logo_header";
import Procced from "../Procced/component";
import ActiveOrdersBar from "../ActiveOrdersBar/component";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MixPnl, { mpClick } from "../../Mixpanel";
import Banners from '../Banners/container'
import { Search, Tune } from "@mui/icons-material";
import TopNavbar from "../header";
import LocationPage from "../VmList1/LocationPage/component";
import RecentVms from "../VmList1/RecentVm's/component";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import ActiveOrdersFab from "../_ActiveOrderFab/component";
import MilestonePagination from "../Game/game1";
const Vmlist = (props) => {
  const navigate=useNavigate()
  const [cameraFacingMode, setCameraFacingMode] = useState('environment'); // 'user' for front camera, 'environment' for rear camera
  const videoConstraints = {
    facingMode: 'environment',  // This sets the camera to use the back camera
  };
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [searchText,setSearchText]=useState('')
  const globalSearchflag = (e) => {
    e.preventDefault();

    navigate("/globalsearch");
  };
  console.log("props&&props.ActiveOrderItems",props&&props.ActiveOrderItems)
  //////////////////////////////////
  const [qrData, setQRData] = useState(null);
  const [showScanner, setShowScanner] = useState(true);
  const [cameraError, setCameraError] = useState(false);
  const [isFetchLocation, setFetchLocation] = useState(true);
  const [locationModalFLag, setLocationModalFLag] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const LocatFun = () => {
    props.getLocation4Modal();
    setLocationModalFLag(false);
  };

  const handleClear=()=>{
    setSearchText('')
  }
  

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstallClick = (e) => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  function TruncatedText({ text, limit,color,size }) {
    if (text.length <= limit) {
      return <span style={{ fontSize: size?size:"", color:color?color:"" }}>{text}</span>;
    }
    return (
      <span style={{ fontSize:size?size:"", color:color?color:"" }}>{`${text&&text.substring(
        0,
        limit
      )}`}{" "}...</span>
    );
  }

  function handleScan(result) {
    console.log("contactless",result)
      if (result) {
        // Do something with the result
        console.log("PickupOrderContainer---------->",result);
  
        const machineIdIndex = result&&result.data.indexOf("machineId=");
  
        if (machineIdIndex !== -1) {
          const machineId = result.data.substring(machineIdIndex + 10);
          console.log("======================================",machineId); 
          navigate(`items?machineId=${machineId}`)
         
          } else {
         
            toast.error(`${"Machine ID Not Found"}`, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
          });
       
        }
  
        
        
      }
    }


  const handleError = (error) => {
    console.log("Camera Error", error);
    toast.error(`${error}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
    setCameraError(true);
    props.setShow_Qr_Modal(true);
  };
  const VmsClick=(e,id, name, address,source)=>{

    console.log("VmsClick")
  //mpClick({EventName:"vm_page_opened",data:{source:"source",vmid:id}})
    navigate(`items?machineId=${id}`)
    localStorage.setItem("vmName", name);
    localStorage.setItem("vmAddress", address);
    
  
  }

  const apiUrl = process.env;
  

  function TruncatedVMnameHeading({ text, limit }) {
    if (text && text.length <= limit) {
      return <>{text}</>;
    }
    return <>{`${text.substring(0, limit)}...`}</>;
  }

  function TruncatedVMAddressHeading({ text, limit }) {
    if (text && text.length <= limit) {
      return <>{text}</>;
    }
    return <>{`${text.substring(0, limit)}...`}</>;
  }

  console.log("apiUrl", apiUrl);
  const renderBoxes = () => {
    
  
       
        return (
          <>
          

           <Box marginTop={"20px"}>
 {/*           <div className="modal-overlay">
              <div className=".modal-content ">
              <button onClick={(e)=>{handleInstallClick(e)}}
              >
      Install App
    </button>
              </div>
     
    
     
    </div> */}
 <Typography color={"rgba(113, 129, 152, 1)"}  fontSize={"17px"} fontWeight={"500"} marginTop={"5%"}>Nearby by Vending Machine</Typography>
    {(props&&props.vmListSkeleton)?
    <Box marginTop={"14px"}>
      {[...Array(10)].map((_, index) => (
        <Card key={index} sx={{ width: '100%', borderRadius: '16px', marginBottom: '16px' ,boxShadow:"0px 2px 21px 0px rgba(0, 0, 0, 0.08) " }}>
          <div >
            <div style={{display:"flex"}}>
            <div style={{ width:"30%",padding:"2%",    }}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={80}
             
              sx={{ borderRadius:"10px", objectFit: 'scale-down', padding: '2%' }}
            />
            </div>
 
         
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5%' }}>
              <Box>
                <Skeleton variant="text" width={150} height={20} marginBottom={2} />
                <Skeleton variant="text" width={100} height={20} />
              </Box>
            </Box>
          </div>
          </div> 
        </Card>
      ))}
    </Box>:<Box marginTop={"3%"}  marginBottom={props&&props.ActiveOrderItems&&props.ActiveOrderItems[0]&&props&&props.carttotalAmount?"53%":props&&props.carttotalAmount?"37%":props&&props.ActiveOrderItems&&props.ActiveOrderItems[0]?"37%":"60px"}>
    {props&&props.nearByVms.map((data, index) => (
        <Card key={index} sx={{ width: '100%', borderRadius: '16px', marginBottom: '10px',boxShadow:"0px 2px 21px 0px rgba(0, 0, 0, 0.08) " }}  onClick={(e)=>VmsClick(e,data.id,data.name,data.address,"nearbyMachines_Section")}>
          <Box sx={{ display: 'flex' }}>
          
        <object
               /*  className="profile-img" */
                data={`https://daalchini.s3.ap-south-1.amazonaws.com/dcprod/${data.machineImagePath}`}
                type="image/png"
                style={{backgroundColor:"none",padding:"5%",width:"30%",objectFit:"fill",height:"80px",borderRadius:"27px"}}
              >
                <img /* className="cover"  */src={VmImgnotfound} alt="Product Img" style={{width:"100%",padding:"0px",height:"50px",objectFit:"contain",borderRadius:"6px"}} />{" "}
              </object>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5%',paddingLeft:"0px" }}>
              <Box>
                <Typography fontWeight={600} fontSize={"14px"} color={"rgba(56, 79, 111, 1)"}></Typography><TruncatedText text={data.name} limit={25}/>
                <Typography color={"rgba(148, 163, 184, 1)"} fontWeight={400} fontSize={"12px"}><TruncatedText text={data.address } limit={33}/></Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      ))}
        </Box>}
    </Box>
        
       
          </>
        );
  
  };
  



  const ShowLocationModal = () => {
    console.log();
    //setLocationModalFLag(true);
  };
  const shareLocation=()=>{
    props.getLocation4Modal()
  }

  const ShowQRFun = () => {
    console.log();
    props.setShow_Qr_Modal_VmList(true);
    props.setShow_vmList_flag(false);
    props.setShow_location_flag(false);
  };

 useEffect(()=>{


},
 [props.ActiveOrderItems,props.PreCardItems])
 const previewStyle = {
  height: 240,
  width: 320,
  border: "none", // Add this line to remove the border
};

const recentVmsClick=(e,id)=>{

  console.log("recentVmsClick")
mpClick({EventName:"vm_page_opened",data:{source:"recentVm",vmid:id}})
  navigate(`items?machineId=${id}`)
  

}

  return (
    <>
   
     {(localStorage.getItem("lat"))&&<MixPnl page={"vm_page_opened"} />}

    {props.loader&&<WaitingLoder/>}
  

      {props.show_Qr_Modal_LocationPage && (
        <div>
          <HeaderSecondary onClick={props.HideQrModalFunforLocationPAge} />
          {!cameraError ? (
            <div>
              <div className="mt-[60px] h-full w-full backdrop-blur-md absolute top-0 left-0"></div>

               <Webcam  videoConstraints={videoConstraints}  className="object-cover h-screen w-screen" />  
              <div className="mt-[80px] w-[200px] h-[200px] border-4 border-[var(--color-primary)] rounded-md flex justify-center items-center absolute top-1/4 left-1/4  ">
                {showScanner && (
                      <QrReader
                      delay={50}
                      onError={handleError}
                      onScan={handleScan}
                      facingMode={cameraFacingMode}
                      style={{width:"100%",border:"none!important"}}
                      //className="object-cover rounded-md w-full h-full"
                               />
                )}
              </div> 
            </div>
          ) : (
            <>
              {" "}
              <p style={{ textAlign: "center", paddingTop: "50%" }}>
                {" "}
                No Camra Permission Reset Your Setting
              </p>
            </>
          )}
        </div>
      )}
      {props.show_Qr_Modal_VmList && (
        <div>
          <HeaderSecondary onClick={props.HideQrModalFunforVmlistPage} />
          {!cameraError ? (
           <div>
           <div className="mt-[60px] h-full w-full backdrop-blur-md absolute top-0 left-0"></div>

            <Webcam  videoConstraints={videoConstraints}  className="object-cover h-screen w-screen" />  
           <div className="mt-[80px] w-[200px] h-[200px] border-4 border-[var(--color-primary)] rounded-md flex justify-center items-center absolute top-1/4 left-1/4 ">
             {showScanner && (
                   <QrReader
                   delay={50}
                   onError={handleError}
                   onScan={handleScan}
                   facingMode={cameraFacingMode}
                   style={{width:"100%"}}
                   //className="object-cover rounded-md w-full h-full"
                            />
             )}
           </div> 
         </div>
          ) : (
            <>
              {" "}
              <p style={{ textAlign: "center", paddingTop: "50%" }}>
                {" "}
                No Camra Permission Reset Your Setting
              </p>
            </>
          )}
        </div>
      )}

      {locationModalFLag && (
        <div className="modal">
          <div className="modal-content">
            <img
              src={looocat}
              style={{
                position: " absolute",
                top: "-63px",
                left: "37%",
                width: "81px",
              }}
            />
            <div style={{ "text-align": "center", "margin-top": "15%" }}>
              <h1
                style={{
                  color: "#384F6F",
                  "text-align": "center",
                  "font-family": "Roboto",
                  "font-size": "15px",
                  "font-style": "normal",
                  "font-weight": "700",
                  "line-height": "28px" /* 186.667% */,
                  "letter-spacing": "0.5px",
                }}
              >
                Find Nearby Vending Machines{" "}
              </h1>
              <p
                style={{
                  marginTop: "3%",
                  fontSize: "12px",
                  "padding-right": "10%",
                  "padding-left": "10%",
                  "text-align": "center",
                  color: "rgba(56, 79, 111, 0.69)",
                  "font-family": "Roboto",
                  "font-size": "12px",
                  "font-style": "normal",
                  "font-weight": "400",
                  "line-height": "15.5px",
                }}
              >
                Share Your Current Location to Find the Nearest Options
              </p>
            </div>
            <div
              style={{
                display: "flex",
                "justify-content": "space-around",
                marginTop: "5%",
              }}
            >
              <div
                //to={`/items?machineId=${machineId}`}
                //className="nobtn"

                className="flex align-center bg-green-500 w-16 border-r-2 justify-evenly  p-3 rounded-2xl"
                style={{
                  width: "99%",
                  textDecoration: "none",
                  color: "#FFF",
                  "background-color": "#00b177",
                  "text-align": "center",
                  "font-family": "Inter",
                  "font-size": "15px",
                  "font-style": "normal",
                  "font-weight": "800",
                  "line-height": "13.5px" /* 90% */,
                  "letter-spacing": "1px",
                }}
                onClick={(e) => {
                  LocatFun();
                }}
              >
                <button
                  onClicke={{ width: "72%" }}
                  onClickstyle={{ width: "72%" }}
                >
                  Share
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

  
      {props.show_location_flag && (
        <>
        
        { props&&props.show_location_flag && !(localStorage.getItem("lat"))&&<MixPnl page={"location_request_page_opened"} pro={"location_request_page_opened properties"}/>}
         
          
            <LocationPage getFirstLocation={props&&props.getLocation} location_Btn_disable={props.location_Btn_disable}/>
        
        </>
      )}




{props.show_vmList_flag && (
        <>
           {props&&props.PreCardItems&&props.PreCardItems[0]&&<Procced onClick={localStorage.setItem("mycart", true)} totalamount={props&&props.carttotalAmount} cartCount={props&&props.totalitemcount} bottom={"39px"}/>}
           {props&&props.ActiveOrderItems&&props.ActiveOrderItems[0]&&<ActiveOrdersFab
        
        itemname={props&&props.ActiveOrderItems&&props.ActiveOrderItems[0]&&props.ActiveOrderItems[0].lineItems[0].productName}
        itemlength={props &&props.ActiveOrderItems&& props.ActiveOrderItems[0]&&props.ActiveOrderItems[0].lineItems.length}
        orderLength={props && props.ActiveOrderItems&&props.ActiveOrderItems.length}
        Top={props&&props.ActiveOrderItems&&props.ActiveOrderItems[0]?props.PreCardItems&&props.PreCardItems[0]?"122px" : "65px":"65px"}
        orderID={props &&props.ActiveOrderItems&& props.ActiveOrderItems[0]&&props.ActiveOrderItems[props.ActiveOrderItems.length-1].orderId}
        orderMachineId={props &&props.ActiveOrderItems&& props.ActiveOrderItems[0]&&props.ActiveOrderItems[props.ActiveOrderItems.length-1].vendingMachineId}
        />}
    


        {console.log("orderMachineId",props &&props.ActiveOrderItems&& props.ActiveOrderItems[0]&&props.ActiveOrderItems[props.ActiveOrderItems.length-1].vendingMachineId)}
       
      
          <TopNavbar

LoctionPinHeader={true} QrMarkHeader={true} shareLocation={shareLocation} ShowQRFun={ShowQRFun}
          
          />
          <div style={{
          }}>
            <MilestonePagination/>
            </div>
          <BottomNarBar />
          <Box    sx={{display:{xs:"block",md:"",sm:"block"},
        //backgroundImage: ' linear-gradient(to bottom, #ffffff, #f4fcff)',
        background: "radial-gradient(circle, rgba(245, 233, 233, 0.408) 0%, rgba(185, 245, 247, 0.34) 100%)",
        minHeight: '100vh',
        padding: "12px",
        paddingTop:"7vw",

        color: '#fff', // Set text color to white
    }}
>
           

          
              <Banners vmid={0} />
        
        

            {(localStorage.getItem("lat"))&&<MixPnl page={"recent_vm_displayed"} />}
            {(props&&props.vmListSkeleton)?<Skeleton
    variant="rectangular"
    width="100%"
    height={40}
   
    sx={{ borderRadius:"10px", objectFit: 'scale-down', padding: '2%' ,marginBottom:"5px",marginTop:"5%"}}
  />:
  <TextField
      onClick={(e) => globalSearchflag(e)}
      sx={{
        background: "white",
        borderRadius: "10px",
        border: "none",
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'your-border-color', // customize border color
          },
          '& input': {
            fontSize: '15px', // customize font size
            color: 'background: rgba(56, 79, 111, 1)', // customize font color
          },
        },
      }}
      variant="outlined"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search/>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {searchText && (
              <IconButton
                edge="end"
                onClick={handleClear}
              >
                <ClearIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      placeholder="Find items or machine"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
    />}
            
            {props&&props.recentMachines&&props&&props.recentMachines[0]&&<RecentVms recentMachines={props&&props.recentMachines} VmsClick={VmsClick} vmListSkeleton={props&&props.vmListSkeleton} />}

          
           { props &&props.nearByVms&&
      props.nearByVms[0] &&<MixPnl page={"nearby_vm_displayed"} />}
            <div>{renderBoxes()}</div>{" "}
          </Box>
        </>
      )}

    </>
  );
};

export default Vmlist;
