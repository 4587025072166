import { connect } from "react-redux";
import { fetchVmListRequest,fetchPreCardRequest,fetchActiveOrdersRequest } from "./action";
import { useEffect, useState } from "react";
import Vmlist from "./Vmlist";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { mpClick } from "../../Mixpanel";
const Vmlist_Container = (props) => {
/////////////////////////GLOBAL DATA SECTION ONLY ///////////////////////////////////////////////////

  const nearByVms=props&&props.vmList&&props.vmList.NearByVMs
  const recentMachines=props&&props.vmList&&props.vmList.recentMachines
  const PreCardItems=props&&props.vmList&&
                    props.vmList.PreCardData&& 
                    props.vmList.PreCardData.PreCardItemsList

                
  const PreCardVMID=props&&props.vmList&&
                    props.vmList.PreCardData&&
                    props.vmList.PreCardData.PreCardVmID


  const ActiveOrderItems=props.vmList&&
                          props.vmList.ActiveOrdersData&&
                          props.vmList.ActiveOrdersData.ActiveOrderItemsList
    


const [vmListSkeleton,setVmListSkeleton]=useState(true)

console.log("ActiveOrderItems",ActiveOrderItems)


localStorage.setItem("PreVmId",PreCardVMID)
  /////////////////////////////////////////////////
  // SHOW VM LIST PAGE
  const [show_vmList_flag, setShow_vmList_flag] = useState(true);

  // SHOW LOCATION PAGE
  const [show_location_flag, setShow_location_flag] = useState(true);

  // this flag use for disable Location Page Btn
  const [location_Btn_disable, setLocation_Btn_disable] = useState(false);

    // this flag use for disable Location Page Btn
    const [show_Qr_Modal, setShow_Qr_Modal] = useState(false);
    const [show_Qr_Modal_VmList, setShow_Qr_Modal_VmList] = useState(false);
    const [show_Qr_Modal_LocationPage, setShow_Qr_Modal_LocationPage] = useState(false);
  useEffect(() => {
    
    // IF LOCATION LNT AND LNG VARIABLE  PRESENT IN LOCAL THAN

    if (localStorage.getItem("lat") && localStorage.getItem("lng")) {
      if(navigator.onLine){
      setShow_vmList_flag(true); // SHOW VM LIST PAGE AND
      setShow_location_flag(false); // HIDE LOCATION PAGE
      fetchVmList(localStorage.getItem("lat"), localStorage.getItem("lng"));
      }
      else{
        setShow_location_flag(false);
      }
    }
    //IF LOCATION LNT AND LNG VARIABLE not PRESENT IN LOCAL STORAGE THAN
    else {
      setShow_vmList_flag(false); // HIDE VM LIST PAGE AND

      setShow_location_flag(true); // SHOW LOCATION PAGE
    }
  
  }, []);


useEffect(()=>{
  if(navigator.onLine){
fetchPreCard()
fetchActiveOrder()
  
if(localStorage.getItem("move_cart_vmid"))
{
navigate(`/items?machineId=${localStorage.getItem("move_cart_vmid")}`)
}
}


},[])


  //////////////////////////////////////////////////////////////////////////////
  // this function take location and set into local storage
  // if location lnt and lng set successfully in localstorage than
  // location page close and show vm List page

  function getLocation() {

  console.log("mpClick getLocation")
     
    setLocation_Btn_disable(true);// untill location not get till ...disable Location Page Btn
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
    function showPosition(position) {
      setLoader(true);
      console.log(
        "Latitude: " +
          position.coords.latitude +
          " Longitude: " +
          position.coords.longitude
      );
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("lng", position.coords.longitude);
      setShow_location_flag(false);// HIDE VM LIST PAGE AND
      setShow_vmList_flag(true);//// SHOW LOCATION PAGE

      fetchVmList(position.coords.latitude, position.coords.longitude);

     
    }

    // if any problem to access  location  than 
    function showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          setLocation_Btn_disable(false);
          console.log("User denied the request for Geolocation.");
          toast.error(`User denied the request for Geolocation`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          break;
        case error.POSITION_UNAVAILABLE:
          setLocation_Btn_disable(true);
          console.log("Location information is unavailable.");
          toast.error(`Location information is unavailable.`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          break;
        case error.TIMEOUT:
          setLocation_Btn_disable(true);
          console.log("The request to get user location timed out.");
          toast.error(`The request to get user location timed out.`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          break;
        case error.UNKNOWN_ERROR:
          setLocation_Btn_disable(true);
          console.log("An unknown error occurred.");
          toast.error(`An unknown error occurred.`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          break;
      }
    }
  }
  function getLocation4Modal() {
   
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
    function showPosition(position) {
      setLoader(true);
      console.log(
        "Latitude: " +
          position.coords.latitude +
          " Longitude: " +
          position.coords.longitude
      );
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("lng", position.coords.longitude);
      setShow_location_flag(false);// HIDE VM LIST PAGE AND
      setShow_vmList_flag(true);//// SHOW LOCATION PAGE

      fetchVmList(position.coords.latitude, position.coords.longitude);
    }

    // if any problem to access  location  than 
    function showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          setLocation_Btn_disable(false);
          console.log("User denied the request for Geolocation.");
          toast.error(`User denied the request for Geolocation`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          break;
        case error.POSITION_UNAVAILABLE:
          setLocation_Btn_disable(true);
          console.log("Location information is unavailable.");
          toast.error(`Location information is unavailable.`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          break;
        case error.TIMEOUT:
          setLocation_Btn_disable(true);
          console.log("The request to get user location timed out.");
          toast.error(`The request to get user location timed out.`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          break;
        case error.UNKNOWN_ERROR:
          setLocation_Btn_disable(true);
          console.log("An unknown error occurred.");
          toast.error(`An unknown error occurred.`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          break;
      }
    }
  }

function ShowQrModalFun(){



  setShow_vmList_flag(false); // HIDE VM LIST PAGE AND

  setShow_location_flag(false); // HINDE LOCATION PAGE

  setShow_Qr_Modal_LocationPage(true);// Show Qr Modal

}

function HideQrModalFunforLocationPAge(){



  setShow_vmList_flag(false); // HIDE VM LIST PAGE AND

  setShow_location_flag(true); // HINDE LOCATION PAGE

  setShow_Qr_Modal_LocationPage(false);// Show Qr Modal

}

function HideQrModalFunforVmlistPage(){



  setShow_vmList_flag(true); // HIDE VM LIST PAGE AND

  setShow_location_flag(false); // HINDE LOCATION PAGE

  setShow_Qr_Modal_VmList(false);// Show Qr Modal

}









  const [cartCount,setCartCount]=useState(null);
  const [carttotalAmount,setCarttotalAmount]=useState(null)
  const [locationAllow, setLocationAllow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [locatinActiveFlag, setLocatinActiveFlag] = useState(false);
  const navigate = useNavigate();
  console.log("PreCardData props", props.vmList.PreCardData);

  const handleResponseVmList = (res) => {
   setShow_location_flag(false);
   setVmListSkeleton(false)
   setLoader(false)
    console.log("handleResponse", res);
    setLoader(false)
  };

  const handleErrorVmList = (error) => {
    console.log("handleErrorVmList", error.response.status==401);
    setLoader(false)
    if(error.response.status==401|| error.response.status==403){
      console.log(error.response.data.status.code);
      //LOGOUT
      localStorage.removeItem("UserJWTAuth")
      navigate("/login");
  }
  };


  const handleResponsePreCard = (res) => {
    console.log("handleResponsePreCard khan", res);
   

    
   
  };

  const handleResponseActiveOrders=(res)=>{

    console.log("handleResponseActiveOrders", res);


  }
  let totalitemcount=0,totalAmount=0
  useEffect(()=>{
    
    PreCardItems&&PreCardItems.forEach(item => {
      const count = item.count;
      const amount = item.amount;
      const itemTotal = count * amount;
      totalitemcount+=item.count
      totalAmount += itemTotal;
    });
    setCartCount(totalitemcount)
    setCarttotalAmount(totalAmount)
    console.log("props&&props.totalitemcount",totalitemcount,totalAmount)
    console.log("handleResponse");
    console.log("handleResponsePreCard" );

  },[PreCardItems])

  const handleErrorPreCard = (error) => {
   
    setLoader(false)
   
    if(error.response.status==401|| error.response.status==403){
      console.log("handleErrorPreCard", error.response.data.status.code);
       //LOGOUT
    localStorage.removeItem("UserJWTAuth")
    navigate("/login");
  }
  };

  const handleErrorActiveOrders = (error) => {
    setLoader(false)
    console.log("KHAN handleErrorActiveOrders ", error);
    if(error.response.status==401|| error.response.status==403){

      console.log("handleErrorActiveOrders", error.response.data.status.code);
      //Logout
      localStorage.removeItem("UserJWTAuth")
        navigate("/login");
  }
  };

  const SetVm = (e, id, name, address) => {
    console.log("Setvm VM ID", id);
    mpClick({EventName:"vm_page_opened",data:{source:"nearbyVm",vmid:id}})
    navigate(`/items?machineId=${id}`, { replace: true });
    localStorage.setItem("vmName", name);
    localStorage.setItem("vmAddress", address);
  };
  const globalSearchflag = (e) => {
    e.preventDefault();

    navigate("/globalsearch");
  };

  useEffect(() => {
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
    console.log("UserJWTAuth VmlistContainer", UserJWTAuth);
    if (!UserJWTAuth) {
      navigate("/login");
    }
  }, []);

  function getLocation() {
    mpClick({EventName:"location_request"} )
    setLocation_Btn_disable(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
    function showPosition(position) {
      setLoader(true);
      console.log(
        "Latitude: " +
          position.coords.latitude +
          " Longitude: " +
          position.coords.longitude
      );
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("lng", position.coords.longitude);
      setShow_location_flag(false);
      setShow_vmList_flag(true);

      fetchVmList(position.coords.latitude, position.coords.longitude);
    }
    function showError(error) {
      console.log("showError",error.code)
      switch (error.code) {
        case error.PERMISSION_DENIED:
          setLocation_Btn_disable(false);
          mpClick({EventName:"location_request_denied"} )
          console.log("User denied the request for Geolocation.");
          toast.error(`User denied the request for Geolocation`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          break;
        case error.POSITION_UNAVAILABLE:
          setLocation_Btn_disable(true);
          mpClick({EventName:"location_request_unavailable"} )
          console.log("Location information is unavailable.");
          toast.error(`Location information is unavailable.`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          break;
        case error.TIMEOUT:
          setLocation_Btn_disable(true);
          mpClick({EventName:"location_request_timeout"} )
          console.log("The request to get user location timed out.");
          toast.error(`The request to get user location timed out.`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          break;
        case error.UNKNOWN_ERROR:
          setLocation_Btn_disable(true);
          mpClick({EventName:"location_request_unknow_error"} )
          console.log("An unknown error occurred.");
          toast.error(`An unknown error occurred.`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          break;
      }
    }
  }

 

  const fetchVmList = (lat, lng) => {
    setLoader(true)
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");
    setLoader(true)
    props.fetchVmListRequest({
      url: `/consumerbe/api/v2/outlet/search?nearbyLimit=50&recentLimit=6&lat=${lat}&lng=${lng}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
        //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
        
      },
      handleErrorVmList,
      handleResponseVmList,
    });
  };




  const fetchPreCard = () => {
    const Guest_clientId=localStorage.getItem("Guest_clientId")
    const UserJWTAuth = localStorage.getItem("UserJWTAuth");

   
    props.fetchPreCardRequest({
      url: `/consumerbe/api/v2/cart`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${UserJWTAuth}`,
        "x-app-platform": "web",
        "x-app-version": "v1.0.1",
         //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
      },
      handleErrorPreCard ,
      handleResponsePreCard ,
    });
  
  };

//handleResponseActiveOrders

/////////////////  ACTIVE ORDERS DATA FETCHING
const fetchActiveOrder = () => {
  const UserJWTAuth = localStorage.getItem("UserJWTAuth");
  const Guest_clientId=localStorage.getItem("Guest_clientId")
if(!Guest_clientId){
  props.fetchActiveOrdersRequest({
    url: `/consumerbe/api/v2/order/active`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${UserJWTAuth}`,
      "x-app-platform": "web",
      "x-app-version": "v1.0.1",
       //HEADER ERROR
      "x-device-id":localStorage.getItem("uuid")
    },
    handleErrorActiveOrders ,
    handleResponseActiveOrders ,
  });
}
};
  console.log("UserContainer props", props);
  return (
    <>
      <Vmlist
        show_vmList_flag={show_vmList_flag}
        show_location_flag={show_location_flag}
        
        ShowQrModalFun={ShowQrModalFun}
        show_Qr_Modal={show_Qr_Modal}
        setShow_Qr_Modal={setShow_Qr_Modal}
        setShow_location_flag={setShow_location_flag}
        setShow_vmList_flag={setShow_vmList_flag}
        setShow_Qr_Modal_VmList={setShow_Qr_Modal_VmList}
        show_Qr_Modal_LocationPage={show_Qr_Modal_LocationPage}
        HideQrModalFunforVmlistPage={HideQrModalFunforVmlistPage}
        HideQrModalFunforLocationPAge={HideQrModalFunforLocationPAge}
        carttotalAmount={carttotalAmount}
        totalitemcount={cartCount}
        loader={loader}



//////////////////////////////GLOBAL DATA PASSING ////////////////////
        nearByVms={nearByVms}
        PreCardItems={PreCardItems}
        PreCardVMID={PreCardVMID}
        ActiveOrderItems={ActiveOrderItems}
//////////////////////////////////////////////////////////////////////
        vmListSkeleton={vmListSkeleton}

        SetVm={SetVm}
        globalSearchflag={globalSearchflag}
        locatinActiveFlag={locatinActiveFlag}
        getLocation={getLocation}
        getLocation4Modal={getLocation4Modal}
        location_Btn_disable={location_Btn_disable}
        recentMachines={recentMachines}
        //loader={loader}
       
        show_Qr_Modal_VmList={show_Qr_Modal_VmList}
      />
    </>
  );
};

const mapStateToProps = (props) => {
  return props;
};

const mapDispatchToProps = {
  fetchVmListRequest,
  fetchPreCardRequest,
  fetchActiveOrdersRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Vmlist_Container);
