import React from "react";
import CancleIcon from "../../assets/wrong-address 1.png";
import { Link, useNavigate } from "react-router-dom";
const Popup=(props)=>{
    const navigate=useNavigate()
    return(<>
  
    <div className="modal">
              <div className="modal-content" >
                <img
                  src={CancleIcon}
                  style={{ position: "absolute", top: "-41px", left: "39%"  ,width: "81px"}}
                />
                <div style={{ "text-align": "center", "margin-top": "10%" }}>
                  <h3 style={{color: "#384F6F",
"text-align": "center",
"font-family": "Roboto",
"font-size":"16px",
"font-style": "normal",
"font-weight": "700",
"line-height": "28px", /* 175% */
"letter-spacing":"1px"}}>
Wrong vending machine</h3>
                  <p style={{ marginTop: "3%" ,color: "rgba(56, 79, 111, 0.69)",
"text-align":"center",
"font-family": "Roboto",
"font-size":"12px",
"font-style": "normal",
"font-weight": "400",
"line-height":"20px"}}>
                    {props&&props.text}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    "justify-content": "space-around",
                    marginTop: "9%",
                  }}
                >
                  <button
                 
                    className="nobtn"
                    style={{display: "flex",
                      "width":"100%",
                      "height": "41px",
                      "padding":"10px",
                      "justify-content": "center",
                      "align-items":"center",
                      "gap": "10px",
                      "flex-shrink":"0"}}

                     onClick={(e)=>{navigate("/activeOrder")}}
                  >
                   ok
                  </button>
       
                </div>
              </div>
            </div>
    </>)
}

export default Popup