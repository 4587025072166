import React, { useState } from "react";
import TopNavbar from "../header";
import MyCartHeader from "../Header/MyCartHeader";
import { colors } from "@material-ui/core";
import ProfileIConeEarn from '../../assets/earnprofileicon.png'
import CopyIcone from '../../assets/copyicone.png'
import Icon from '../../assets/iconEarn.png'
import ShareButton from "../ShareButton/component";
import ShareButtons from "../ShareButton/component";
import WebShareButton, { handleShare } from "../ShareButton/component1";
const ReferEarnComp=(props)=>{

    console.log("Props---->",props)

    const [inputValue, setInputValue] = useState(localStorage.getItem("referCode"));
    
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(inputValue);
            alert('Copied the text: ' + inputValue);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };
const closeModal=()=>{
    console.log("close modal")
    props&&props.setEarnModal(false)
}

const openModal=(e)=>{

    props&&props.setEarnModal(true)
}
    return(
    
    <>

    {  props&&props.earnModal&&
    
    <div className="modal" onClick={closeModal} >
              <div className="modal-content" style={{height:"357px",left:"10%"}}   onClick={(event) => event.stopPropagation()}>
                <div style={{display:"flex",justifyContent:"center"}}>
              <img src={Icon} style={{width:"89px"}}/></div>
                <div style={{ "text-align": "center", "margin-top": "5%" }}>
                  <h3 style={{color: "#384F6F",
"text-align": "center",
"font-family": "Roboto",
"font-size":"16px",
"font-style": "normal",
"font-weight": "700",
"line-height": "28px", /* 175% */
"letter-spacing":"1px"}}>Enter Coupon Code</h3>
                
                </div>
                <div
                  style={{
                    display: "flex",
                    "justify-content": "space-around",
                    marginTop: "9%",
                  }}
                >

                    <div style={{border: "1px solid #94A3B861",width:"100%",    padding: "5%",borderRadius:"9px"}}>
                        <input style={{width:"100%"}} placeholder="Enter code" onChange={(e)=>{props&&props.handleReferCode(e)}}/>
                    </div>

                </div>
                <div style={{display:"flex",justifyContent:"center",marginTop:"15%"}}>
                <div onClick={props&&props.ApplyReferCode} style={{background: "#00B177",textAlign:"center",color:"white",padding:"5%",width:"50%",borderRadius:"10px"}}>
                        Check

                    </div>
                    </div>
              </div>

            
         
            </div>}
    <div style={{   background: "aliceblue",
    height:"100vh"}}>
 <MyCartHeader title={"Refer and Earn"}  route={`/profile`}/>


 <div style={{display: "flex",
    justifyContent: "center" ,marginTop:"10vw" }} >
 
    <img  src={ProfileIConeEarn}  style={{width:"100px"}}/>
 </div>
 <h2 style={{textAlign:"center",color:"#333F4E", "font-family": "SF Pro Display",
"font-size": "18px",
"font-weight": "700",
"line-height": "24px",
"text-align":"center",marginTop:"5%"}}>Refer & Earn </h2>
 <p style={{textAlign:"center",    padding:"10%",color:"#64748B","font-family": "SF Pro Display",
"font-size": "14px",
"font-weight": "400",
"line-height": "21px",
"letter-spacing":"0.2px",}}>
    Share this code with your friends. when they order using Daalchini App for the first time, you both get Rs 10
 </p>
    <h2 style={{textAlign:"center",color:"#718198",fontFamily: "SF Pro Display",
fontSize: "12px",
fontWeight: "400",
lineHeight: "18px",
letterSpacing: "0.20000000298023224px"}}>YOUR CODE</h2>


<div style={{    display: "flex","align-items":"flex-end","justify-content":"center","margin-top":"5%"}}>
    <div style={{border:"1px dotted black", width: "46%",display:"flex",    "justify-content": "center","align-items":"center",    }}>
            <h1 
            style={{width:"100%",padding:"5%",color:" #00B177",fontFamily: "SF Pro Display",textTransform:"uppercase",
                fontSize: "16px",
                fontWeight: "500",
                letterSpacing:"1.5px",
                textAlign: "center"}}
                type="text" 
                value={inputValue} 
                onChange={handleInputChange} 
            >

               {localStorage.getItem("referCode")}
            </h1>
           
            <img  style={{width:"25px",height:"25px","margin-right": "5%"}}  src={CopyIcone} onClick={copyToClipboard}/>
        </div>

        </div>
        <div style={{display:"flex",justifyContent:"center"}}>
        <div style={{width:"80%"}}>
        <div style={{padding:"5%" ,border:"1px solid #94A3B866",borderRadius:"12px",marginTop: "10%"}}>
           
            <div style={{    display: "flex",      "justify-content":" space-between",
    alignItems: "center"}}>
                <h1 style={{fontFamily:"SF Pro Display",
fontSize: "13px",
fontWeight: "500",
lineHeight: "19.5px",
letterSpacing:"0.20000000298023224px",
color: "#64748B"}}>
                Have a coupon code?
                </h1>

                <div style={{background:"#00B177",padding:"2%",color:"white",textAlign:"center",borderRadius:"6px",fontSize:"3vw" ,    width: "30vw"}} onClick={(e)=>{openModal(e)}}>
                Apply coupon
                </div>
            </div>
            </div>

          

        </div>
     
        </div>
        <div style={{display:"flex",    justifyContent:"center"  }}>
        <a href="https://www.daalchini.co.in/terms.html" style={{fontSize:"12px",marginTop:"5%" ,textAlign:"center",textDecoration:"underline"}}>Terms & Conditions</a>
        </div>
        <div style={{display:"flex",justifyContent:"center",marginTop:"5%", position:"absolute",
    bottom: "3vw",
    width: "100%"}}>
        <div  style={{    width:"50%"}}>
            <div style={{border:" 1px solid #71819861",padding:"5%" ,borderRadius:"8px"}}>
            <div style={{color: "#718198","font-family":"SF Pro Display",
"font-size": "14px",
textTransform:"uppercase",
"font-weight": "500",
"text-align": "center"}} onClick={handleShare}>
            Invite your Friends
            </div>
            </div>
        </div>
        </div>
     
        </div>
    </>
    

)

}

export default ReferEarnComp;
