import React from "react";
import "../Procced/procced.css";
import { Link } from "react-router-dom";
import QrIcon from "../../assets/QrIcon.png";
import whiteforwordarrow from "../../assets/whiteforwordArrow.png";
import "./ActiveOrderbar.css";
import { useNavigate } from "react-router-dom";
import { mpActiveOrdersVmInventory, mpClick } from "../../Mixpanel";
function ActiveOrdersBar(props) {


const navigate=useNavigate()


const goToPickUpOrder=(e)=>{

  localStorage.setItem("contactLessOrderID",props.order_id)
  localStorage.setItem("contactLessMachineID",props.orderMachineId)
  navigate("/pickupOrder")
}



const goToActiveOrder=(e)=>{
  //mpActiveOrdersVmInventory({EventName:"",data:{}})
  
  mpActiveOrdersVmInventory({EventName:"Active_order_icon_clicked",data:{order_id:props.order_id,items_count:props.orderLength,SourcePage:props.SourcePage}})

  navigate("/activeOrder")
}


  console.log("props.orderLength props", props.orderLength>1);
  function TruncatedHeading({ text, limit }) {
    if (text && text.length <= limit) {
      return (
        <p
          style={{
            color: "rgba(255, 255, 255, 0.69)",
            "font-family": "Roboto",
            "font-size": "10px",
            "font-style": "normal",
            "font-weight": "400",
          }}
        >
          {text}
        </p>
      );
    }
    return (
      <p
        style={{
          fontSize: "10px",
          color: "rgba(255, 255, 255, 0.69)",
          "font-family": "Roboto",
          "font-size": "10px",
          "font-style": "normal",
          "font-weight": "400",
        }}
      >{`${text && text.substring(0, limit)}...`}</p>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        "z-index": "99999999",
        bottom: props.Top ? props.Top : "113px",
        "justify-content": "center",
        width: "99%",
        "justify-content": "space-between",
      }}
    >
      <div
       onClick={(e)=>{goToActiveOrder(e)}}
        style={{
          textDecoration: "none",
          color: "white",
          bottom: "68px",
          backgroundColor: "blue",
          "background-color": "#2D2B45",
          color: "white",
          height: "62px",
          width: "100%",
          marginLeft: "11px",
          "border-radius": "10px",
          "z-index": "99999",
        }}
      >
        <div
          style={{
            "margin-top": props.orderLength > 1 ? "7px" : "14px",
            display: "flex",
            "justify-content": "space-around",
            "align-items": "center",
          }}
        >
          <div>
            <h2
              style={{
                fontSize: "13px",
                color: "rgba(255, 255, 255, 0.88)",
                
                "font-family": "Roboto",
                "font-size": "13px",
                "font-style": "normal",
                "font-weight": "500",
                "line-height": "150%" /* 19.5px */,
                "letter-spacing": "0.2px",
                "text-transform": "uppercase",
              }}
            >
              Pickup your order
            </h2>
            <div style={{display:"flex" }}>
              {" "}
              <TruncatedHeading text={props.itemname} limit={25} />{" "}
              {props.itemlength > 1 && (
                <span style={{ fontSize: "10px" }}>
                  +{props.itemlength - 1} More Items
                </span>
              )}
            </div>
          </div>
          <img style={{ width: "4%", height: "2%" }} src={whiteforwordarrow} />

          {/*  <p>Pick Your Order in 25:12 mins</p> */}
        </div>
        {props.orderLength > 1 && (
          <>
            <hr className="horizontal-line" />
<div style={{display:"flex",    "justify-content": "space-around"}}>
            <p
              style={{
              /*   "margin-left": "13%", */
                fontSize: "10px",
                color: "rgba(255, 255, 255, 0.69)",
                "font-family": "Roboto",
                "font-size": "10px",
                "font-style": "normal",
                "font-weight": "400",
               /*  "line-height": "20px", */
              }}
            >
              + {props.orderLength - 1} more Order{" "}
            </p>
            <p style={{    visibility:"hidden"}}>tahzeeb khan</p>
            </div>
          </>
        )}
      </div>

      <div onClick={(e)=>{goToPickUpOrder(e)}}>
        <div
          style={{
            width: "77px",
            "margin-left": "7%",
            "background-color": "#2D2B45",
            "margin-right": "16px",
            "border-radius": "8px",
            height: "61px",
            display: "flex",
            "justify-content": " space-around",
            "align-items": "center",
          }}
        >
          <img style={{ width: "37px" }} src={QrIcon} />
        </div>
      </div>
    </div>
  );
}

export default ActiveOrdersBar;
